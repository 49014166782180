import React from "react";
import { useEffect, useRef, useCallback } from "react";
import axios from "axios";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Tab, Tabs, TabPanel } from "react-tabs";
import "../../assets/css/custom.css";
import "react-tabs/style/react-tabs.css";
import { useState } from "react";
import NotificationAlert from "react-notification-alert";
import { Link } from "react-router-dom";
import Accordions from "components/Accordian/Accordians";
import Varshfal from "./Varshfal";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
const VASHISHT_API_URL = process.env.REACT_APP_VASHISHT_API;

const Questionanswer = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabDisabled, setTabDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cateDetails, getCateDetails] = useState([]);
  const [questions, getQuestions] = useState([]);
  const notificationAlertRef = React.useRef(null);
  const scroller = useRef(null);

  // get user data local
  let bodydata = localStorage.getItem("bodydata");
  let token = localStorage.getItem("token");
  let user = JSON.parse(localStorage.getItem("user"));
  let bdata = JSON.parse(bodydata);

  // header for api
  const headers = {
    authorization: "Bearer " + token,
    "Content-Type": "application/json",
    "user-name": user.email,
  };

  const headers_category = {
    "auth-token": token,
    "user-name": user.email,
  };

  const centeredScroll = useCallback((index, cat_Id) => {
    const item = JSON.parse(localStorage.getItem(cat_Id));
    if (item) {
      getQuestions(item);
    } else {
      HandleQuestions(index, cat_Id);
    }

    setTabIndex(index);
    handleClickScroller("tabs" + index);
    // const scrollers = scroller.current;
    // const button = scrollers.children[index - 1];
    // const buttonRect = button.getBoundingClientRect();
    // const scrollerRect = scrollers.getBoundingClientRect();

    // // Calculate the scroll position to center the button
    // const scrollPosition =
    //   button.offsetLeft - (scrollerRect.width - buttonRect.width) / 2;
    // // console.log(button,"centeredScroll")
    // scrollers.scrollTo({
    //   left: scrollPosition,
    //   behavior: "smooth",
    // });
  });

  const handleClickScroller = (id) => {
    const element = document.getElementById(id);
    console.log(element, "scrolller");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
  };

  function scrollContent(direction) {
    const scrollContent = document.getElementById("scroller");
    // console.log(scrollContent, "scrollContent");
    const scrollAmount = 150;
    scrollContent.scroll({ behavior: "smooth" });
    if (direction === "left") {
      scrollContent.scrollLeft -= scrollAmount;
    } else if (direction === "right") {
      scrollContent.scrollLeft += scrollAmount;
    }
  }

  // past and future varshfal
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  console.log(currentYear); // Outputs the current year (e.g., 2024)

  let varshfalDataHtml = [];

  for (let i = 0; i < 21; i++) {
    let totalyear = currentYear;
    const exactDate = totalyear - 5;
    varshfalDataHtml.push(parseInt(exactDate + i));
  }
  const formattedDate = moment(
    bdata.birthDate.day +
      "-" +
      bdata.birthDate.month +
      "-" +
      currentDate.getFullYear(),
    "DD/MM/YYYY"
  ).format("DD MMM YYYY");

  console.log(
    currentDate.getFullYear(),
    bdata.birthDate.day +
      "-" +
      bdata.birthDate.month +
      "-" +
      bdata.birthDate.year,
    "currentDate"
  );
  // past and future

  function formatDateRange(dateString) {
    const startDate = moment(dateString, "DD MMM YYYY");
    const endDate = startDate.clone().add(1, "year").subtract(1, "day");
    const formatDate = (date) => date.format("D MMM YYYY");
    return `${formatDate(startDate)} to ${formatDate(endDate)}`;
  }

  const formattedRange = formatDateRange(formattedDate);
  console.log(formattedRange, "formattedRange");
  const birthData = bdata;
  const options = { year: "numeric", month: "short", day: "numeric" };

  const result = varshfalDataHtml.map((item, index) => {
    let strCurrent =
      birthData.birthDate.month + "-" + birthData.birthDate.day + "-" + item;
    let varshfaldate = new Date(
      strCurrent.replace(/-/g, "/")
    ).toLocaleDateString("en-IN", options);
    let strFuture =
      birthData.birthDate.month +
      "-" +
      birthData.birthDate.day +
      "-" +
      (item + 1);
    // let futureYearDateObj = new Date(strFuture.replace(/-/g, "/")).subtractDays(1);
    let futureYearDateObj = new Date(strFuture.replace(/-/g, "/"));
    futureYearDateObj.setDate(futureYearDateObj.getDate() - 1);
    let futureYear = futureYearDateObj.toLocaleDateString("en-IN", options);
    // let start_Date = varshfaldate;
    var varshfal = varshfaldate + " to " + futureYear;
    return varshfal;
  });

  const GetCategories = async () => {
    let item = JSON.parse(localStorage.getItem("cat_details"));
    if (item) {
      getCateDetails(item);
    } else {
      try {
        const response = await axios.post(
          `${BAS_URL_APPAPI}acharya/getCategory`,
          { acharyaid: user.id }, // Request body
          { headers: headers_category } // Headers
        );

        if (response.data && response.data.data) {
          const excludedNames = ["Match Making"];
          const cat_id = response.data.data.map((item) => item.categoryID);
          const cat_details = response.data.data
            .map((item) => item)
            .filter((name) => !excludedNames.includes(name.name));
          getCateDetails(cat_details);
          localStorage.setItem("cat_id", JSON.stringify(cat_id));
          localStorage.setItem("cat_details", JSON.stringify(cat_details));
        }

        console.log(response.data.data, "getCategory");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const HandleQuestions = async (index, catId, startDate, endDate) => {
    setTabIndex(index);
    setIsLoading(true);
    setTabDisabled(true);
  // const result = await axios.post(`${BAS_URL_APPAPI}acharya/getcredt`);
      const usertoken =  localStorage.getItem('vjtoken')
      const headers = {
        "authorization": "Bearer " + usertoken,
        "Content-Type": "application/json"
      };
    const data = Object.assign(bdata, {
      category: catId,
      Start_Date: startDate,
      End_Date: endDate,
    });
    try {
      let response = await axios.post(
        `${VASHISHT_API_URL}callcenter/questionbycat`,
        data,
        { headers }
      );
      // console.log(response.data.data,"bhvhhvhv")
      if (response.data.data.questions) {
        getQuestions(response.data.data.questions);
        localStorage.setItem(
          catId,
          JSON.stringify(response.data.data.questions)
        );
        setIsLoading(false);
        setTabDisabled(false);
      } else {
        getQuestions(response.data.data);
        setIsLoading(false);
        setTabDisabled(false);
      }
    } catch (err) {
      console.log(err, "response----jjj");
    }
  };

  const HandleAnswers = async (category) => {
    console.log(category, bdata,"dsdindindiwd");
    setTabDisabled(true);
    // setIsLoading(true);
    const payload = Object.assign(bdata, {
      category: category.category,
      finalyear: bdata.finalyear,
      rotatekundali: bdata.rotatekundali,
      currentDate: bdata.currentDate,
      currentTime: bdata.currentTime,
      showpdf: false,
      showgochar: false,
      ageRange: {
        fromAge: "",
        toAge: "",
      },
    });
    const data = Object.assign(
      {
        acharyaid: user.id,
        questions: [
          {
            que: category.que,
            qid: category.qid,
            uid: category.uid,
            category: category.category,
          },
        ],
      },
      {
        payload: payload,
      }
    );
    try {
      let response = await axios.post(
        `${BAS_URL_APPAPI}acharya/getAnswerByQuestionCategory`,
        data,
        { headers_category }
      );
      if (response.data.data.questions) {
        // console.log(response.data.data.questions, "bbbmbjkbnj");
        getQuestions((prevData) => {
          const updatedQuestions = response.data.data.questions.reduce(
            (acc, item) => {
              const index = acc.findIndex(
                (existingItem) => existingItem.qid === item.qid
              );
              if (index !== -1) {
                acc[index] = { ...acc[index], ...item };
              } else {
                acc.push(item);
                // console.log(acc, "njnjnjjnnj");
              }
              return acc;
            },
            [...prevData]
          );
          localStorage.setItem(
            category.category,
            JSON.stringify(updatedQuestions)
          );
          return updatedQuestions;
        });
        setTabDisabled(false);
        setIsLoading(false);
      }
    } catch (err) {
      setTabDisabled(false);
      setIsLoading(false);
      console.log(err, "response----jjj");
    }
  };

  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const handleSuggestionClick = (e, person, place) => {
    console.log(place, "suudsushuh");
    // setSelectedPlace(place);
    // setInputValue(place);
    // handleInputChange(e, person, place)
    // setSuggestions([]);
  };

  const [formData, setFormData] = useState({
    boy: {
      name: "",
      placeOfBirth: "",
      dob: { day: "", month: "", year: "" },
      tob: { hours: "", minutes: "", period: "AM" },
    },
    girl: {
      name: "",
      placeOfBirth: "",
      dob: { day: "", month: "", year: "" },
      tob: { hours: "", minutes: "", period: "AM" },
    },
  });

  const [errors, setErrors] = useState({});

  console.log(formData, "formData");

  const validateForm = () => {
    const formErrors = {};

    // Name Validation
    if (!formData.boy.name.trim().match(/^[a-zA-Z]+ [a-zA-Z]+( [a-zA-Z]+)*$/)) {
      formErrors.boyName =
        "Please enter a valid full name for the boy (first and last name).";
    }
    if (
      !formData.girl.name.trim().match(/^[a-zA-Z]+ [a-zA-Z]+( [a-zA-Z]+)*$/)
    ) {
      formErrors.girlName =
        "Please enter a valid full name for the girl (first and last name).";
    }

    // Place of Birth Validation
    if (!formData.boy.placeOfBirth.trim()) {
      formErrors.boyPlaceOfBirth = "Place of birth for the boy is required.";
    }
    if (!formData.girl.placeOfBirth.trim()) {
      formErrors.girlPlaceOfBirth = "Place of birth for the girl is required.";
    }

    // DOB Validation
    const validateDOB = (dob) => {
      const day = dob.day;
      const month = dob.month;
      const year = dob.year;
      if (
        !day.match(/^(0[1-9]|[12][0-9]|3[01])$/) ||
        !month.match(/^(0[1-9]|1[012])$/) ||
        !year.match(/^\d{4}$/)
      ) {
        return "Please enter a valid date (DD-MM-YYYY).";
      }
      return null;
    };

    const boyDOBError = validateDOB(formData.boy.dob);
    if (boyDOBError) formErrors.boyDOB = boyDOBError;

    const girlDOBError = validateDOB(formData.girl.dob);
    if (girlDOBError) formErrors.girlDOB = girlDOBError;

    // TOB Validation
    const validateTOB = (tob) => {
      const hours = tob.hours;
      const minutes = tob.minutes;
      if (
        !hours.match(/^(0[0-9]|1[0-9]|2[0-3])$/) ||
        !minutes.match(/^[0-5][0-9]$/)
      ) {
        return "Please enter a valid time (HH:MM).";
      }
      return null;
    };

    const boyTOBError = validateTOB(formData.boy.tob);
    if (boyTOBError) formErrors.boyTOB = boyTOBError;

    const girlTOBError = validateTOB(formData.girl.tob);
    if (girlTOBError) formErrors.girlTOB = girlTOBError;

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleInputChange = (e, person, field) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [person]: {
        ...prev[person],
        [field]:
          name.includes("dob") || name.includes("tob")
            ? { ...prev[person][field], [name]: value }
            : value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Your API call logic goes here
      alert("Form submitted successfully!");
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validateForm()) {
  //     // API call here if the form is valid
  //     try {
  //       const response = await axios.post('https://your-api-endpoint.com/submit', formData);
  //       console.log('API Response:', response.data);
  //       alert('Form submitted successfully!');
  //     } catch (error) {
  //       console.error('API Error:', error);
  //       alert('Failed to submit the form. Please try again.');
  //     }
  //   }
  // };

  const SearchPlaceApi = async (query) => {
    const config1 = {
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const body = {
      district: query,
    };
    try {
      const res = await axios.post(
        `${VASHISHT_API_URL}place/search-place`,
        body,
        config1
      );
      console.log(res, "suggestions");
      setSuggestions(res.data.data);
      // Adjust based on API response structure
    } catch (err) {
      console.error("Error fetching places:", err);
    }
  };

  function findIndexOfRange(dateRanges, currentDate) {
    return dateRanges.findIndex((range) => {
      const [start, end] = range
        .split(" to ")
        .map((dateStr) => new Date(dateStr));
      return currentDate >= start && currentDate <= end;
    });
  }

  const yearindex = findIndexOfRange(result, currentDate);
  const [centerValue, setCenterValue] = useState(yearindex);

  console.log(suggestions, "suggestions");

  useEffect(() => {
    // SearchPlaceApi();
    GetCategories();
    HandleQuestions(0, "1012");
  }, []);

  return (
    <div className="content md:px-2">
      <NotificationAlert ref={notificationAlertRef} />
      <div
        className="cs_perloader"
        style={{ display: tabDisabled ? "" : "none", background: "none" }}
      >
        <span className="cs_perloader_text t-1/2">Loading...</span>
      </div>
      <div
        style={{
          paddingLeft: "-15px",
          paddingRight: "-15px",
        }}
      >
        <Container fluid className="px-0">
          <Row className="mb-0 mt-1 px-2" noGutters>
          <Col lg={12} xs={12} md={12}>
            <Card className="mb-3">
              <CardBody className="mt-0 shadow-sm rounded-sm">
                  <ButtonGroup
                    className="btn-group-toggle d-flex ml-[5px]"
                    data-toggle="buttons"
                    style={{ gap: 3 }}
                  >
                    <Row>
                      <Col md="12">
                        <Link
                          className="btn btn-simple"
                          style={{
                            height: "40px",
                            alignContent: "center",
                            padding: "5px 16px",
                            fontSize: "12px",
                            color: "#263148",
                            background: "transparent",
                            border: "1px solid #212529",
                            borderRadius: "4px",
                          }}
                          tabindex="18"
                          to="/acharya/dashboard"
                        >
                          Horoscope
                        </Link>
                        {/* <Link className="btn" href="#preddiv" style={{padding:"4px 16px",fontSize:"12px",color:"#1d8cf8",borderColor:"#1d8cf8",background:"transparent",border: "1px solid",borderRadius: "4px"}} tabindex="18" to="/acharya/dashboard">Prediction</Link> */}
                        <Link
                          className="btn active"
                          style={{
                            height: "40px",
                            alignContent: "center",
                            padding: "5px 16px",
                            fontSize: "12px",
                            color:"white",
                            backgroundImage: "linear-gradient(to bottom left, #344675, #263148, #344675)",
                            border: "1px solid #212529",
                            borderRadius: "4px",
                          }}
                          tabindex="18"
                          to="/acharya/questions"
                        >
                          Questions
                        </Link>
                      </Col>
                    </Row>
                  </ButtonGroup>
              </CardBody>
            </Card>
          </Col>
          </Row>
        </Container>
        <Container fluid className="px-0">
          <Row className="mb-0 mt-1 px-2" noGutters>
            <Card className="">
              <CardBody className="shadow-sm rounded-sm">
                <Tabs selectedIndex={tabIndex}>
                  <div
                    id="scroller"
                    className="scrolllist"
                    style={{
                      whiteSpace: "nowrap",
                      scrollBehavior: "smooth",
                      margin: "0 35px",
                      overflowX: "scroll",
                      overflowY: "hidden",
                      display: "flex",
                      gap: "8px",
                      maxWidth: "100%",
                    }}
                  >
                    {cateDetails.map((list, index) => (
                      <Tab className="tabs">
                        <button
                          key={index}
                          id={"tabs" + index}
                          className={
                            tabIndex === index ? "tab_btn_focus" : "tab_btn"
                          }
                          onClick={() =>
                            centeredScroll(index, list.categoryID, list.name)
                          }
                          // disabled={tabDisabled}
                        >
                          {list.name}
                        </button>
                      </Tab>
                    ))}
                    <Button
                      className="outline btn"
                      style={{
                        padding: "9px 10px",
                        position: "absolute",
                        zIndex: "0",
                        left: "14px",
                      }}
                      onClick={() => scrollContent("left")}
                    >
                      <i className="fa fa-arrow-left"></i>
                    </Button>
                    <Button
                      style={{
                        padding: "9px 10px",
                        position: "absolute",
                        zIndex: "0",
                        right: "14px",
                      }}
                      onClick={() => scrollContent("right")}
                    >
                      <i className="fa fa-arrow-right"></i>
                    </Button>
                  </div>

                  {console.log(cateDetails, "cateDetails")}

                  {cateDetails.map((item, index) => (
                    <TabPanel>
                      {item.categoryID == "-1" ? (
                        <form className="container" onSubmit={handleSubmit}>
                          <div className="row pt-5">
                            {/* Boy's Details */}
                            <div className="col-lg-6 py-2">
                              <div className="p-4 border rounded-lg border-dark">
                                <div className="flex">
                                  <h4 className="mb-0">Boy's details</h4>
                                  <button
                                    type="button"
                                    className="btn btn-sm py-2.5 px-3 btn-primary"
                                    onClick={() =>
                                      setFormData({
                                        ...formData,
                                        boy: {
                                          name: "",
                                          placeOfBirth: "",
                                          dob: { day: "", month: "", year: "" },
                                          tob: {
                                            hours: "",
                                            minutes: "",
                                            period: "AM",
                                          },
                                        },
                                      })
                                    }
                                  >
                                    Reset
                                  </button>
                                </div>

                                {/* Name */}
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder="Name"
                                  value={formData.boy.name}
                                  onChange={(e) =>
                                    handleInputChange(e, "boy", "name")
                                  }
                                />
                                {errors.boyName && (
                                  <div>
                                    <small style={{ color: "red" }}>
                                      {errors.boyName}
                                    </small>
                                  </div>
                                )}

                                {/* Place of Birth */}
                                <label>Place of birth</label>
                                <div className="relative">
                                  <input
                                    type="text"
                                    value={inputValue.placeName}
                                    name="place"
                                    onChange={(e) =>
                                      SearchPlaceApi(e.target.value)
                                    }
                                    tabindex="2"
                                    className={`form-control ${
                                      suggestions.length > 0 &&
                                      "rounded-t-lg rounded-ee-none rounded-es-none border-bottom-0"
                                    }`}
                                    placeholder="Place"
                                  />
                                  {suggestions.length > 0 && (
                                    <div
                                      style={{ border: "1px solid #929699" }}
                                      className="rounded-b-lg rounded-t-0 border-top-0 absolute bg-white z-10 w-full"
                                    >
                                      <div className="h-[200px] overflow-scroll mb-0">
                                        {suggestions.map((place, index) => (
                                          <div>
                                            <button
                                              className="list-none hover:bg-gray-300 cursor-pointer btn-block text-start px-2"
                                              key={index}
                                              role="button"
                                              onClick={() => (e) =>
                                                handleSuggestionClick(
                                                  e,
                                                  "boy",
                                                  place.placeName
                                                )}
                                            >
                                              {place.placeName} -{" "}
                                              {place.StateName} -{" "}
                                              {place.countryName}
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {/* <label>Place of birth</label>
                        <input
                          type="text"
                          className="form-control"
                          name="placeOfBirth"
                          placeholder="Place"
                          value={formData.boy.placeOfBirth}
                          onChange={(e) => handleInputChange(e, "boy", "placeOfBirth")}
                        /> */}
                                {errors.boyPlaceOfBirth && (
                                  <div>
                                    <small style={{ color: "red" }}>
                                      {errors.boyPlaceOfBirth}
                                    </small>
                                  </div>
                                )}

                                {/* DOB */}
                                <label>DOB</label>
                                <div className="flex" style={{ gap: 10 }}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="day"
                                    placeholder="Day"
                                    value={formData.boy.dob.day}
                                    onChange={(e) =>
                                      handleInputChange(e, "boy", "dob")
                                    }
                                  />
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="month"
                                    placeholder="Month"
                                    value={formData.boy.dob.month}
                                    onChange={(e) =>
                                      handleInputChange(e, "boy", "dob")
                                    }
                                  />
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="year"
                                    placeholder="Year"
                                    value={formData.boy.dob.year}
                                    onChange={(e) =>
                                      handleInputChange(e, "boy", "dob")
                                    }
                                  />
                                </div>
                                {errors.boyDOB && (
                                  <div>
                                    <small style={{ color: "red" }}>
                                      {errors.boyDOB}
                                    </small>
                                  </div>
                                )}

                                {/* TOB */}
                                <label className="mt-1">TOB</label>
                                <div className="flex" style={{ gap: 10 }}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="hours"
                                    placeholder="Hours"
                                    value={formData.boy.tob.hours}
                                    onChange={(e) =>
                                      handleInputChange(e, "boy", "tob")
                                    }
                                  />
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="minutes"
                                    placeholder="Minutes"
                                    value={formData.boy.tob.minutes}
                                    onChange={(e) =>
                                      handleInputChange(e, "boy", "tob")
                                    }
                                  />
                                  <select
                                    className="form-control"
                                    name="period"
                                    value={formData.boy.tob.period}
                                    onChange={(e) =>
                                      handleInputChange(e, "boy", "tob")
                                    }
                                  >
                                    <option>AM</option>
                                    <option>PM</option>
                                  </select>
                                </div>
                                {errors.boyTOB && (
                                  <div>
                                    <small style={{ color: "red" }}>
                                      {errors.boyTOB}
                                    </small>
                                  </div>
                                )}
                              </div>
                            </div>

                            {/* Girl's Details */}
                            <div className="col-lg-6 py-2">
                              <div className="p-4 border rounded-lg border-dark">
                                <div className="flex">
                                  <h4 className="mb-0">Girl's details</h4>
                                  <button
                                    type="button"
                                    className="btn btn-sm py-2.5 px-3 btn-primary"
                                    onClick={() =>
                                      setFormData({
                                        ...formData,
                                        girl: {
                                          name: "",
                                          placeOfBirth: "",
                                          dob: { day: "", month: "", year: "" },
                                          tob: {
                                            hours: "",
                                            minutes: "",
                                            period: "AM",
                                          },
                                        },
                                      })
                                    }
                                  >
                                    Reset
                                  </button>
                                </div>

                                {/* Name */}
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder="Name"
                                  value={formData.girl.name}
                                  onChange={(e) =>
                                    handleInputChange(e, "girl", "name")
                                  }
                                />
                                {errors.girlName && (
                                  <div>
                                    <small style={{ color: "red" }}>
                                      {errors.girlName}
                                    </small>
                                  </div>
                                )}

                                {/* Place of Birth */}
                                <label>Place of birth</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="placeOfBirth"
                                  placeholder="Place"
                                  value={formData.girl.placeOfBirth}
                                  onChange={(e) =>
                                    handleInputChange(e, "girl", "placeOfBirth")
                                  }
                                />
                                {errors.girlPlaceOfBirth && (
                                  <small style={{ color: "red" }}>
                                    {errors.girlPlaceOfBirth}
                                  </small>
                                )}

                                {/* DOB */}
                                <label>DOB</label>
                                <div className="flex" style={{ gap: 10 }}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="day"
                                    placeholder="Day"
                                    value={formData.girl.dob.day}
                                    onChange={(e) =>
                                      handleInputChange(e, "girl", "dob")
                                    }
                                  />
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="month"
                                    placeholder="Month"
                                    value={formData.girl.dob.month}
                                    onChange={(e) =>
                                      handleInputChange(e, "girl", "dob")
                                    }
                                  />
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="year"
                                    placeholder="Year"
                                    value={formData.girl.dob.year}
                                    onChange={(e) =>
                                      handleInputChange(e, "girl", "dob")
                                    }
                                  />
                                </div>
                                {errors.girlDOB && (
                                  <div>
                                    <small style={{ color: "red" }}>
                                      {errors.girlDOB}
                                    </small>
                                  </div>
                                )}

                                {/* TOB */}
                                <label className="mt-1">TOB</label>
                                <div className="flex" style={{ gap: 10 }}>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="hours"
                                    placeholder="Hours"
                                    value={formData.girl.tob.hours}
                                    onChange={(e) =>
                                      handleInputChange(e, "girl", "tob")
                                    }
                                  />
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="minutes"
                                    placeholder="Minutes"
                                    value={formData.girl.tob.minutes}
                                    onChange={(e) =>
                                      handleInputChange(e, "girl", "tob")
                                    }
                                  />
                                  <select
                                    className="form-control"
                                    name="period"
                                    value={formData.girl.tob.period}
                                    onChange={(e) =>
                                      handleInputChange(e, "girl", "tob")
                                    }
                                  >
                                    <option>AM</option>
                                    <option>PM</option>
                                  </select>
                                </div>
                                {errors.girlTOB && (
                                  <div>
                                    <small style={{ color: "red" }}>
                                      {errors.girlTOB}
                                    </small>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Submit Button */}
                          <div className="row">
                            <div
                              className="col-12 py-2"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                type="submit"
                                className="btn btn-sm py-2.5 px-3"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      ) : item.categoryID == "1011" ? (
                        <>
                          <Varshfal
                            index={index}
                            result={result}
                            centerValue={centerValue}
                            yearindex={yearindex}
                            item={item}
                            questions={questions}
                            isLoading={isLoading}
                            HandleAnswers={HandleAnswers}
                            setCenterValue={setCenterValue}
                            HandleQuestions={HandleQuestions}
                            tabDisabled={tabDisabled}
                          />
                        </>
                      ) : (
                        <Accordions
                          data={questions}
                          isLoading={isLoading}
                          tabnames={item.name}
                          handleQuestionAns={HandleAnswers}
                        />
                      )}
                    </TabPanel>
                  ))}
                </Tabs>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Questionanswer;
