import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

export const Threedotscomppred = ({isclass}) => {
  return (
    <div className={isclass ? " ": "loaderdiv1"}>
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
          <span><strong className='text-[12px]'>Your Kundali is Preparing</strong> <span class="blink">...</span></span>
          {/* <ThreeDots height="40" width="40"  radius="9" color="#1d8cf8" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true}/> */}
        </div>
    </div>
  )
}
