import RegistrationCrm from "components/Registration/registrationCrm";
import LoginCrm from "components/auth/LoginCrm";
import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
const Crm = ({ username, uri = "/login", uri_name = "Log in", lang }) => {
  const [activeNav, setActiveNav] = useState(false);
  const [activeAnch, setActiveAnch] = useState(0);

  const closeDrawer = (event) => {
    setActiveAnch(event);
    setActiveNav(false);
  };

  const closeDrawerLangHindi = (event) => {
    lang("hin");
    setActiveNav(false);
  };

  const closeDrawerLangEng = (event) => {
    lang("eng");
    setActiveNav(false);
  };
  return (
    <div>
      <header className="w-full z-3  bg-gradient-to-r from-violet-400 to-pink-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10">
        <div className="max-w-full mx-auto px-4 sm:px-0">
          <div className="flex items-center justify-between h-20">
            {/* Site branding */}
            <div className="shrink-0 mr-4">
              {/* Logo */}
              <Link
                to={uri}
                className="block text-[#000] text-2xl font-bold"
                aria-label="Cruip"
              >
                <img src={Logo} alt={"GDV PANEL"} width={"70%"} />
              </Link>
            </div>

            {/* Desktop navigation */}
            <nav className="hidden md:flex md:grow">
              {/* Desktop sign in links */}
              <ul className="flex grow justify-end flex-wrap items-center">
                <li>
                  <Link
                    to={"all-transaction"}
                    className="font-medium text-white hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                  >
                    All transactions
                  </Link>
                </li>
                <li>
                  <Link
                    to={uri}
                    className="font-medium text-white hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                  >
                    {uri_name}
                  </Link>
                </li>
                <li>
                  <div
                    // to="/registration"
                    disabled
                    className="btn-sm py-2 text-white ml-3"
                  >
                    <i className="fa fa-user"></i> {username}
                  </div>
                </li>
              </ul>
            </nav>

            {/* <MobileMenu /> */}
          </div>
        </div>
      </header>
      <header
        className="cs_site_header cs_style_1 cs_text_dark cs_sticky_header cs_medium cs_sticky_active bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="cs_main_header">
          <div className="container">
            <div className="cs_main_header_in">
              <div className="shrink-0 mr-4">
                <a
                  href={"/"}
                  className="block text-[#000] text-2xl font-bold"
                  aria-label="Cruip"
                >
                  <img src={logo} alt={"GDV PANEL"} width={"80%"} />
                </a>
              </div>
              <div className="cs_main_header_left w-fit">
                <nav className="cs_nav cs_medium cs_primary_font">
                  <ul
                    className={`cs_nav_list cs_onepage_nav relative ${
                      activeNav ? "cs_active" : ""
                    }`}
                  >
                    <div className="inline-flex rounded-md shadow-sm absolute top-6 left-[50%] right-[50%] lg:hidden">
                      <div className="inline-flex rounded-md shadow-sm max-lg:hidden">
                        <Link
                          to="/registration"
                          className="cs_btn cs_bg_accent cs_modal_btn max-lg:block"
                          data-modal="register"
                        >
                          <i className="fa fa-user"></i> {username}
                        </Link>
                      </div>
                    </div>
                    <li className="max-lg:block lg:hidden">
                      <Link
                        to="/crm-registration"
                        className="max-lg:block px-3 text-dark"
                        data-modal="register"
                      >
                        Register
                      </Link>
                    </li>
                    <li className="max-lg:block lg:hidden">
                      <Link
                        className="cs_header_text_btn cs_modal_btn"
                        to="/login"
                      >
                        Login
                      </Link>
                    </li>

                    <li className="menu-item-has-children">
                      <Link
                        to="/"
                        className={activeAnch === 0 ? "active-link" : ""}
                        onClick={() => closeDrawer(0)}
                      >
                        Home
                      </Link>
                    </li>

                    <li className="menu-item-has-children">
                      <Link
                        to="/all-transaction"
                        className={activeAnch === 0 ? "active-link" : ""}
                        onClick={() => closeDrawer(0)}
                      >
                        All transactions
                      </Link>
                    </li>
                  </ul>
                  {activeNav && (
                    <span
                      className="cs_menu_toggle cs_toggle_active z-[20]"
                      onClick={() => setActiveNav(false)}
                    >
                      <span></span>
                    </span>
                  )}
                </nav>
              </div>
              <div className="cs_main_header_right">
                <div className="cs_header_btns">
                  <Link
                    className="cs_header_text_btn text-dark cs_modal_btn max-lg:hidden"
                    to="/crm-agent"
                  >
                  Log out
                  </Link>
                  <div className="max-lg:hidden">
                    <Link
                       to="/crm-registration"
                      className="cs_btn cs_bg_accent cs_modal_btn max-lg:block"
                      data-modal="register"
                    >
                     New Register
                    </Link>
                  </div>
                  <div className="inline-flex rounded-md shadow-sm max-lg:hidden">
                    <Link
                      className="cs_btn cs_bg_accent cs_modal_btn max-lg:block"
                      data-modal="register"
                    >
                      <i className="fa fa-user"></i> {username}
                    </Link>
                  </div>
                </div>
                {!activeNav && (
                  <span
                    className="cs_menu_toggle"
                    onClick={() => setActiveNav(true)}
                  >
                    <span></span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Crm;
