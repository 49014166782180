import {
  CHECK_PLAN,
} from "../actions/constants";
const initialState = []

export default function(state=initialState, action){
    const {type, payload} = action
    switch(type){
        case CHECK_PLAN:
            return payload
        default:
            return state
    }
}
