import axios from 'axios';
import NotificationDisplay from 'components/Dashboard/NotificationDisplay'
import React, { useEffect, useState } from 'react'
import { Link, useHistory,useParams } from 'react-router-dom'
import { Card, CardBody, CardHeader, Input } from 'reactstrap';
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const NewQuery = () => {
  // let history = useHistory();
  //const acharya = localStorage.getItem("user");
  
  const [supportdata, setSupportdata] = useState({
    issue:"",
    message:"",
    reply:"",
    date:"",
    reply_date:""
  })
  // const {id} = useParams();
  // console.log("Url Id==================",id);
  //const userdetails = JSON.parse(acharya);
  //const id = userdetails.id; 
  const {id} = useParams();
  const loadsupport = async()=>{
    try {
      const supportData = await axios.get(`${BAS_URL_APPAPI}acharya/sugestionbyid/${id}`)
      console.log("All Sugestion==============",supportData)   
      setSupportdata(supportData.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    loadsupport();
  }, [])
  
  return (
    <div className='content'>
      {/* <NotificationDisplay/>       */}
      <div className='add-suggestion'>
        <Card>
          <CardHeader className='cardheaderall'>
            <Link className="btn btn-outline-light submitbtn" to="/acharya/support"><i className='fa fa-arrow-left'></i> Back</Link>
            <h4 className='text-center'>Add Sugestion</h4>
          </CardHeader>
          <CardBody>
            <div className='form-group'>
              <label>Issue</label>
              <input type="text" className="form-control" value={supportdata.issue} readOnly/>
              <label>Message</label>
              <textarea type="text" className="form-control" value={supportdata.message}/>
              <label>Response</label>
              <textarea type="text" className="form-control" value={supportdata.reply} />
              <label>Response By</label>
              <input type="text" className="form-control" value={supportdata.reply_by} />
              <label>Query Date</label>
              <textarea type="text" className="form-control" value={supportdata.date} readOnly/>
              <label>Response Date</label>
              <input type="text" className="form-control" value={supportdata.reply_date} readOnly/>
            </div>
          </CardBody>
        </Card>
      </div>
      {/* <div>
        <p>{supportdata.issue}</p>
        <p>{supportdata.message}</p>
        <p>{supportdata.reply}</p>
        <p>{supportdata.reply_by}</p>
        <p>{supportdata.date}</p>
        <p>{supportdata.reply_date}</p>
      </div> */}
    </div>
  )
}

export default NewQuery