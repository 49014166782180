import React, { useEffect } from 'react'
import {useSelector} from 'react-redux'
import { Redirect, Route } from 'react-router'
function PrivateRoute({ component: Component, ...rest}) {
    const authenticated = useSelector(state => state.auth.isAuthenticated)
    // const loading = useSelector(state => state.auth.loading)
    useEffect(() => {
        // const handleContextmenu = e => {
        //     e.preventDefault()
        // }
        // document.addEventListener('contextmenu', handleContextmenu)
        // return function cleanup() {
        //     document.removeEventListener('contextmenu', handleContextmenu)
        // }
  
        // Stop reload and refresh
        const unloadCallback = (event) => {
          event.preventDefault();
          event.returnValue = "";
          return "";
        };
      
        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, [ ])

    return (
        <Route 
        {...rest} 
        render={props => !authenticated 
            ? 
            (<Redirect to="/login" />)
            :
            (<Component {...props} />)
        } />
        
    )
}

export default PrivateRoute
