import React from "react";
import IconBank from "../../assets/landing/img/shop.svg";
import priceBar from "../../assets/landing/img/price_bar.svg";
import setup from "../../assets/landing/img/setup.svg";
import ui from "../../assets/landing/img/ui.svg";
import vector from "../../assets/landing/img/Vector.svg";
import arrow from "../../assets/landing/img/arrow.png";
import illustartion1 from "../../assets/landing/img/illustartion1.webp";

const CuttingEdge = ({lang = "eng"}) => {

    const CuttingEdges = {
      eng :  [{
          heading: "As the role of astrology in our lives becomes increasingly prominent, people are turning to astrologists, or acharyas, and even those who have the slightest possible knowledge of it. Therefore, our application, or, simply put, software, is unavoidably useful for the following individuals/users.",
          sub_heading: "Who can use this Software?",
          points: [
            {
              num: "01",
              img: arrow,
              title: "Professionals and Practitioners",
              sub_title:
                "If you are an acharya or an astrologer, our astrology software is definitely the one stop solution for your requirements, whether for professional practice or personal use.",
            },
            {
              num: "02",
              img: arrow,
              title: "Astrology Beginners and Learners",
              sub_title:
                "Whether you are a beginner in astrology or learning the foundational concepts, this advanced panel is a crucial companion during your astroscience academic journey.",
            },
          ],
        }],
        
        hin :  [{
          heading: "जैसे-जैसे हमारे जीवन में ज्योतिष की भूमिका बढ़ती जा रही है, लोग ज्योतिषियों, या आचार्यों, और यहां तक कि जिनके पास इसका सबसे छोटा संभव ज्ञान है, उनकी ओर रुख कर रहे हैं। इसलिए, हमारा एप्लिकेशन, या सीधे कहें, कुंडली सॉफ्टवेयर,निम्न-लिखितव्यक्तियों के लिए अनिवार्य रूप से उपयोगी है।",
          sub_heading: "इस अत्याधुनिक सॉफ्टवेयर का उपयोग कौन कर सकता है?",
          points: [
            {
              num: "01",
              img: arrow,
              title: "पेशेवर और प्रैक्टिशनर",
              sub_title:
                "यदि आप एक आचार्य या ज्योतिषी हैं, तो हमारा ज्योतिष सॉफ्टवेयर आपकी आवश्यकताओं के लिए एकमात्र समाधान है, चाहे वह एक पेशेवर के रूप में अभ्यास हो या केवल आपके व्यक्तिगत उपयोग के लिए हो।",
            },
            {
              num: "02",
              img: arrow,
              title: "ज्योतिष के नवागंतुक और सीखने वाले",
              sub_title:
                "चाहे आप ज्योतिष की बुनियादी बातें शुरू कर रहे हों या बुनियादी अवधारणाओं को सीख रहे हों, यह सर्वोत्तम पैनल आपके ज्योतिष विज्ञान अकादमिक यात्रा/जीवन का एक महत्वपूर्ण साथी है।",
            },
          ],
        }],

    };
  return (
         <section className="cs_user_feature">
            <div className="container-custom">
              <div className="row align-items-center cs_gap_y_40 cs_reverse_lg">
                <div
                  className="col-xl-6 wow"
                  data-aos="fade-in"
                  data-aos-duration="800"
                  data-aos-delay="200"
                >
                 {CuttingEdges[lang].map((item_main)=>(
                    <>
                    <div className="cs_section_heading cs_style_1">
                    <h2 className="cs_section_title mb-0">
                      {item_main.sub_heading}
                    </h2>
                    <p className="cs_section_subtitle cs_text_accent">
                      {item_main.heading}
                    </p>
                  </div>
                  <div className="cs_height_60 cs_height_lg_40"></div>
                  <ul className="cs_list cs_style_1 cs_mp0">
                    {item_main.points.map((item,index)=> <li className={index}>
                      <span className="cs_list_icon">
                        <img src={item.img} alt="Icon" />
                      </span>
                      <span className="cs_list_text text-gray-900">{item.title}</span>
                      <p className="cs_iconbox_subtitle text-gray-600">
                      {item.sub_title}
                    </p>
                    </li>)}
                  </ul></>))}
                </div>
                <div
                  className="col-xl-6 wow fadeInRight"
                  data-aos="fade-left"
                  data-aos-duration="800"
                  data-aos-delay="200"
                >
                  <div className="cs_feature_thumb_1 text-center">
                    <img src={illustartion1} alt="illustartion1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="cs_height_150 cs_height_lg_80"></div>
          </section>
  )
}

export default CuttingEdge
