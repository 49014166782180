import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { login } from "../../redux/actions/auth";
import Alerts from "components/Alert/Alerts";
import "./Login.css";
import Logo from "../../assets/img/logo.png";
import discount from "../../assets/img/horooffer.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import FooterNew from "components/Footer/FooterNew";
import NotificationAlert from "react-notification-alert";
import { CListGroup } from "@coreui/react";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Crm from "views/pages/Crm";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
const PLANAPI = process.env.REACT_APP_PLAN_API;

function LoginCrm() {
  // const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const [authenticated, setAuthenticated] = useState("");
  const notificationAlertRef = React.useRef(null);
  let [err, setErr] = useState("");
  let [success, setSuccess] = useState("");
  const history = useHistory();
  const loginFailed = useSelector((state) => state.auth.loginFailed);


  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    userName: "",
  });

  console.log(err, "err");
  // const [eye, setEye] = useState(true);

  const onChange = (e) =>
    setFormData({ ...formData, userName: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (formData.userName === "") {
      setErr("Empty username!");
      setSuccess("");
      return;
    } else {
      
      // console.log(formData, "formData");

      try {
        const res = await axios.post(
          `${BAS_URL_APPAPI}getTokenForCRM`,
          formData
        );

        if (res.data.success === 1) {
          localStorage.setItem("crm-agent", JSON.stringify(formData.userName));
          setSuccess("login successfully");
          setAuthenticated(true);
        }
      } catch (err) {
        if (err.response.data.success === 0) {
          setErr("Invalid username");
        }
      }
    }

    // setSubmitClicked(true);
    // localStorage.setItem("kundalidata", JSON.stringify(dataObj));
    // console.log("Data object in franchise credit kundali=====", dataObj);
  };
  //Redirect if logged in
  if (authenticated) {
    return <Redirect to="/crm-registration" />;
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef}></NotificationAlert>
      <div className="home-background bg-gray-100 align-center justify-center flex h-100vh flex-col justify-between relative">
        <div className="flex flex-col justify-between pt-4 pb-4 sm:flex-row absolute md:bottom-0 right-10">
          <p className="text-sm text-dark pt-1">
            © {new Date().getFullYear()} Reserved by Deep TechnoServices LLP
          </p>
        </div>
        {/* <Crm /> */}
        {discount ? (
          <div className="container py-4 justify-center flex flex-col h-full">
            <div className="row ">
              <div className="col-lg-6 col-md-12 justify-center flex flex-col mx-auto">
                <div className="xl:w-2/3 2xl:w-1/2  h-auto pt-4 pb-5 shadow-lg shadow-indigo-500/40 px-4 rounded-lg mx-auto px-10 lg:px-16 xl:px-12 bg-white">
                  <div className="d-flex w-100 justify-center align-center flex-column pb-4">
                    {/* <div className="position-center flex justify-center items-center">
                        <img
                          src={Logo}
                          alt=""
                          className="object-contain xl:w-25"
                        />
                      </div> */}
                    <h1 className="text-xl md:text-2xl font-semibold leading-tight text-gray-600 text-center mt-2">
                      <div className="my-0">GDV CRM LOGIN</div>
                    </h1>
                  </div>

                  <form
                    className="mt-0"
                    action="#"
                    method="POST"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <div>
                      <label className="block text-gray-700">Enter User Name</label>
                      <input
                        type="text"
                        name="userName"
                        id=""
                        placeholder="Enter User Name"
                        className="w-full px-4 py-2 rounded-lg bg-gray-200 mt-1 border focus:border-blue-500 focus:bg-white focus:outline-none"
                        autofocus
                        autocomplete
                        onChange={(e) =>
                          setFormData({ ...formData, userName: e.target.value })
                        }
                      />
                    </div>

                    <button
                      type="submit"
                      className="w-full block bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
                   px-4 py-2 mt-6"
                    >
                      Submit
                    </button>
                  </form>
                  {err && (
                    <div className="text-center">
                      <small style={{ color: "red" }}>{err}</small>
                    </div>
                  )}
                  {success && (
                    <div className="text-center">
                        {success}
                        <small style={{ color: "green" }}>{success}</small>
                      </div>
                  )}

                  {/* <p className="mt-2 text-center">
                  <Link
                    to="/registration"
                    className="text-blue-500 hover:text-blue-700 font-semibold"
                  >
                    Register
                  </Link>
                </p> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default LoginCrm;
