import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage';

import themeReducer from './themeReducer'
import auth from './auth'
import alert from './alert'
import remedy from './remedy'
import product from './product'
import horoscope from './horoscope'
import question from './questionanswer'
//import prediction from "./horoscope"
import selectedproduct from './selectedproduct'
import selectedlanguage from './selectedlanguage'
import plan from './plan';
import updateExpireReducer from './updateExpireReducer';


const persistConfig = {
    key: 'primary',
    storage,
    whitelist: ['themeReducer', 'auth', 'plan']
}

const rootReducer = combineReducers({
    themeReducer,
    auth,
    alert,
    remedy,
    product,
    horoscope,
   // prediction,
    question,
    selectedproduct,
    selectedlanguage,
    plan,
    updateExpireReducer
})

export default persistReducer(persistConfig, rootReducer)
// export default rootReducer