import { UPDATE_EXPIRED} from "../actions/constants"

let initialState = {
    data:false,
}

export default function(state = initialState, action) {
    switch(action.type){
        case UPDATE_EXPIRED:     
            state.data = action.payload    
            return {...state}
            
        default:
            return state
    }
}




