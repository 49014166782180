import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
//import Dragmodal from './Dragmodal'

import { useDispatch, useSelector } from "react-redux";
import { Nav, NavItem, NavLink } from "react-bootstrap";

const Subhasubh = () => {
  const allData = useSelector((state) => state.horoscope);
  const colorChart = allData.horo.colorChart;
  const [modal, setModal] = useState(false);
  const togglemodal = () => setModal(!modal);

  //console.log("colorChartcolorChartcolorChartcolorChartcolorChart",colorChart);
  return (
    <>
      <Row>
        <Col md="12">
          <Card className="p-0 mb-0 pb-0 pt-1">
            <Nav className="top-0 mb-2 ">
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  gap: 10,
                  paddingBottom: "3px",
                }}
              >
                <NavItem className="" >
                  <Row>
                    <Col md="6">
                      <div
                        style={{
                          borderRadius: "30px",
                          padding: "5px 0px",
                          fontSize: "12px",
                          border: "none",
                          fontWeight: "600",
                          color: "#000",
                          fontFamily: "poppins",
                        }}
                      >
                        Planets
                      </div>
                    </Col>
                    <Col md="6 d-flex" style={{ gap: 10 }}>
                      <div
                        style={{
                          borderRadius: "30px",
                          padding: "5px 0px",
                          fontSize: "12px",
                          border: "none",
                          fontWeight: "600",
                          color: "#000",
                          fontFamily: "poppins",
                        }}
                      >
                        <div className="flex items-center gap-x-1">
                          <div className="h-3 w-3 bg-[#a6dda6]"></div>Favourable
                        </div>
                      </div>
                      <div
                        style={{
                          borderRadius: "30px",
                          padding: "5px 0px",
                          fontSize: "12px",
                          border: "none",
                          fontWeight: "600",
                          color: "#000",
                          fontFamily: "poppins",
                        }}
                      >
                        <div className="flex items-center gap-x-1">
                          <div className="h-3 w-3 bg-[#d67752]"></div>
                          UnFavourable
                        </div>
                      </div>
                    </Col>
                  </Row>
                </NavItem>
              </div>
            </Nav>
            <CardBody className="p-0 mb-0">
              <Table striped bordered hover className="mahadasha mb-3">
                <thead className="p-0  bg-gray-100">
                  <tr>
                    <th className="px-2 py-2 text-[12px]">Planet</th>
                    <th className="px-2 py-2 text-[12px]">Degree</th>
                    <th className="px-2 py-2 text-[12px]">RL-NL-SL-SSL</th>
                    <th className="px-2 py-2 text-[12px]">Significators</th>
                  </tr>
                </thead>
                {/* <tbody>
                        {colorChart && colorChart.map((val,index) => (
                        <tr key={index} style={{backgroundColor: val.isBad ==="Shubh" ? "#5e72e4" : "#ffc400"}}>
                            <td onClick={togglemodal}>{val.planetName}</td>
                            <td>{val.DegreePlanet}</td>
                            <td>{val.RL}- {val.NL}- {val.SL}- {val.SSL}</td>
                            <td>चंद्र शुक्र | शनि</td>
                        </tr>  
                        ))}                                                                               
                    </tbody> */}
                <tbody className="mb-0">
                  {colorChart &&
                    colorChart.map((val, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor:
                            val.isbad === "Shubh" ? "#a6dda6" : "#d77955",
                        }}
                      >
                        <td onClick={togglemodal}>{val.planetName}</td>
                        <td>{val.DegreePlanet}</td>
                        <td>
                          {val.RL}- {val.NL}- {val.SL}- {val.SSL}
                        </td>
                        <td>{val.Specification}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Subhasubh;
