import { GET_PRODUCTS} from '../actions/constants'

const initialState = []

export default function(state=initialState, action){
    const {type, payload} = action
    switch(type){
        case GET_PRODUCTS:
            return payload.products
        default:
            return state
    }
}