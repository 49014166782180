import { QUESTION_POST} from '../actions/constants'

const initialState = []

export default function(state=initialState, action){
    const {type, payload} = action
    switch(type){
        case QUESTION_POST:
            return payload.question
        default:
            return state
    }
}