import Header from "components/Header";
import Footer from "components/Landing/Footer";
import React, { useEffect } from "react";

const ShippingAndDelivery = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          background: "#fff",
        }}
      >
        <Header text={"Shipping & Delivery"} />
        <section className="dark:bg-gray-100 dark:text-gray-800 bg-white flex justify-center align-center lg:py-20">
          <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
            <div className="flex flex-col sm:px-8 lg:px-12 xl:px-32 ">
              <div className="px-0 pb-2">
                <p className="text-gray-800 text-justify lg:text-base">
                  No Tangible Products Are Available On This Website. The
                  Products And Services Are Instantly Delivered. No Products Are
                  Shipped From This Website.
                </p>
                <p className="text-gray-800 text-center lg:text-base">
                  Deep TechnoServices LLP
                  <br /> 3rd Floor, C-97, Mckenzie Tower, Phase-2, Mayapuri,
                  West Delhi Pin: 110064
                  <br />
                  Email: care@astroscience.in
                  <br />
                  Contact Number: +91-9821397676
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer/>
    </>
  );
};

export default ShippingAndDelivery;

const style = {
  container: {
    alignItems: "center",
    textAlign: "center",
    marginTop: "20px",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  content: {
    fontSize: "16px",
    color: "#fff",
    marginTop: "20px",
    textAlign: "left",
  },
};
