import React, { useCallback, useEffect, useRef, useState } from "react";
import Heading from "../../components/Landing/Heading";
import "../../assets/landing/css/animate.css";
import "../../assets/landing/css/style.css";
import "../../assets/landing/css/fontawesome.min.css";
import "../../assets/landing/css/custom.css";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Hero from "components/Landing/Hero";
import Leading from "components/Landing/Leading";
import Grade from "components/Landing/Grade";
import CuttingEdge from "components/Landing/CuttingEdge";
import Pricing from "../../components/Landing/Pricing";
import Testimonials from "components/Landing/Testimonials";
import Faq from "components/Landing/Faq";
import Footer from "components/Landing/Footer";
import MovedUp from "components/Landing/MovedUp";
import ScrollTrigger from "react-scroll-trigger";
import Others from "components/Landing/Others";
import ModalVideo from "react-modal-video";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Slideshow from "components/Landing/Slideshow";
import New from "components/Landing/New";

const Landing = () => {
  const [loader, SetLoader] = useState(true);
  const [lang, setlang] = useState("eng");
  const [isOpen, setOpen] = useState(false);
  // const [langDefault, setlangDefault] = useState(lang);
  const mylink = useLocation();
  const link = mylink.state;

  function handleChange() {
    setOpen(!isOpen);
  }

  // scroll by link
  const scrollToSection = (id) => {
    if (id) {
      const section = document.getElementById(id);
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // handle asyn operation
  useEffect(() => {
    setTimeout(() => {
      SetLoader(false);
    }, 300);

    // animation
    AOS.init({
      useClassNames: true,
      initClassName: false,
      animatedClassName: "animated",
    });

    scrollToSection(link);

    localStorage.setItem("language", lang);
    const language = localStorage.getItem("language");

    if (language) {
      setlang(language);
    } else {
      setlang(lang);
    }
  }, [link]);

  const Loader = () => {
    return (
      <div className="cs_perloader" style={{ display: loader ? "" : "none" }}>
        <div className="cs_perloader_in">
          <div className="cs_perloader_dots_wrap">
            <div className="cs_perloader_dots">
              <i></i>
              <i></i>
              <i></i>
              <i></i>
            </div>
          </div>
        </div>
        <span className="cs_perloader_text">Loading...</span>
      </div>
    );
  };


  useEffect(() => {
    let langs = localStorage.getItem("langDefault");
    if(langs){
      setlang(langs)
    }else{
      setlang(lang) 
    }
  },[])

  return (
    <>
      <Loader />
      <div>
        <Heading lang={setlang} langDefault={lang} />
        <div className="cs_content">
          <Hero lang={lang} />
          <New lang={lang} />
          <Slideshow lang={lang} />
          <Leading lang={lang} />
          <Grade lang={lang} />
          <CuttingEdge lang={lang} />
          <Pricing lang={lang} />
          <Testimonials lang={lang} />
          <Faq lang={lang} />
          {/* <Others lang={lang} /> */}
        </div>
        <Footer />
        <MovedUp />

        <ModalVideo
          className="model-video"
          chanel="youtube"
          autoplay={1}
          loop="1"
          isOpen={isOpen}
          videoId="YrjbJajCDoI"
          onClose={handleChange}
        />
      </div>
    </>
  );
};

export default Landing;
