import React from "react";
import { Badge } from "reactstrap";

const ProfileIcon = ({ imageUrl, altText, notifications, userDetail }) => (
  <div
    style={{
      position: "relative",
      display: "inline-block",
      paddingTop: "6px ",
    }}
  >
    <div className="max-lg:hidden flex items-center justify-center cursor-pointer">
      <img
        src={imageUrl}
        alt={altText}
        style={{
          width: "35px",
          height: "35px",
          borderRadius: "50%", // Makes the image circular
          objectFit: "cover",
        }}
        className="border-stone-100 bg-slate-600"
      />
      <div className="text-gray-900 font-thin ms-2 flex items-center justify-center">
        {userDetail?.name} <i className="fa fa-caret-down text-sm ms-1"></i>
      </div>
    </div>
    {notifications > 0 && (
      <Badge
        color="danger"
        pill
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          transform: "translate(25%, -25%)",
        }}
      >
        {notifications}
      </Badge>
    )}
  </div>
);

export default ProfileIcon;
