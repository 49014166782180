import axios from "axios";
import React, { useEffect, useState } from "react";
import Vector3 from "../../assets/landing/img/Vector3.svg";
import dot from "../../assets/landing/img/dot.svg";
import symbol from "../../assets/landing/img/symbol.svg";
import symbol1 from "../../assets/landing/img/symbol1.svg";
import symbol3 from "../../assets/landing/img/symbol3.svg";
import { Link } from "react-router-dom";
const PLANAPI = process.env.REACT_APP_PLAN_API;

const Pricing = ({ lang = "eng" }) => {
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const Plans = async () => {
    setIsLoading(true);
    // console.log("entered");
    try {
      const res = await axios.get(`${PLANAPI}`);
      // console.log(res, "my plan response");
      setPlans(res.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err, "err");
      setIsLoading(true);
    }
  };

  const Pricings = {
    eng: [
      {
        heading: "Pricing of Our Kundali Software",
        sub_heading:
          "Keeping in mind the necessities and requirements of every user, we have designed a pricing structure that brings a smile to each of you.",
        points: [
          {
            img: "",
            title: "Vaishali",
            city: "Agra",
            sub_title:
              "For a professional like me, the panel has proved to be a significant earning potential, especially during the Covid 19 pandemic, which left a major dent in my income stream. And it was a great platform to earn right from the comfort of my home.",
          },
          {
            img: "",
            title: "Kiran Chawla",
            city: "Allahabad",
            sub_title:
              "Even if I put in only the slightest amount of effort, I earn almost around 35K monthly. This is amazing! I know I can take this bracket even to a new level, provided I invest a little bit extra time; the more I work, the higher my income will be.",
          },
          {
            img: "",
            title: "Vinod Sharma",
            city: "Delhi",
            sub_title:
              "The engagement with this advanced astrology software has turned out to be a full-time profession for me. Moreover, it has job security for people like us; simply serve the people and earn to sustain your livelihood. It's a great tool for me!",
          },
          {
            img: "",
            title: "Sangeeta",
            city: "Eta (U.P.)",
            sub_title:
              "Earlier, when analyzing the Kundali, I used to end up spending a lot of time. But now, my practice has become both simpler and faster than before. With this, the amount of effort has reduced manifold while benefiting my clients significantly.",
          },

          {
            img: "",
            city: "Neeta Sharma",
            title: "Delhi",
            sub_title:
              "After association with this high-tech medium—one of the best Kundali software tools—my income has definitely increased. However, above all else, my confidence has elevated immensely. It is my saviour and a superb asset for me!",
          },
        ],
      },
    ],
    hin: [
      {
        heading: "हमारे ज्योतिष - कुंडली सॉफ्टवेयर की कीमत",
        sub_heading:
          "हर उपयोगकर्ता की आवश्यकताओं और अपेक्षाओं को ध्यान में रखते हुए, हमने एक मूल्य संरचना तैयार की है जो आप में से प्रत्येक को मुस्कान देती है।",
        points: [
          {
            img: "",
            title: "Vaishali",
            city: "Agra",
            sub_title:
              "For a professional like me, the panel has proved to be a significant earning potential, especially during the Covid 19 pandemic, which left a major dent in my income stream. And it was a great platform to earn right from the comfort of my home.",
          },
          {
            img: "",
            title: "Kiran Chawla",
            city: "Allahabad",
            sub_title:
              "Even if I put in only the slightest amount of effort, I earn almost around 35K monthly. This is amazing! I know I can take this bracket even to a new level, provided I invest a little bit extra time; the more I work, the higher my income will be.",
          },
          {
            img: "",
            title: "Vinod Sharma",
            city: "Delhi",
            sub_title:
              "The engagement with this advanced astrology software has turned out to be a full-time profession for me. Moreover, it has job security for people like us; simply serve the people and earn to sustain your livelihood. It's a great tool for me!",
          },
          {
            img: "",
            title: "Sangeeta",
            city: "Eta (U.P.)",
            sub_title:
              "Earlier, when analyzing the Kundali, I used to end up spending a lot of time. But now, my practice has become both simpler and faster than before. With this, the amount of effort has reduced manifold while benefiting my clients significantly.",
          },

          {
            img: "",
            city: "Neeta Sharma",
            title: "Delhi",
            sub_title:
              "After association with this high-tech medium—one of the best Kundali software tools—my income has definitely increased. However, above all else, my confidence has elevated immensely. It is my saviour and a superb asset for me!",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    Plans();
  }, []);

  return (
    <section
      id="pricing"
      className="cs_section_shape_wrap_1 cs_gradient_bg_1 position-relative overflow-hidden"
    >
      <div className="cs_section_shape_1 position-absolute">
        <img src={Vector3} alt="Shape" />
      </div>
      <div className="cs_section_shape_2 position-absolute">
        <img src={dot} alt="Shape" />
      </div>
      <div className="cs_height_143 cs_height_lg_75"></div>
      {Pricings[lang].map((main_item) => (
        <div className="container-custom">
          <div className="cs_section_heading cs_style_1 cs_style_1 text-center">
            {/* <p
            className="cs_section_subtitle cs_text_accent wow"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            Our Pricing
          </p> */}
            <h2
              className="cs_section_title mb-0 wow"
              data-aos="fade-in"
              data-aos-duration="800"
              data-aos-delay="200"
            >
              {main_item.heading}
            </h2>
            <p
              className="cs_section_subtitle cs_text_accent"
              data-aos="fade-in"
              data-aos-duration="800"
              data-aos-delay="200"
            >
              {main_item.sub_heading}
            </p>
          </div>
          <div className="cs_height_85 cs_height_lg_60"></div>
          <div
            className="row cs_gap_y_30 wow justify-center"
            data-aos="fade-in"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            {plans.map((item, index) => (
              <div key={index} className="col-lg-4">
                <Link to="registration"><div className="cs_pricing_table cs_style_1">
                  <div className="cs_pricing_head">
                    {/* <div className="cs_pricing_icon">
                  <img src={symbol} alt="" />
                    </div> */}
                    <div className="cs_pricing_head_text">
                      <h3 className="cs_pricing_head_title">{item.name}</h3>
                      {/* <p className="cs_pricing_head_subtitle mb-0">
                    For small business
                  </p> */}
                    </div>
                  </div>
                  <div className="cs_pricing_seperator"></div>
                  <div className="cs_pricing_plan">
                    <h3 className="cs_price_value">₹{item.price}/-</h3>
                  </div>
                  <div className="cs_pricing_feature">
                    <ul className="cs_pricing_feature_list cs_mp0">
                      {item.features.map((el, index) => (
                        <span className="flex py-2">
                          <div>
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <li className="text-gray-900 inline py-1" key={index}>
                            {el.description}
                          </li>
                        </span>
                      ))}
                    </ul>
                    <button
                      to="/registration"
                      className="btn-block cs_pricing_btn cs_up_3_hover"
                    >
                      Register
                    </button>
                  </div>
                </div></Link>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="cs_height_150 cs_height_lg_80"></div>
    </section>
  );
};

export default Pricing;
