import axios from "axios";
import ImageUpload from "components/CustomUpload/ImageUpload";
import NotificationDisplay from "components/Dashboard/NotificationDisplay";
import moment from "moment";
import { Threedotscomp } from "components/Spinner/Threedotscomp";
import React, { useEffect, useState } from "react";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useDispatch, useSelector} from "react-redux";
import { logout } from "redux/actions/auth";
import { CloudHSM } from "aws-sdk";
import { checkplan } from "redux/actions/plan";
const PLANAPI = process.env.REACT_APP_PLAN_API;
const RECHARGE_API = process.env.REACT_APP_RECHARGE_API;

const Profile = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const [imageUrl, setImageUrl] = useState('')
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const initAcharya = {
    name: "",
    email: "",
    contact: "",
    country: "",
    countrylist: "",
    state: "",
    district: "",
    houseno: "",
    pincode: "",
    credit: "",
    password: "",
    eligiblity: "",
  };
  const [stateAcharya, setAcharya] = useState(initAcharya);
  const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
  const notificationAlertRef = React.useRef(null);
  const [userdata, setUserData] = useState({
    name: "",
    email: "",
    contact: "",
    house: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
    about: "",
    image:""
  });

  // const [validity, setValidity] = useState([]);
  const [plans, setPlans] = useState([]);
  const [recharge, setRecharge] = useState([]);
  const [update, setUpdate] = useState({});
  //   const [formData, setFormData] = useState({
  //     name:'',
  //     email:'',
  //     contact:'',
  //     address :'',
  //     city:'',
  //     state:'',
  //     country:'',
  //     pincode:'',
  //     about:''
  // })

  const {
    name,
    email,
    contact,
    house,
    district,
    state,
    country,
    pincode,
    about,
  } = userdata;

  const onChange = (e) => setUserData({ ...userdata, [e.target.name]: e.target.value });
  // console.log("Form Data=======", userdata);

  const handleChange = (e) => {
    setStatus(e.target.value);
  };

  const acharya = localStorage.getItem("user");
  const userDetail = JSON.parse(acharya);
  const uid = userDetail?.id;

  const getUserData = async () => {
    try {
      const userDetail = JSON.parse(acharya);
      // console.log("userDetail===============", userDetail);
      setUserData(userDetail);
    } catch (error) {
      if (error.response.data.messages == "NOT_A_USER") {
        dispatch(logout());
      }
    }
  };

  const Plans = async () => {
    setIsLoading(true);
    // console.log("entered");
    try {
      const res = await axios.get(`${PLANAPI}`);
      // console.log(res, "my plan response");
      setPlans(res.data.data);
      setIsLoading(false);
    } catch (err) {
      // console.log(err, "err");
      setIsLoading(true);
    }
  };

  const Recharges = async () => {
    setIsLoading(true);
    console.log("entered");
    try {
      const res = await axios.get(`${RECHARGE_API}`);
      // console.log(res, "my plan response");
      setRecharge(res.data.data);
      setIsLoading(false);
    } catch (err) {
      // console.log(err, "err");
      setIsLoading(true);
    }
  };

  const currentPlan = useSelector(e => e.plan)
  const validity  = currentPlan;

  // const getSubscription = async () => {
  //   setIsLoading(true);
  //   try {
  //     const userDetail = JSON.parse(acharya);
  //     const acharyaid = userDetail.id;
  //     const body = {
  //       id: acharyaid,
  //     };
  //     // console.log("Acharya Id==============",acharyaid) ;
  //     let result = await axios.post(
  //       `${BAS_URL_APPAPI}acharya/acharyaval`,
  //       body
  //     );
  //     setIsLoading(false);
  //     console.log("ksld;flkfsljfk;fjlds'f", result.data.data.credit);
  //     localStorage.setItem("user", JSON.stringify(result.data.data));
  //     
  //   } catch (error) {
  //     setIsLoading(true);
  //     console.log(error,"errorRRR");
  //     let data = localStorage.getItem("user");
  //     let finalData = JSON.parse(data);
  //     let token = localStorage.getItem("token");

  //     console.log(data, finalData, token,"JJJJJJJJ")
      
  //     if (error.response.data.messages == "NOT_A_USER") {
  //       const obj = {
  //         id: finalData.id,
  //         token: token,
  //       };
  //       // console.log(error, finalData.id, token, obj, "JJJJJJJJ")
  //       try{
  //       const result = await axios.post(`${BAS_URL_APPAPI}acharya/logout`, obj);
  //       }catch(err){
  //         if (error.response.data.messages == "NOT_A_USER") {
  //           dispatch(logout());
  //         }
  //       }
  //     }
  //   }
  // };

  const Updateprofile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const body = {
        id: uid,
        name: userdata.name,
        email: userdata.email,
        contact: userdata.contact,
        address: userdata.house,
        city: userdata.district,
        state: userdata.state,
        country: userdata.country,
        pincode: userdata.pincode,
        about: userdata.about,
        image : userdata.image
      };
      console.log("Acharya Id==============", uid);
      let result = await axios.post(
        `${BAS_URL_APPAPI}acharya/updateprofile`,
        body
      );
      setIsLoading(false);
      if (result) {
        setUpdate(result);
        let notifyMessage = "Profile Updated Successfully";
        let optiondata = {};
        optiondata = {
          place: "tc",
          message: notifyMessage,
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };
        localStorage.setItem("user", JSON.stringify(userdata));
        notificationAlertRef.current.notificationAlert(optiondata);
        setIsLoading(false);
      } else {
        let notifyMessage = "Profile not updated";
        let optiondata = {};
        optiondata = {
          place: "tc",
          message: notifyMessage,
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(optiondata);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    getUserData();
    // getSubscription();
    Plans();
    Recharges();
    // dispatch(checkplan())
  }, []);

  // console.log(validity, recharge, "recharge");

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlertRef} />
        <Row>
          <Col md="8">
            <Card>
              {/* <CardHeader className="d-flex justify-content-between align-items-center">
                <h2 className="title d-flex">Profile</h2>
              </CardHeader> */}
              <div
                style={{ fontSize: "20px", paddingLeft: "15px" }}
                className="py-2"
              >
                <strong>Registration:</strong>{" "}
                {plans?.map((e) => e.id == validity?.reg_plan && e.name)}
                &nbsp; <br></br>
                <strong>Current Plan:</strong>{" "}
                {recharge?.map(
                  (e) =>
                    e.mapped_subscription_plan_id == validity?.subs_plan && (
                      <>{e.name}</>
                    )
                )}
                &nbsp;
                <span>
                  {validity?.plan_status == 0 && <span>{"(Expired)"}</span>}
                </span>
                {/* &nbsp; <br></br>
                <strong>Plan Validity: </strong>{" "}
                {recharge.map(
                  (e) =>
                    e.mapped_subscription_plan_id == validity.subs_plan && (
                      <>{e.validity_without_resubs_days}days</>
                    )
                )} */}
                &nbsp; <br></br>
                {validity?.plan_status == 1 && <>Expiring on: </>}{" "}
                {validity?.plan_status == 1 &&
                  validity?.expiry_date.split(" ")[0]}
              </div>
              <CardBody>
                {/* <Form onSubmit={Updateprofile}> */}
                <Form>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label>Name</label>
                        <Input
                          laceholder="Company"
                          name="name"
                          type="text"
                          value={userdata?.name}
                          readOnly
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label>Email/User Name</label>
                        <Input
                          placeholder="Username"
                          name="email"
                          type="email"
                          value={userdata?.email}
                          readOnly
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Mobile</label>
                        <Input
                          placeholder="Mobile No"
                          type="text"
                          name="contact"
                          value={userdata?.contact}
                          readOnly
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="9">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          placeholder="Home Address"
                          type="text"
                          name="house"
                          value={userdata?.house}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          placeholder="City"
                          type="text"
                          name="district"
                          value={userdata?.district}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label>State</label>
                        <Input
                          placeholder="State"
                          type="text"
                          name="state"
                          value={userdata?.state}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          placeholder="Country"
                          type="text"
                          name="country"
                          value={userdata?.country}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input
                          placeholder="ZIP Code"
                          type="number"
                          name="pincode"
                          value={userdata?.pincode}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          cols="80"
                          placeholder="Here can be your description"
                          name="about"
                          rows="4"
                          type="textarea"
                          value={userdata?.about}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Button className="btn-fill" color="primary" type="submit">
                        Update
                      </Button> */}
                  <Row>
                    <Col>
                      <Button
                        className="btn-fill"
                        color="primary"
                        type="submit"
                        onClick={Updateprofile}
                      >
                        {" "}
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              {/* <CardFooter>
                    
                  </CardFooter> */}
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="avatar"
                          src={require("assets/img/logo.png")}
                        />
                        
                      </a> */}
                  <ImageUpload src={userdata.image} imageUrl={(val) => setUserData(prev => ({
                    ...prev, image : val
                  }))} />
                  <h5 className="title">{userdata?.name}</h5>
                  {/* <p className="description">Ceo/Co-Founder</p> */}
                </div>
                <div className="card-description">{userdata?.about}</div>
              </CardBody>
              {/* <CardFooter>
                <div className="button-container">
                  <a
                    href="https://www.facebook.com/gdvashistOfficial"
                    target="blank"
                  >
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fab fa-facebook" />
                    </Button>
                  </a>
                  <a href="https://twitter.com/gdvashist_real" target="blank">
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                  </a>
                  <a
                    href="https://www.youtube.com/@GurudevGDVashist"
                    target="blank"
                  >
                    <Button
                      href=""
                      className="btn-icon btn-round"
                      color="youtube"
                    >
                      <i className="fab fa-youtube" />
                    </Button>
                  </a>
                </div>
              </CardFooter> */}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Profile;
