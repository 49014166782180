import React from "react";
import { useSelector } from "react-redux";
// const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

export const PlainExpired = () => {
  const plan_status = useSelector(e => e.plan.plan_status)
  // const updateExp = useSelector(e => e)

  // console.log(updateExp.updateExpireReducer.data,"updateExpire in")

//  console.log(getPlan.plan_status ,"getplan")

  // const getSubscription = async () => {
  //   try {
  //     let acharya = localStorage.getItem("user");
  //     const userDetail = JSON.parse(acharya);
  //     const acharyaid = userDetail.id;
  //     const body = {
  //       id: acharyaid,
  //     };
  //     // //console.log("Acharya Id==============",acharyaid) ;
  //     let result = await axios.post(
  //       `${BAS_URL_APPAPI}acharya/acharyaval`,
  //       body
  //     );
  //     setPlanStatus(result.data.data.plan_status);
  //     if (result.data.data.plan_status === 0) {
  //     }
  //   } catch (error) {
  //     //console.log(error);
  //   } 
  // };

  // useEffect(() => {
  //   getSubscription();
  // }, [updateExp.updateExpireReducer.data]);
  

  

  return (
    <>
      {" "}
      {plan_status == 0 && (
        <span className="mb-0 d-none d-xl-block d-sm-block">
          <b class="blink" style={{ fontSize: "16px", color: "red" }}>
            Plan is expired, please renew!!
          </b>
        </span>
      )}
    </>
  );
};
