import React, { useEffect } from "react";
import { useState } from "react";
import { Card, Row } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, CardBody, Col } from "reactstrap";
// import ReactTables from 'views/tables/ReactTables';
import ReactTable from "components/ReactTable/ReactTable.js";
import moment from "moment/moment";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import { useDispatch} from "react-redux";
import { logout } from "redux/actions/auth";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const Support = () => {
  const dispatch = useDispatch();
  const [support, getSupportData] = useState([]);
  const notificationAlertRef = React.useRef(null);
  const token = localStorage.getItem("token");
  const acharya = localStorage.getItem("user");
  const history = useHistory();
  const id = useParams();
  const getfreekundaliData = async () => {
    try {
      const userDetail = JSON.parse(acharya);
      const id = userDetail.id;
      const sugestion = await axios.get(
        `${BAS_URL_APPAPI}acharya/sugestion/${id}`,
        {
          headers: { Authorization: token },
          //body:{id :id}
        }
      );
      if (sugestion) {
        getSupportData(sugestion.data.data);
      } else {
        let notifyMessage = "No Data Found";
        let optiondata = {};
        optiondata = {
          place: "tc",
          message: notifyMessage,
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(optiondata);
      }
    } catch (error) {
      if (error.response.data.messages == "NOT_A_USER") {
        dispatch(logout());
      }
    }
  };

  // const getSubscription = async () => {
  //   try {
  //     let acharya = localStorage.getItem("user");
  //     const userDetail = JSON.parse(acharya);
  //     const acharyaid = userDetail.id;
  //     const body = {
  //       id: acharyaid,
  //     };
  //     // //console.log("Acharya Id==============",acharyaid) ;
  //     let result = await axios.post(
  //       `${BAS_URL_APPAPI}acharya/acharyaval`,
  //       body
  //     );
  //   } catch (error) {
  //     let data = localStorage.getItem("user");
  //     let finalData = JSON.parse(data);
  //     let token = localStorage.getItem("token");
  //     if (error.response.data.messages == "NOT_A_USER") {
  //       const obj = {
  //         id: finalData.id,
  //         token: token,
  //       };
  //       // console.log(error, finalData.id, token, obj, "JJJJJJJJ")
  //       try{
  //       const result = await axios.post(`${BAS_URL_APPAPI}acharya/logout`, obj);
  //       }catch(err){
  //         if (error.response.data.messages == "NOT_A_USER") {
  //           dispatch(logout());
  //         }
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    // getSubscription();
    getfreekundaliData();
  }, []);
  
  const [data, setData] = React.useState(
    support?.map((prop, key) => {
      return {
        id: key,
        credits: prop[0],
        amount: prop[1],
        transaction_id: prop[2],
        mode: prop[3],
        status: prop[4],
        date: prop[5],
        actions: <div></div>,
      };
    })
  );

  return (
    <div className="content">
      <NotificationAlert ref={notificationAlertRef} />
      <Row>
        <Col xs={12} md={12}>
          <Card>
            {/* <CardHeader>
                <CardTitle tag="h4">React Table</CardTitle>
              </CardHeader> */}
            <CardBody>
              <div>
                Welcome to the Support page of Acharya Panel. <br />
                <span style={{ fontWeight: "bold" }}>
                  Contact Us :- 9821397676 (Monday to Sunday, 9AM to 7PM)
                </span>{" "}
                <br />
                <span style={{ fontWeight: "bold" }}>
                  Email us :- care@astroscience.in
                </span>
                <br />
              </div>
              <div>
                <Link
                  className="btn btn-outline-light submitbtn"
                  to="/acharya/addnewquery"
                >
                  Add New Query
                </Link>{" "}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Card>
            {/* <CardHeader>
                <CardTitle tag="h4">React Table</CardTitle>
              </CardHeader> */}
            <CardBody>
              <ReactTable
                data={support ? support : []}
                //filterable
                resizable={false}
                columns={[
                  {
                    Header: "Id",
                    accessor: (row, index) => index + 1,
                    maxWidth: 400,
                    minWidth: 140,
                    width: 100,
                  },
                  {
                    Header: "Issue",
                    accessor: "issue",
                  },
                  {
                    Header: "Message",
                    accessor: "message",
                  },
                  {
                    Header: "Response",
                    accessor: (row) =>
                      row.reply ? row.reply : <div>No Response</div>,
                    //accessor: "reply"
                  },
                  {
                    Header: "Date",
                    accessor: (row) => (
                      <div>
                        {moment(row.date).utc().format("DD/MM/YY")}{" "}
                        <div>{moment(row.date).utc().format(" HH:mm")}</div>
                      </div>
                    ),
                  },
                  {
                    Header: "Reply Date",
                    accessor: (row) =>
                      row.reply_date ? (
                        <div>
                          {moment(row.reply_date)
                            .utc()
                            .format("DD-MM-YYYY HH:mm A")}
                        </div>
                      ) : (
                        <div>No Reply</div>
                      ),
                    // accessor: row => <div>{moment(row.reply_date).format("DD-MM-YYYY HH:ss A")}</div>,
                  },
                  // {
                  //   Header: "Date",
                  //   accessor: row => <div>{moment(row.date).format("DD-MM-YYYY HH:ss A")}</div>,
                  // },
                  {
                    Header: "Actions",
                    accessor: (d) => (
                      <div>
                        <Button
                          key={d.id}
                          onClick={() =>
                            history.push("/acharya/view-reply/" + d.id)
                          }
                          color={"primary"}
                          style={{ padding: "5px 10px" }}
                        >
                          <i className="fa fa-eye"></i>
                        </Button>{" "}
                      </div>
                    ),
                  },
                ]}
                defaultPageSize={10}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Support;
