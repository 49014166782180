import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Row } from "reactstrap";
import Header from "components/Header";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import "./registration.css";
import Logo from "../../assets/img/logo.png";
import { registration } from "../../redux/actions/auth";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Threedotscomp } from "components/Spinner/Threedotscomp";
import RechargeReg from "views/pages/RechargeReg";
import Footer from "components/Landing/Footer";
import { logout } from "../../redux/actions/auth";
const r_key_id = process.env.REACT_APP_CHECK_R_KEY_ID;
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

function Registration() {
  const history = useHistory();
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isReregistraion, setIsReregistraion] = useState(false);
  const [showMobileWarning, setShowMobileWarning] = useState(false);
  let [email, setEmail] = useState();
  let [order_no, setOrderNo] = useState("");
  const [allrecharge, setRechargevalu] = useState([]);
  const [formData, setFormData] = useState({
    amount: 1000,
    country: "NA",
    house: "NA",
    country: "NA",
    district: "NA",
    state: "NA",
  });
  const [errors, setErrors] = useState({});

  const user = localStorage.getItem("user");
  const userDetail = JSON.parse(user);
  let acharyaname = "";

  useEffect(() => {
    if (userDetail) {
      acharyaname = userDetail["name"];
      history.push("/login");
    }
  }, []);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const notificationAlertRef = React.useRef(null);

  const findFormErrors = () => {
    //  console.log("ABCD",pincodeData.data.data.length)
    const {
      name,
      email,
      contact,
      country,
      state,
      pincode,
      house,
      district,
      password,
    } = formData;
    const newErrors = {};
    // name errors
    if (!name || name === "") newErrors.name = "Name cannot be blank!";
    else if (name.length > 30) newErrors.name = "name is too long!";
    // food errors
    if (!email || email === "") newErrors.email = "Email cannot be blank!";
    else if (!validateEmail(email) || email.length > 50)
      newErrors.email = "Enter a valid email!";
    if (
      !contact ||
      contact === "" ||
      contact.length > 10 ||
      contact.length <= 6
    )
      newErrors.contact = "Invalid Number";
    if (!country || country === "")
      newErrors.country = "Country cannot be blank!";
    if (
      !(!country || country === "") &&
      (country.length > 50 || country.length < 2)
    )
      newErrors.country =
        "Country name length should be between 2 to 50 letters!";

    if (!state || state === "") newErrors.state = "State cannot be blank!";
    if (!(!state || state === "") && (state.length > 50 || state.length < 2)) {
      newErrors.state = "State name length should be between 2 to 50 letters!";
    }
    if (!pincode || pincode === "" || pincode.length != 6)
      newErrors.pincode = "Pincode is not valid!";
    if (!house || house === "") newErrors.house = "House cannot be blank!";
    if (!(!house || house === "") && house.length > 100)
      newErrors.house = "House No. too long!";
    if (!district || district === "")
      newErrors.district = "District cannot be blank!";
    if (
      !(!district || district === "") &&
      (district.length > 50 || district.length < 2)
    ) {
      newErrors.district =
        "District name length should be between 2 to 50 letters!";
    }
    if (!password || password === "")
      newErrors.password = "Password cannot be blank!";
    else if (!validatePassword(password))
      newErrors.password =
        "Password should have 8-32 characters, 1 alphabet & 1 special character. First character should not be special character.";

    return newErrors;
  };

  const findFormErrorsRe = () => {
    //  console.log("ABCD",pincodeData.data.data.length)
    const { name, email } = formData;
    const newErrors = {};
    // name errors
    if (!name || name === "") newErrors.name = "Name cannot be blank!";
    else if (name.length > 30) newErrors.name = "name is too long!";
    // food errors
    if (!email || email === "") newErrors.email = "Email cannot be blank!";
    else if (!validateEmail(email) || email.length > 50)
      newErrors.email = "Enter a valid email!";

    return newErrors;
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);

  const initPayment = async (data) => {
    let notifyMessage = "Acharya Has been Created.";
    const options = {
      key_id: r_key_id,
      amount: 100 * 1000,
      currency: data.currency,
      order_id: data.id,
      modal: {
        ondismiss: function () {
          setIsLoading(false);
        },
      },
      handler: async (response) => {
        console.log("Handler", response);
        const dataObj = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,

          email: formData.email,
          price: 1000,
          credit: 300,
          usertype: "acharya",
          pass: formData.password,
          //insertedId:inserttopup.iinsertId
        };
        //console.log("")
        console.log("dataObjdataObjdataObjdataObj === before", dataObj);
        const { data } = await axios.post(
          `${BAS_URL_APPAPI}acharya/verify_payment`,
          dataObj
        );
        console.log("dataObjdataObjdataObjdataObj === after", data);
        if (data.message === "Acharya has been created") {
          // alert("Acharya Has been Created")
          let options = {};
          options = {
            place: "tc",
            message: notifyMessage,
            type: "primary",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
          };
          setOrderNo(data.crm_orderid);
          setEmail(data.email);
          setIsLoading(false);
          openModal();

          notificationAlertRef.current.notificationAlert(options);
        } else {
          let options = {};
          options = {
            place: "tc",
            message: notifyMessage,
            type: "primary",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
          };
          notificationAlertRef.current.notificationAlert(options);
          alert("Something Went Wrong");
          setIsLoading(false);
        }
        console.log("Data =============", data);
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const initPaymentRe = async (data) => {
    let notifyMessage = "Acharya Has been Created.";
    const options = {
      key_id: r_key_id,
      amount: 100 * 1000,
      currency: data.currency,
      order_id: data.id,
      modal: {
        ondismiss: function () {
          setIsLoading(false);
        },
      },
      handler: async (response) => {
        console.log("Handler", response);
        const dataObj = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,

          email: formData.email,
          price: 1000,
          credit: 300,
          usertype: "acharya",
          // pass: formData.password,
          //insertedId:inserttopup.iinsertId
        };
        //console.log("")
        console.log("dataObjdataObjdataObjdataObj === before", dataObj);
        const { data } = await axios.post(
          `${BAS_URL_APPAPI}payment/re_verify_payment`,
          dataObj
        );
        console.log("dataObjdataObjdataObjdataObj === after", data);
        if (data.message === "Acharya has been created") {
          // alert("Acharya Has been Created")
          let options = {};
          options = {
            place: "tc",
            message: notifyMessage,
            type: "primary",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
          };
          setOrderNo(data.crm_orderid);
          setEmail(data.email);
          setIsLoading(false);
          openModal();

          notificationAlertRef.current.notificationAlert(options);
        } else {
          let options = {};
          options = {
            place: "tc",
            message: notifyMessage,
            type: "primary",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
          };
          notificationAlertRef.current.notificationAlert(options);
          alert("Something Went Wrong");
          setIsLoading(false);
        }
        console.log("Data =============", data);
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const paymentHandler = async (e) => {
    try {
      setIsLoading(true);
      setErrors({});
      e.preventDefault();
      const newErrors = findFormErrors();
      console.log("NEW Errors", newErrors, Object.keys(newErrors).length);
      if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setIsLoading(false);
        setErrors(newErrors);

        return;
      }
      let pincodeData = await axios.get(
        `${BAS_URL_APPAPI}admin/pincodes/${pincode}`
      );
      if (pincodeData.data.data.length === 0) {
        newErrors.pincode = "Pincode is not valid!";
        setIsLoading(false);
        setErrors(newErrors);

        return;
      }
      formData.district = "NA";
      formData.country = "NA";
      formData.state = "NA";
      formData["plan"] = getplan.id;
      formData.amount = getplan.price;

      let response = await axios.post(
        `${BAS_URL_APPAPI}acharya/registration`,
        formData
      );
      let registration = response.data;
      // console.log(registration);

      if (
        response.data.success === 0 &&
        response.data.message === "USER_ALREADY_EXISTS"
      ) {
        let options = {};
        options = {
          place: "tc",
          message: "User aleady exist!",
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };

        notificationAlertRef.current.notificationAlert(options);
        setIsLoading(false);
      } else if (
        response.data.success === 0 &&
        response.data.message === "REG_EXPIRIED"
      ) {
        formData.name = response.data.userData.name;
        formData.email = response.data.userData.email;
        formData.pincode = JSON.parse(response.data.userData.pincode);
        formData["plan"] = getplan.id;
        formData.amount = getplan.price;

        let options = {};
        options = {
          place: "tc",
          message: "Your Registration is Expired!",
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };

        notificationAlertRef.current.notificationAlert(options);
        setIsLoading(false);
        setShowError(true);
      } else {
        initPayment(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Payment handler not working", error);
    }
  };

  const paymentHandlerRe = async (e) => {
    try {
      setIsLoading(true);
      setErrors({});
      e.preventDefault();
      const newErrors = findFormErrorsRe();
      console.log("NEW Errors", newErrors, Object.keys(newErrors).length);
      if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setIsLoading(false);
        setErrors(newErrors);

        return;
      }
      // let pincodeData = await axios.get(
      //   `${BAS_URL_APPAPI}admin/pincodes/${pincode}`
      // );
      // if (pincodeData.data.data.length === 0) {
      //   newErrors.pincode = "Pincode is not valid!";
      //   setIsLoading(false);
      //   setErrors(newErrors);

      //   return;
      // }
      // formData.district = "NA";
      // formData.country = "NA";
      // formData.state = "NA";

      const obj = {
        plan: getplan.id,
        amount: getplan.price,
        email: formData.email,
        name: formData.name,
      };

      let response = await axios.post(
        `${BAS_URL_APPAPI}payment/re_registration`,
        obj
      );
      let registration = response.data;
      // console.log(registration);

      if (
        response.data.success === 0 &&
        response.data.message === "USER_ALREADY_EXISTS"
      ) {
        let options = {};
        options = {
          place: "tc",
          message: "User aleady exist!",
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };

        notificationAlertRef.current.notificationAlert(options);
        setIsLoading(false);
      } else if (
        response.data.success === 0 &&
        response.data.message === "REG_EXPIRIED"
      ) {
        formData.name = response.data.userData.name;
        formData.email = response.data.userData.email;
        // formData.pincode = JSON.parse(response.data.userData.pincode);
        formData["plan"] = getplan.id;
        formData.amount = getplan.price;

        let options = {};
        options = {
          place: "tc",
          message: "Your Registration is Expired!",
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };

        notificationAlertRef.current.notificationAlert(options);
        setIsLoading(false);
        setIsReregistraion(true);
      } else {
        initPaymentRe(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Payment handler not working", error);
    }
  };

  const getEmail = async () => {
    let userDetails = JSON.parse(localStorage.getItem("user"));
    // console.log("User Details====", userDetails);
    let email = userDetails?.email;
    // console.log("Email=====", email);
    setEmail(email);
  };

  useEffect(() => {
    getEmail();
    if (window.innerWidth <= 800) setShowMobileWarning(true);
    if (window.scroll) {
      window.scroll({
        top: 1,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  const dispatch = useDispatch();
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePassword = (password) => {
    const pattern =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
    return pattern.test(password);
  };
  const { name, contact, country, state, pincode, house, district, password } =
    formData;
  if (!formData) {
    alert("all fields are mendatory");
  }
  const onChange = (e) => {
    delete errors[e.target.name];
    setErrors(errors);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeWithoutSpecialAndNumber = (e) => {
    delete errors[e.target.name];
    let newValue = e.target.value;
    const filteredValue = newValue.replace(/[^A-Za-z\s]/g, "");

    setErrors(errors);
    setFormData({ ...formData, [e.target.name]: filteredValue });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      registration({
        name,
        email,
        contact,
        country,
        state,
        pincode,
        house,
        district,
        password,
      })
    );
    let check = true;
  };

  //Redirect if logged in

  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlan] = useState(true);
  const [getplan, setGetPlan] = useState({
    name: "Select plan",
  });
  const [select, setSelect] = useState("");

  const handlePlan = (e) => {
    e.preventDefault();
    setPlan(true);
    setSelect(getplan.name);
  };

  // console.log(location.state, "my location");

  useEffect(() => {
    if (location.state) {
      formData.name = location.state.name;
      formData.email = location.state.email;
      // formData.pincode = JSON.parse(location.state.pincode);
      formData["plan"] = getplan.id;
      // formData.amount = getplan.price;
      setIsReregistraion(true);
    }
  }, [getplan]);

  // useEffect( async () => {
  //   // console.log(formData.name && formData.email.includes(".com"),"jnjnj")
  const HandleReregNav = () => {
    setIsReregistraion(true);
  };


  const HandleRereg = () => {
    setIsReregistraion(false);
  };

  return (
    <>
      {plans
        ? // <Header text={"Registration Plan Selection"} />
          ""
        : // <Header text={""} />
          ""}
      <div className="reg_container z-1 cs_content">
        {isLoading ? (
          <div className="z-[3]">
            <Threedotscomp />
          </div>
        ) : null}
        <NotificationAlert ref={notificationAlertRef}></NotificationAlert>
        {plans ? (
          <>
            <RechargeReg
              paymentHandler={setGetPlan}
              state={setPlan}
              selected={select}
            />
          </>
        ) : (
          <>
            <div className="container py-4 bg-white z-2 justify-center flex w-full">
              <div className="row flex w-full py-32">
                <div className="col-lg-4 col-xl-4 col-md-12 discount-img h-fit">
                  <div className="item-center form-register justify-center lg:flex max-md:hidden sm:hidden">
                    <div
                      className="flex-shrink-0 m-6 relative rounded-lg max-w-xs shadow-lg py-0 hover:scale-105 cursor-pointer"
                      role="button"
                    >
                      {getplan.mapped_subscription_plan_id == 4 && (
                        <div
                          style={{
                            position: "absolute",
                            background: "transparent",
                            height: "140px",
                            width: "150px",
                            left: "-10px",
                            top: "-10px",
                            overflow: "hidden",
                            zIndex: "2",
                          }}
                          className=""
                        >
                          <div
                            style={{
                              position: "absolute",
                              background: "#344675",
                              height: "30px",
                              width: "20px",
                              left: "-10px",
                              bottom: "0",
                              overflow: "hidden",
                              zIndex: "1",
                            }}
                            className="bg-gradient-to-r from-slate-700 to-slate-900"
                          ></div>
                          <div
                            style={{
                              position: "absolute",
                              background: "#344675",
                              height: "10px",
                              width: "30px",
                              right: "32px",
                              top: "0",
                              overflow: "hidden",
                              zIndex: "1",
                            }}
                            className="bg-gradient-to-r from-slate-900 to-slate-700"
                          ></div>
                          <div
                            style={{
                              position: "inherit",
                              height: "30px",
                              width: "200%",
                              left: "-100px",
                              rotate: "310Deg",
                              top: "42px",
                              zIndex: "1",
                              color: "#ffffff",
                              textAlign: "center",
                              justifyItems: "center",
                              justifyItems: "center",
                              lineHeight: "2.2",
                              boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 5px",
                            }}
                            className="bg-gradient-to-r from-red-500 to-orange-500"
                          >
                            Top Choice
                          </div>
                        </div>
                      )}
                      <svg
                        className="absolute bottom-0 left-0 mb-8"
                        viewBox="0 0 375 283"
                        fill="none"
                        style={{ transform: "scale(1.5)", opacity: "0.1" }}
                      >
                        <rect
                          x="159.52"
                          y="175"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 159.52 175)"
                          fill="white"
                        />
                        <rect
                          y="107.48"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 0 107.48)"
                          fill="white"
                        />
                      </svg>
                      <div className="m-0 border bg-gradient-to-r from-violet-200 to-pink-100 rounded-lg bg-pink">
                        <div className="relative text-white pt-3 pb-6 mt-6 justify-between align-center">
                          <div className="flex justify-center item-center mb-0 py-3">
                            <span className="block font-semibold text-xl">
                              <h2 className="text-center text-dark">
                                {getplan.name}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="relative px-10 flex items-start justify-start flex-col h-60">
                          {getplan.features.map((el, index) => (
                            <span
                              style={{
                                display: "flex",
                                gap: 3,
                                color: "#",
                                padding: "5px 0",
                              }}
                              className="rounded-full"
                            >
                              <i
                                key={index}
                                className="fa fa-check-circle success"
                                aria-hidden="true"
                                style={{ marginTop: "6px" }}
                              ></i>
                              &nbsp;
                              <li
                                style={{
                                  listStyle: "",
                                  color: "#000",
                                  listStyleType: "none",
                                }}
                              >
                                {" "}
                                <p
                                  style={{
                                    color: "#000",
                                    lineHeight: "25px",
                                  }}
                                >
                                  {" "}
                                  {el.description}
                                </p>
                              </li>
                            </span>
                          ))}
                        </div>
                        <div className="relative text-white px-6 pb-6 mt-6 justify-between align-center">
                          <div className="flex justify-center pb-2">
                            <span className="block bg-white rounded-full text-orange-500 text-lg font-bold px-3 py-2 leading-none items-center">
                              ₹{getplan.price}/-
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isReregistraion ? (
                  <div className="col-lg-8 col-xl-8 col-md-12 z-[1] h-fit self-center w-full max-md:px-0">
                    <div className="h-auto pt-4 pb-5 shadow-lg shadow-indigo-500/40 rounded-lg lg:w-11/12 md:w-full mx-0 px-10 lg:px-16 xl:px-12 m-auto">
                      <div className="flex w-100 justify-center align-center flex-column pb-4">
                        <div className="position-center flex justify-center items-center">
                          <Link></Link>
                          <img
                            src={Logo}
                            alt=""
                            className="object-contain xl:w-25"
                          />
                        </div>
                        <h1 className="text-xl md:text-2xl font-semibold leading-tight text-gray-600 text-center mt-2">
                          <div className="my-0">GDV PANEL REGISTRATION</div>
                        </h1>
                      </div>
                      <form
                        className="mt-0"
                        action="#"
                        method="POST"
                        onSubmit={(e) => onSubmit(e)}
                        id="reset"
                      >
                        <div className="flex lg:flex-row max-md:flex-col md:space-x-2 max-md:space-x-0">
                          <div className="mb-2 lg:w-1/2 max-lg:w-full">
                            <label classnName="block text-gray-700">Name</label>
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              placeholder="Enter name"
                              onChange={(e) =>
                                onChangeWithoutSpecialAndNumber(e)
                              }
                              required
                              className="w-full px-4 py-2 rounded-lg bg-gray-200 border focus:border-blue-500 focus:bg-white focus:outline-none"
                            />
                            <small className="text-red-500 text-xs">
                              {errors.name}
                            </small>
                          </div>
                          <div className="mb-2 lg:w-1/2 max-lg:w-full">
                            <label className="block text-gray-700">Email</label>
                            <input
                              type="email"
                              name="email"
                              placeholder="Enter email"
                              value={formData.email}
                              onChange={(e) => onChange(e)}
                              required
                              className="w-full px-4 py-2 rounded-lg bg-gray-200 border focus:border-blue-500 focus:bg-white focus:outline-none"
                            />
                            <small className="text-red-500 text-xs">
                              {errors.email}
                            </small>
                          </div>
                        </div>
                        <div className="flex lg:flex-row max-md:flex-col  md:space-x-2 max-md:space-x-0">
                          <div className="mb-2 lg:w-1/2 max-lg:w-full">
                            <label classnAM="block text-gray-700">
                              Phone number
                            </label>
                            <input
                              type="number"
                              name="contact"
                              value={formData.contact}
                              placeholder="Enter phone No."
                              onChange={(e) => onChange(e)}
                              required
                              style={{
                                border: !!errors.contact ? "1px solid red" : "",
                              }}
                              className="w-full px-4 py-2 rounded-lg bg-gray-200 focus:border-blue-500 focus:bg-white focus:outline-none border"
                            />
                            <small className="text-red-500 text-xs">
                              {errors.contact}
                            </small>
                          </div>

                          <div className="mb-2 lg:w-1/2 max-lg:w-full position-relative">
                            <label className="block text-gray-700">
                              Pincode
                            </label>
                            <input
                              maxLength={6}
                              minLength={6}
                              name="pincode"
                              type="number"
                              value={formData.pincode}
                              placeholder="Enter 6 Digit pincode"
                              onChange={(e) => onChange(e)}
                              isInvalid={!!errors.pincode}
                              required
                              style={{
                                border: !!errors.pincode ? "1px solid red" : "",
                              }}
                              className="w-full px-4 py-2 rounded-lg bg-gray-200 mt-0 border focus:border-blue-500 focus:bg-white focus:outline-none"
                            />
                            <small className="text-red-500 text-xs">
                              {errors.pincode}
                            </small>
                          </div>
                        </div>

                        <div className="flex lg:flex-row max-md:flex-col md:space-x-2 max-md:space-x-0">
                          <div className="mb-2 lg:w-1/2 max-lg:w-full position-relative">
                            <div
                              style={{
                                position: "absolute",
                                right: 10,
                                top: 28,
                                bottom: 0,
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                padding: "10px",
                              }}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                            <label classnAM="block text-gray-700">
                              Password
                            </label>
                            <input
                              autoComplete="new-password"
                              value={formData.password}
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeholder="Enter Password"
                              onChange={(e) => onChange(e)}
                              required
                              style={{
                                border: !!errors.house ? "1px solid red" : "",
                              }}
                              className="w-full px-4 py-2 rounded-lg bg-gray-200 focus:border-blue-500 focus:bg-white focus:outline-none border"
                            />
                            <small className="text-red-500 text-xs">
                              {errors.password}
                            </small>
                          </div>

                          <div className="mb-2 lg:w-1/2 max-lg:w-full">
                            <label className="block text-gray-700">Plans</label>
                            <button
                              type="button"
                              as="select"
                              value={getplan.name}
                              onClick={(e) => handlePlan(e)}
                              required
                              className="w-full px-4 py-2 rounded-lg bg-gray-200 border focus:border-blue-500 focus:bg-white focus:outline-none"
                            >
                              <span className="flex justify-between">
                                {getplan.name}
                                <i className="fa fa-angle-down leading-[20px]"></i>
                              </span>
                            </button>
                            {/* <small className="text-red-500 text-xs">
                            {errors.name}
                          </small> */}
                          </div>
                        </div>
                        <div className="text-center mt-1 mb-2">
                          <small class="text-[10px] text-dark-300">
                            *By using this website, you agree with the Terms and
                            Conditions.
                          </small>
                        </div>
                        <div className="xl:w-full md:w-full mb-1 md:mb-0 z-10 max-md:px-0 max-md:w-full flex justify-center">
                          <button
                            type="submit"
                            onClick={paymentHandler}
                            className="btn-block bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
                            py-2 mt-2"
                          >
                            Register
                          </button>
                        </div>
                        {showError && (
                          <Row className="justify-content-center">
                            <div
                              style={{ color: "red" }}
                              className="text-center"
                            >
                              <small> Your Account Has Expired!</small>
                              <small>
                                {" "}
                                Please Re-registeration again.{" "}
                                <Link
                                  type="button"
                                  class="text-blue-500 hover:text-blue-700 font-semibold"
                                  onClick={(e) => HandleReregNav(e)}
                                >
                                  Click Here!
                                </Link>
                              </small>
                            </div>
                          </Row>
                        )}
                        <div className="mt-2 text-center w-full">
                          <Link
                            to="/Login"
                            className="text-blue-500 hover:text-blue-700 font-semibold mt-0"
                          >
                            Back to Login
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-8 col-xl-8 col-md-12 z-[1] h-fit self-center w-full max-md:px-0">
                    <div className="h-auto pt-4 pb-5 shadow-lg shadow-indigo-500/40 rounded-lg lg:w-11/12 md:w-full mx-0 px-10 lg:px-16 xl:px-12 m-auto">
                      <div className="flex w-100 justify-center align-center flex-column pb-4">
                        <div className="position-center flex justify-center items-center">
                          <img
                            src={Logo}
                            alt=""
                            className="object-contain xl:w-25"
                          />
                        </div>
                        <h1 className="text-xl md:text-2xl font-semibold leading-tight text-gray-600 text-center mt-2">
                          <div className="my-0">GDV PANEL RE-REGISTRATION</div>
                        </h1>
                        <div className="text-center text-red-600">
                          <small>
                            {" "}
                            Your Account Has Expired! Please Re-registeration
                            again.
                          </small>
                        </div>
                      </div>
                      <form
                        className="mt-0"
                        action="#"
                        method="POST"
                        onSubmit={(e) => onSubmit(e)}
                        id="reset"
                      >
                        <div className="flex lg:flex-row max-md:flex-col md:space-x-2 max-md:space-x-0">
                          <div className="mb-2 lg:w-1/2 max-lg:w-full">
                            <label classnName="block text-gray-700">Name</label>
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              placeholder="Enter name"
                              onChange={(e) =>
                                onChangeWithoutSpecialAndNumber(e)
                              }
                              required
                              className="w-full px-4 py-2 rounded-lg bg-gray-200 border focus:border-blue-500 focus:bg-white focus:outline-none"
                            />
                            <small className="text-red-500 text-xs">
                              {errors.name}
                            </small>
                          </div>
                          <div className="mb-2 lg:w-1/2 max-lg:w-full">
                            <label className="block text-gray-700">Email</label>
                            <input
                              type="email"
                              name="email"
                              placeholder="Enter email"
                              value={formData.email}
                              onChange={(e) => onChange(e)}
                              required
                              className="w-full px-4 py-2 rounded-lg bg-gray-200 border focus:border-blue-500 focus:bg-white focus:outline-none"
                            />
                            <small className="text-red-500 text-xs">
                              {errors.email}
                            </small>
                          </div>
                        </div>

                        <div className="flex lg:flex-row max-md:flex-col md:space-x-2 max-md:space-x-0">
                          <div className="mb-2 lg:w-1/2 max-lg:w-full">
                            <label className="block text-gray-700">Plans</label>
                            <button
                              type="button"
                              as="select"
                              value={getplan.name}
                              onClick={(e) => handlePlan(e)}
                              required
                              className="w-full px-4 py-2 rounded-lg bg-gray-200 border focus:border-blue-500 focus:bg-white focus:outline-none"
                            >
                              <span className="flex justify-between">
                                {getplan.name}
                                <i className="fa fa-angle-down leading-[20px]"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className="text-center mt-1 mb-2">
                          <small class="text-[10px] text-dark-300">
                            *By using this website, you agree with the Terms and
                            Conditions.
                          </small>
                        </div>
                        <div className="xl:w-full md:w-full mb-1 md:mb-0 z-10 max-md:px-0 max-md:w-full flex justify-center">
                          {/* {!isReregistraion ? (
                          <button
                            type="submit"
                            onClick={paymentHandler}
                            className="btn-block bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
                            py-2 mt-2"
                          >
                            Register
                          </button>
                        ) : ( */}
                          <button
                            type="submit"
                            onClick={paymentHandlerRe}
                            className="btn-block bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
                            py-2 mt-2"
                          >
                            Re-register
                          </button>

                          {/* )} */}
                        </div>
                        <div className="mt-2 text-center w-full flex justify-center">
                          <Row className="justify-content-center">
                            <Link
                              to="/Login"
                              className="text-blue-500 hover:text-blue-700 font-semibold mt-0"
                            >
                              Back to Login
                            </Link>
                          </Row>
                          <div className="px-4">|</div>
                          <Row className="justify-content-center">
                            <a
                              role="button"
                              onClick={HandleRereg}
                              class="text-blue-500 hover:text-blue-700 font-semibold"
                            >
                              Register
                            </a>
                          </Row>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {/* <Alerts /> */}
        <div className="formmodal-card">
          <Modal
            className="modal-data"
            size="md"
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
          >
            <ModalHeader
              style={{ background: "#2022243d", padding: "5px 10px" }}
            >
              <span>
                <h4 className="mb-0">Registeration Completed!!!</h4>
              </span>
            </ModalHeader>
            {/* <button onClick={closeModal} className="lkaclose">X</button></span> */}
            <ModalBody className="p-0 text-center">
              Hi {name}! <br /> Welcome to GDV PANEL
              <br />
              You can login with this {email}.<br /> Note Your Order no.{" "}
              {order_no} <br /> Enjoy your horoscopic journey!
              <br />
              <Button
                onClick={(e) => {
                  history.push("/login");
                }}
              >
                {" "}
                back to Login
              </Button>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Registration;
