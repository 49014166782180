export const SWITCH_THEME = "SWITCH_THEME"
export const SWITCH_COLOR = "SWITCH_COLOR"
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGOUT = 'LOGOUT'
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const GET_REMEDY = 'GET_REMEDY'
export const DELETE_REMEDY = 'DELETE_REMEDY'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const HOROSCOPE_POST = 'HOROSCOPE_POST'
export const QUESTION_POST = 'QUESTION_POST'
export const PREDICTION_POST = 'PREDICTION_POST'
export const GET_MAHADASHA = 'GET_MAHADASHA'
export const SELECTED_PRODUCT ='SELECTED_PRODUCT'
export const SELECTED_LANGUAGE = 'SELECTED_LANGUAGE'
export const RESET_STORE = 'RESET_STORE'
export const CHECK_PLAN = 'CHECK_PLAN'
export const UPDATE_EXPIRED = 'UPDATE_EXPIRED'
