import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AuthLayout from "layouts/Auth/Auth.js";
import AcharyaLayout from "layouts/Acharya/Acharya.js";
import Login from "components/auth/Login";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";
import "../src/assets/css/custom.css";
import axios from "axios";
import Registration from "components/Registration/registration";
import AboutUs from "views/pages/AboutUs";
import PrivacyPolicy from "views/pages/PrivacyPolicy";
import TermsConditions from "views/pages/TermsConditions";
import ContactUs from "views/pages/ContactUs";
import ShippingAndDelivery from "views/pages/ShippingAndDelivery";
import ProductAndServices from "views/pages/ProductAndServices";
import ReturnRefunds from "views/pages/ReturnRefunds";
import './index.css'
import Modal from "components/Dashboard/Modal";
import { useEffect } from "react";
import Crm from "views/pages/Crm";
import LoginCrm from "components/auth/LoginCrm";
import RegistrationCrm from "components/Registration/registrationCrm";
import TopUp from "views/pages/TopUp";
import Landing from "views/pages/Landing";
//import AdminLayout from "layouts/Admin/Admin.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (localStorage.getItem("token")) {
  axios.defaults.headers.common["auth-token"] = localStorage.getItem("token");
}
axios.interceptors.request.use((req) => {
  req.meta = req.meta || {};
  req.meta.requestStartedAt = new Date().getTime();
  // console.log("interceptor req", req);
  return req;
});
axios.interceptors.response.use(
  (res) => {
    try {
      if (
        res.data.hasOwnProperty("messsge") &&
        (res.data.messsge === "Invalid token" ||
          res.data.messsge === "Access denied! unauthorize user")
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
    } catch (e) {
      // console.log("");
      return res;
    }
    // console.log("interceptor 200", res);
    return res;
  },
  (res) => {
    // console.log("interceptor 500", res);
    throw res;
  }
);


root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/registration" component={Registration} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/terms_and_conditions" component={TermsConditions} />
          <Route path="/return_and_refunds" component={ReturnRefunds} />
          <Route path="/crm-agent" component={LoginCrm} />
          <Route path="/crm-registration" component={RegistrationCrm} />
          <Route path="/all-transaction" component={TopUp} />
          <Route path="/shipping_and_delivery" component={ShippingAndDelivery} />
          <Route
            path="/acharya"
            render={(props) => <AcharyaLayout {...props} />}
          />
          <Route path="/product_services" component={ProductAndServices} />
          <Route exact path="/" component={Landing} /> {/* Make this the last route */}
          <Route exact path="*" component={Landing} />
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
