import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import showcase from "../../assets/landing/img/showcase.webp";
import Polygon from "../../assets/landing/img/Polygon.svg";
import Form from "./Form";
import VideoGallery from "./VideoGallery";
import websitebanner from "../../assets/landing/img/website-banner.webp";
import gurujiImage from "../../assets/landing/img/guruji-image.webp";
import IconBank from "../../assets/landing/img/shop.svg";
import priceBar from "../../assets/landing/img/price_bar.svg";
import setup from "../../assets/landing/img/setup.svg";
import ui from "../../assets/landing/img/ui.svg";


const Hero = ({ lang = "eng" }) => {
  const heros = {
    eng: [
      {
        img: "",
        title:
          "Discover the Secrets of Stars with India's Leading Astrology Software",
        sub_title:
          "With our advanced astrology software—highly productive yet user-friendly for acharyas, beginners, or enthusiasts—you can take your astrological practice to new heights with Gurudev GD Vashist's proven principles and insights!",
      },
    ],
    hin: [
      {
        img: "",
        title:
          "भारत के सर्वोत्तम ज्योतिष सॉफ्टवेयर के साथ करे ज्योतिषीय रहस्यों की खोज",
        sub_title:
          "हमारे कुंडली सॉफ्टवेयर के साथ, अनुभवी आचार्यों/ज्योतिषी या ज्योतिष विद्या सिखने में रूचि रखने वाले उत्साही लोगों के लिए अत्यधिक उपयोगी और अनुकूल है - आप गुरुदेव जीडी वशिष्ठ के असीमित ज्ञान, सिद्धांतों और अंतर्दृष्टि  के साथ अपने ज्योतिषीय अभ्यास को एक नयी ऊँचाइयों पर ले जा सकते है!",
      },
    ],
  };
  const Grades = {
    eng: [
      {
        heading:
          "Designed with precision and boundless opportunities in the realm of astroscience, our application can resolve your (including your client’s) queries using real time data and predictive analytics with high accuracy. With that said, the following are the features that demonstrate why ours is the best astrology software.",
        sub_heading: "Best Astrology Software with Powerful Features",
        points: [
          {
            num: "1",
            img: IconBank,
            title: "Popular Types of Chart",
            sub_title:
              "With Vashist, Lagan, Gochar, and Varshphal—the popular types of charts—you can get precise horoscope analysis based on the laws of planetary positions (current or actual), birth details, and yearly trends.",
          },
          {
            num: "2",
            img: priceBar,
            title: "Kundali Details at your Fingertips",
            sub_title:
              "Access comprehensive Kundali details effortlessly with just a few clicks. Our platform brings all the essential information right to your fingertips.",
          },
          {
            num: "3",
            img: setup,
            title: "Favourable and Unfavourable Planets",
            sub_title:
              "Using the software, you can analyze planetary behaviour and its impact on your life. With accuracy, you can determine whether each planet affects you positively or negatively",
          },
          {
            num: "4",
            img: ui,
            title: "Dasha Calculation",
            sub_title:
              "We all are most concerned with the past, present, and future. Our Kundali software's predictive analytics model offers accurate calculation of Vimshottari Dasha, LK Dasha and Varshfal with predictions and remedies.",
          },
        ],
      },
    ],

    hin: [
      {
        heading:
          "ज्योतिष विज्ञान के क्षेत्र में असीमित अवसरों और सटीकता के साथ डिज़ाइन किया गया, हमारा एप्लिकेशन वास्तविक समय डेटा और भविष्यवाणी विश्लेषण का उपयोग करके आपकी समस्याओं को उच्च सटीकता के साथ हल कर सकता है। इसके साथ ही, निम्नलिखित विशेषताएं हैं जो यह दर्शाती हैं कि हमारा सॉफ्टवेयर क्यों सर्वश्रेष्ठ कुंडली सॉफ्टवेयर है:",
        sub_heading:
          "जीडी वशिष्ठ का सॉफ़्टवेयर आपकी उन्नति के लिए सर्वश्रेष्ठ क्यों है?",
        points: [
          {
            num: "1",
            img: IconBank,
            title: "चार प्रमुख प्रकार के चार्ट",
            sub_title:
              "वशिष्ट, लग्न, गोचर, और वर्षफल—चार प्रमुख चार्टों के साथ—आप ग्रहों की स्थिति (वर्तमान या वास्तविक), जन्म के विवरण, और वार्षिक रुझानों के आधार पर सटीक कुंडली विश्लेषण प्राप्त कर सकते हैं।",
          },
          {
            num: "2",
            img: priceBar,
            title: "कुंडली का विस्तृत ज्ञान अब उँगलियों पर",
            sub_title:
              "किसी अन्य कुंडली सॉफ्टवेयरके विपरीत, हमारा एप्लीकेशन बहुत आसान और उपयोगकर्ता-अनुकूल है | बस जीडी वशिष्ट कुंडली सॉफ्टवेयर का मुफ्त डाउनलोड करें, और आप अपने ज्योतिष ज्ञान का नया सफ़र शुरू कर सकते हैं!",
          },
          {
            num: "3",
            img: setup,
            title: "ग्रहों का व्यवहार मूल्यांकन",
            sub_title:
              "जी डी वशिष्ठ कुंडली सोफ्टवेयर का उपयोग करके, आप उन ग्रहों के व्यवहार का निर्धारण कर सकते हैं जो आपके जीवन की दिशा को प्रभावित करते हैं। सटीकता के साथ, आप जान सकते हैं कि प्रत्येक ग्रह का व्यवहार आपको सकारात्मक या नकारात्मक रूप से कैसे प्रभावित करता है।",
          },
          {
            num: "4",
            img: ui,
            title: "दशा ग्रह भविष्यवाणी",
            sub_title:
              "अतीत, वर्तमान, और भविष्य, वे हैं जिनके बारे में हम सभी सबसे अधिक चिंतित और जानने के इच्छुक होते हैं। हमारे कुंडली सॉफ्टवेयर में भविष्यवाणी विश्लेषिकी मॉडल सटीकता के साथ पूर्वानुमान विवरण प्रदान करता है, जिससे आपको पता चलता है कि कैसा भविष्य आपका इंतजार कर रहा है।",
          },
        ],
      },
    ],
  };

  // console.log(heros["eng"], "heros");

  return (
    <section
      className="lg:h-screen cs_style_1 bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-90 pt-[50px] position-relative shadow-inner-2xl"
      style={{ backgroundImage: `url(${websitebanner}`, backgroundSize: "cover" }}
      id="home"
    >
      <div className="container-custom lg:h-full max-md:h-[600px] md:h-[500px]" style={{ position: "inherit" }}>
        {Grades[lang].map((item_main, index_main) => (
          <>
            <div
              key={index_main}
              className="col-xl-12  col-md-12 wow pt-16"
              data-aos="fade-in"
              data-aos-duration="800"
              data-aos-delay="200"
            >
              <div className="row">
                <div className="col-xl-9 wow flex w-100 justify-center justify-items-center py-5">
                  <div className="cs_pr_45 text-center">
                    <div className="cs_hero_text text-center">
                      {heros &&
                        heros[lang].map((item, index) => (
                          <div key={index} className="cs_section_heading cs_style_1 text-center col-xl-12 ">
                            <div className="cs_section_heading cs_style_1">
                              <h2
                                className="cs_section_title mb-0 wow fadeIn text-gray-50 max-md:text-start"
                                data-aos="fade-in"
                                data-aos-duration="800"
                                data-aos-delay="200"
                                style={{ color: "#ffffff" }}
                              >
                                {item.title}
                              </h2>
                              <p
                                className="cs_section_subtitle text-gray-300 max-lg:hidden"
                                style={{ color: "#ffffff95" }}
                              >
                                {item.sub_title}

                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
        <img
          src={showcase}
          alt="Thumbnail"
          className="position-absolute left-0 bottom-0 z-[10] w-3/4 mockup"
        />

        <img
          className="position-absolute right-0 col-xl-4 max-lg:w-1/2 lg:w-[30%] max:[996px]-1/3 guruji"
          src={gurujiImage}
          style={{
            position: "absolute",
            right: "0",
            bottom: "0",
          }}
        />
      </div>

      <div className="container-custom position-relative row flex justify-end"></div>
      <div className="cs_hero_shape3"></div>
    </section>
  );
};

export default Hero;
