import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const MovedUp = () => {
  return (
    <div className="cs_backtotop active">
      <AnchorLink href="#home">
        <i className="fas fa-angle-up"></i>
      </AnchorLink>
    </div>
  );
};

export default MovedUp;
