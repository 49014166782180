import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector,} from "react-redux";
import { updateExpire } from "redux/actions/updateExpire";
import { ThreeDots } from "react-loader-spinner";
import { checkplan } from "redux/actions/plan";
const r_key_id = process.env.REACT_APP_CHECK_R_KEY_ID;
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
const PLANAPI = process.env.REACT_APP_PLAN_API;

const UpgradePlan = ({ notify }) => {
  const [firstVal, setFirstVal] = useState(1000);
  const [secondVal, setSecondVal] = useState(0);
  const [email, setEmail] = useState();
  const [allrecharge, setRechargevalu] = useState([]);
  const [selected, getSelected] = useState(1000);
  const [plans, setPlans] = useState([]);
  // const [isValidplan, setIsValidPlan] = useState(false);
  const [update, setUpdate] = useState([]);
  const notificationAlertRef = React.useRef(null);
  // const [planStatus, setPlanStatus] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  // const currentPlan = useSelector(e => e.plan)

  let token = localStorage.getItem("token");

  // const isValidplan = currentPlan.subs_plan
  // const planStatus = currentPlan.plan_status

  const getEmail = async () => {
    let userDetails = JSON.parse(localStorage.getItem("user"));
    //console.log("User Details====",userDetails);
    let email = userDetails.email;
    //console.log("Email=====",email);
    setEmail(email);
  };

  const initPayment = async (data, plan_id) => {
    setIsLoading(true);
    let notifyMessage = "Recharge done successfully.";
    let body = data;
    //console.log("Body from initaiate payment",body);
    let userDetails = JSON.parse(localStorage.getItem("user"));
    //console.log("User Email======",userDetails.email)
    // const inObject = {
    //         amount : parseInt((body.amount) / 100),
    //         orderid: body.id,
    //         email:userDetails.email,
    // }
    //console.log("inObject inObject ======:",inObject);
    //let inserttopup = await axios.post(`${BAS_URL_APPAPI}payment/storedbeforeverify`, inObject);
    //console.log("inserttopupinserttopupinserttopupinserttopup==",inserttopup);
    const options = {
      key_id: r_key_id,
      credit: parseInt(firstVal),
      amount: 100 * secondVal,
      currency: data.currency,
      order_id: data.id,
      modal: {
        ondismiss: function () {
          setIsLoading(false);
        },
      },
      handler: async (response) => {
        const dataObj = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          userDetails: userDetails,
          schemecode: allrecharge.find(
            (value) => value.Selling_Price == selected
          ),
          email: email,
          usertype: "acharya",
          plan_id: plan_id,
          //insertedId:inserttopup.iinsertId
        };
        //console.log("")
        console.log("dataObjdataObjdataObjdataObj === before", dataObj);
        const { data } = await axios.post(
          `${BAS_URL_APPAPI}payment/verifyUpgradeSubs`,
          dataObj
        );

        if (data.message === "Payment verified successfully") {
          dispatch(updateExpire(true));
          dispatch(checkplan())
          setIsLoading(false);
          notify(notifyMessage);
        } else {
          let options = {};
          options = {
            place: "tc",
            message: notifyMessage,
            type: "primary",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
          };
          notificationAlertRef.current.notificationAlert(options);
          alert("Something went wrong");
          setIsLoading(false);
        }
        console.log("Data =============", data);
        // ¡=
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    // if(inserttopup){

    // }else{

    // }
  };

  const rechargeSubsHandler = async (e, price, p_id) => {
    console.log(e, price, p_id, "hhhhjjhjhj");

    try {
      setIsLoading(true);

      console.log(e);
      const API_URL = `${BAS_URL_APPAPI}payment/rechargeUpgradeSubs`;
      e.preventDefault();
      // const orderUrl = `${API_URL}rechargecredit`;
      // console.log("orderUrlorderUrlorderUrl  :==", orderUrl);
      const { data } = await axios.post(API_URL, {
        email: email,
        amount: price,
        plan_id: p_id,
      });
      console.log("data rechargeSubsHandler", data);
      initPayment(data.data, p_id);
    } catch (error) {
      let options = {};
      options = {
        place: "tc",
        message: error,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(options);
      setIsLoading(false);
      console.log("Payment handler not working", error);
    }
  };

  // const Recharges = async () => {
  //   setIsLoading(true);
  //   console.log("entered");
  //   try {
  //     const res = await axios.get(`${RECHARGE_API}`);
  //     console.log(res, "my plan response");
  //     setPlans(res.data.data);
  //     setIsLoading(false);
  //   } catch (err) {
  //     console.log(err, "err");
  //     setIsLoading(true);
  //   }
  // };

  const Recharges = async () => {
    setIsLoading(true);
    const tokens = localStorage.getItem("token");
    let user = JSON.parse(localStorage.getItem("user"));

    console.log(tokens, user.id, user.email, "jinjkini");

    let data = {
      acharyaid: user.id,
    };

    let headers = {
      "auth-token": token,
      "user-name": user.email,
    };

    try {
      const res =  await axios.post(
        `${BAS_URL_APPAPI}acharya/getUpgradeProduct`,
        data,
        headers
      );
      if (res.data.data) {
        setPlans(res.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(true);
      if(err.response.data.messages == 'NOT_A_USER'){
        dispatch(checkplan());
      }
    }
  };

  const UpdatePlans = async () => {
    console.log("entered");
    setIsLoading(true);
    try {
      const res = await axios.get(`${PLANAPI}`);
      console.log(res.data.data, "my plan response");
      setUpdate(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(true);
      console.log(err, "err");
    }
  };

  // const getSubscription = async () => {
  //   setIsLoading(true);
  //   try {
  //     let acharya = localStorage.getItem("user");
  //     const userDetail = JSON.parse(acharya);
  //     const acharyaid = userDetail.id;
  //     const body = {
  //       id: acharyaid,
  //     };
  //     // //console.log("Acharya Id==============",acharyaid) ;
  //     let result = await axios.post(
  //       `${BAS_URL_APPAPI}acharya/acharyaval`,
  //       body
  //     );
  //     setIsValidPlan(result.data.data.subs_plan);
  //     let planStatus = result.data.data.plan_status;
  //     setIsLoading(false);
  //     setPlanStatus(planStatus);

  //     // console.log(result, "result");
  //   } catch (error) {
  //     setIsLoading(true);
  //     //console.log(error);
  //   }
  // };

  // console.log(plans, "plansooo");

  useEffect(() => {
    // getSubscription();
    getEmail();
    Recharges();
    UpdatePlans();
  }, []);

  // console.log(isValidplan, "isValidplan");

  const myplan = plans.upgrade_product_plan && plans.upgrade_product_plan

  // console.log(currentplan, "allrecharge");

  return (
    <>
      {isLoading ? (
        <div className="bg-[#fff]">
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#1d8cf8"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      ) : (
        <Row>
          <Col xs={12} md={12}>
            <div className="p-0 flex flex-wrap items-center justify-center position-relative">
              {myplan && (
                <>
                  <div className="flex-shrink-0 m-6 relative rounded-lg max-w-xs shadow-lg py-0">
                    <>
                      {myplan.mapped_subscription_plan_id == 4 && (
                        <div
                          style={{
                            position: "absolute",
                            background: "transparent",
                            height: "140px",
                            width: "150px",
                            left: "-10px",
                            top: "-10px",
                            overflow: "hidden",
                            zIndex: "1",
                          }}
                          className=""
                        >
                          <div
                            style={{
                              position: "absolute",
                              background: "#344675",
                              height: "30px",
                              width: "20px",
                              left: "-10px",
                              bottom: "0",
                              overflow: "hidden",
                              zIndex: "1",
                            }}
                            className="bg-gradient-to-r from-slate-700 to-slate-900"
                          ></div>
                          <div
                            style={{
                              position: "absolute",
                              background: "#344675",
                              height: "10px",
                              width: "30px",
                              right: "32px",
                              top: "0",
                              overflow: "hidden",
                              zIndex: "1",
                            }}
                            className="bg-gradient-to-r from-slate-900 to-slate-700"
                          ></div>
                          <div
                            style={{
                              position: "inherit",
                              height: "30px",
                              width: "200%",
                              left: "-100px",
                              rotate: "310Deg",
                              top: "42px",
                              zIndex: "1",
                              color: "#ffffff",
                              textAlign: "center",
                              justifyItems: "center",
                              justifyItems: "center",
                              lineHeight: "2.2",
                              boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 5px",
                            }}
                            className="bg-gradient-to-r from-red-500 to-orange-500"
                          >
                            Top Choice
                          </div>
                        </div>
                      )}

                      <div className="m-0 border bg-gradient-to-r from-violet-200 to-pink-100 rounded-lg bg-pink relative overflow-hidden">
                        <svg
                          className="absolute bottom-0 left-0 mb-8"
                          viewBox="0 0 375 283"
                          fill="none"
                          style={{
                            transform: "scale(1.5)",
                            opacity: "0.1",
                          }}
                        >
                          <rect
                            x="159.52"
                            y="175"
                            width="152"
                            height="152"
                            rx="8"
                            transform="rotate(-45 159.52 175)"
                            fill="white"
                          />
                          <rect
                            y="107.48"
                            width="152"
                            height="152"
                            rx="8"
                            transform="rotate(-45 0 107.48)"
                            fill="white"
                          />
                        </svg>
                        <div className="relative text-white pt-3 pb-6 mt-6 justify-between align-center">
                          <div className="flex justify-center item-center mb-0 py-3">
                            <span className="block font-semibold text-xl">
                              <h2 className="text-center text-dark">
                                {myplan.name}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="relative px-10 flex items-start justify-start flex-col h-60">
                          {myplan.features.map((el, index) => (
                            <span
                              style={{
                                display: "flex",
                                gap: 3,
                                color: "#",
                                padding: "5px 0",
                              }}
                              className="rounded-full"
                            >
                              <i
                                key={index}
                                className="fa fa-check-circle success"
                                aria-hidden="true"
                                style={{ marginTop: "6px" }}
                              ></i>
                              &nbsp;
                              <li
                                style={{
                                  listStyle: "",
                                  color: "#000",
                                  listStyleType: "none",
                                }}
                              >
                                {" "}
                                <p
                                  style={{
                                    color: "#000",
                                    lineHeight: "25px",
                                  }}
                                >
                                  {" "}
                                  {el.description}
                                </p>
                              </li>
                            </span>
                          ))}
                        </div>
                        <div className="relative text-white px-6 pb-6 mt-6 justify-around align-center flex flex-col">
                          <div className="flex justify-center pb-2">
                            <span className="block bg-white rounded-full text-orange-500 text-lg font-bold px-3 py-2 leading-none flex items-center">
                              ₹{myplan?.price}/-
                            </span>
                          </div>
                          <div className="flex justify-center pb-2">
                            {myplan?.mapped_subscription_plan_id === 4 && (
                              <span
                                onClick={(a) =>
                                  rechargeSubsHandler(
                                    a,
                                    myplan?.price,
                                    myplan?.id
                                  )
                                }
                                role="button"
                                className="w-3/4 block bg-[#344675] rounded-full text-white-500 text-lg font-bold px-3 py-2 leading-none flex items-center justify-around hover:text-[#344675] hover:bg-white"
                              >
                                Upgrade
                              </span>
                            )}
                            {myplan?.mapped_subscription_plan_id === 3 && (
                              <span
                                onClick={(a) =>
                                  rechargeSubsHandler(
                                    a,
                                    myplan?.price,
                                    myplan?.id
                                  )
                                }
                                role="button"
                                className="w-3/4 block bg-[#344675] rounded-full text-white-500 text-lg font-bold px-3 py-2 leading-none flex items-center justify-around hover:text-[#344675] hover:bg-white"
                              >
                                Change
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default UpgradePlan;
