import {SELECTED_LANGUAGE } from '../actions/constants'

const initialState = {}

export default function(state=initialState, action){
    const {type, payload} = action

    switch(type){
        case SELECTED_LANGUAGE:
            // console.log("e.target.value",payload)
            //state.horo = payload
            return {...state,...payload}
        default:
            return state
    }
}