import React from 'react'
import './Spinner.css'

const Loadingspinner = () => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">       
      </div>
    </div>
  )
}

export default Loadingspinner