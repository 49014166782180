import axios from 'axios'
import NotificationDisplay from 'components/Dashboard/NotificationDisplay'
import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import "../../assets/css/custom.css"
import { Card, CardBody, CardHeader, Col, Form, Row } from 'reactstrap'
import NotificationAlert from "react-notification-alert";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;


const Suggestion = () => {
  const [suggestion, setSuggestion] = useState("")
  const [suggestiontext, setSuggestionText] = useState()
  const [message, setMessage] = useState();
  const notificationAlertRef = React.useRef(null);

  const Submit = async(e) => {
    e.preventDefault();
    let notifyMessage = "Your query has been raised successfully";
    try {
        const acharya = localStorage.getItem("user");
        const userDetail = JSON.parse(acharya);
        const acharyaid = userDetail.id;
        const achtoken = localStorage.getItem("token");
        let dataObj = {  
          acharya_id: acharyaid,       
          issue: suggestion,
          message: suggestiontext,

        }

        // console.log("Data Object in suggestion create======",dataObj);
        const configheader = {
          headers: { 
              'Content-Type': 'application/json',
              'authorization':'Bearer '+ achtoken,
            }         
        }
        
        const pushsuggestion = await axios.post(`${BAS_URL_APPAPI}acharya/createsuggestion`,dataObj,configheader)
        // console.log("Horoscope Data=====================",pushsuggestion)

        if(pushsuggestion.data.success === 1){
          let options = {};
          options = {
            place: "tc",
            message: notifyMessage,
            type: "primary",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
          };
            notificationAlertRef.current.notificationAlert(options);
            //alert("Suggestin sent successfully.");
            setSuggestion("");
            setSuggestionText("");
        }
        //els
      } catch (err) {
        console.log(err);
      }

  }

  return (
    <div className='content'>
      {/* <NotificationDisplay/> */}
      {/* <Card>
        <CardBody>
            <Link className="btn btn-outline-light submitbtn" to="/acharya/support"><i className='fa fa-arrow-left'></i> Back</Link>
        </CardBody>
      </Card> */}
      <NotificationAlert ref={notificationAlertRef} />
        <Row>
        <Col md="6 m-auto">
            <div className='add-suggestion'>
              <Card className=''>
                <CardHeader className='cardheaderall'>
                  <Link className="btn btn-outline-light submitbtn" to="/acharya/support"><i className='fa fa-arrow-left'></i> Back</Link>
                  <h4 className='text-center'>Add Query</h4>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={Submit}>
                    <div className="form-group">
                      <select className='form-control mb-2' name="suggestiontype" value={suggestion} onChange={(e) => setSuggestion(e.target.value)} required>
                          <option className='form-control' value="">Select Query Type</option>
                          <option className='form-control' value="Panel">Panel</option>
                          <option className='form-control' value="Kundali">Kundali</option>
                          <option className='form-control' value="Recharge">Recharge</option>
                          <option className='form-control' value="Other_Option">Other Option</option>
                      </select>
                      <textarea type="text" className='form-control mb-2' placeholder='Enter Details' value={suggestiontext} onChange={(e) => setSuggestionText(e.target.value)}></textarea>
                      <input type="submit" className=' btn sugbtn' value="Submit" />
                      <div className="message">{message ? <p>{message}</p> : null}</div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
        </Col>
      </Row>

    </div>
  )
}

export default Suggestion