import React from "react";
import { Card, CardBody, Row, Col, Table } from "reactstrap";

import { useSelector } from "react-redux";

const Horoinnertwo = () => {
  const Headers = ["Desk", "Value"];

  const allData = useSelector((state) => state.horoscope);
  const deskValue = allData.horo.deskvalue;
  return (
      <table className="w-full text-[10px] lg:text-[10px] text-left rtl:text-right" >
        <thead className="text-xs  text-gray-900 bg-gray-100 sticky top-0">
          <tr>
            <th scope="col" className="px-2 py-2 text-[12px]">
              {Headers[0]}
            </th>
            <th scope="col" className="px-2 py-2 text-[12px]">
              {Headers[1]}
            </th>
          </tr>
        </thead>
        <tbody>
          {deskValue &&
            deskValue.map((val) => (
              <tr className="odd:bg-white  even:bg-gray-50 border-b lg:text-[10px] text-gray-950">
                <td className="px-2 py-0 border-r-[1px]"><strong>{val.Desk}</strong></td>
                <td className="px-2 py-0"><strong>{val.Value}</strong></td>
              </tr>
            ))}
        </tbody>
      </table>
  );
};

export default Horoinnertwo;
