import axios from "axios";
import classNames from "classnames";
import ReactTable from "components/ReactTable/ReactTable.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useDispatch} from "react-redux";
import { logout } from "redux/actions/auth";

const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
const VASHISHT_API_URL = process.env.REACT_APP_VASHISHT_API;

const Transaction = () => {
  const dispatch = useDispatch();
  const [transaction, setTransaction] = useState([]);
  const notificationAlertRef = React.useRef(null);
  const token = localStorage.getItem("token");
  const acharya = localStorage.getItem("user");

  const getfreekundaliData = async () => {
    try {
      const userDetail = JSON.parse(acharya);
      const id = userDetail.id;
      const email = userDetail.email;
      let dataObj = {
        email: email,
      };

      const transaction = await axios.post(
        `${BAS_URL_APPAPI}acharya/credittransaction`,
        dataObj
      );
      console.log(transaction, "transaction");
      if (transaction) {
        let data = transaction.data.data.map((val, ind) => {
          return {
            ...val,

            action:
              val.action == "UPGRADE PLAN"
                ? "UPGRADE PLAN" +
                  " " +
                  (val.reg_plan == 1
                    ? "(General Registration)"
                    : "(Premium Registration)")
                : val.action == "REGISTER"
                ? "REGISTER" +
                  " " +
                  (val.reg_plan == 1
                    ? "(General Registration)"
                    : "(Premium Registration)")
                : val.action == "RENEW"
                ? "RENEW" +
                  " " +
                  (val.subs_plan == 3
                    ? "(Gold)"
                    : "(Platinum)")
                : val.action == "RECHARGE"
                ? "RECHARGE" +
                  " " +
                  (val.subs_plan == 3
                    ? "(Gold)"
                    : "(Platinum)")
                :val.action,
            status: val.status == 1 ? "Success" : "Unsuccessful",
            action_credit: val.action_credit == 0 ? "NA" : val.action_credit,
          };
        });
        setTransaction(data);
      } else {
        let notifyMessage = "No Data Found";
        let optiondata = {};
        optiondata = {
          place: "tc",
          message: notifyMessage,
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(optiondata);
      }
    } catch (error) {
      console.log(error);
      if(error.response.data.messages == 'NOT_A_USER'){
        dispatch(logout());
      }
    }
  };


  // const getSubscription = async () => {
  //   try {
  //     let acharya = localStorage.getItem("user");
  //     const userDetail = JSON.parse(acharya);
  //     const acharyaid = userDetail.id;
  //     const body = {
  //       id: acharyaid,
  //     };
  //     // //console.log("Acharya Id==============",acharyaid) ;
  //     let result = await axios.post(
  //       `${BAS_URL_APPAPI}acharya/acharyaval`,
  //       body
  //     );
  //   } catch (error) {
  //     let data = localStorage.getItem("user");
  //     let finalData = JSON.parse(data);
  //     let token = localStorage.getItem("token");
  //     if (error.response.data.messages == "NOT_A_USER") {
  //       const obj = {
  //         id: finalData.id,
  //         token: token,
  //       };
  //       // console.log(error, finalData.id, token, obj, "JJJJJJJJ")
  //       try{
  //       const result = await axios.post(`${BAS_URL_APPAPI}acharya/logout`, obj);
  //       }catch(err){
  //         if (error.response.data.messages == "NOT_A_USER") {
  //           dispatch(logout());
  //         }
  //       }
  //     }
  //   }
  // };






  useEffect(() => {
    getfreekundaliData();
    // getSubscription();
  }, []);

  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const [data, setData] = React.useState(
    transaction.map((prop, key) => {
      return {
        id: key,
        action: prop[1],
        action_credit: prop[2],
        total_credit: [3],
        amount: prop[4],
        credits: prop[5],
        transaction_id: prop[6],
        mode: prop[7],
        //status: prop[7],
        date: prop[8],
      };
    })
  );
  console.log(transaction, "transaction");
  return (
    <div className="content">
      <NotificationAlert ref={notificationAlertRef} />
      <Row>
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                <h4>
                  <b>All Transactions</b>
                </h4>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={transaction}
                filterable
                resizable={true}
                columns={[
                  {
                    Header: "Id",
                    accessor: (row, index) => index + 1,
                  },
                  {
                    Header: "Action",
                    accessor: "action",
                  },
                  {
                    Header: "Amount",
                    accessor: "action_credit",
                  },
                  // {
                  //   Header:"Total Credit",
                  //   accessor:"total_credit"
                  // },

                  {
                    Header: "Transaction Id",
                    accessor: "transaction_id",
                  },
                  {
                    Header: "Order Id",
                    accessor: "order_id",
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                  },
                  {
                    Header: "Date",
                    accessor: (row) => (
                      <div>
                        {moment(row.date).utc().format("DD/MM/YY")}{" "}
                        <div>{moment(row.date).utc().format(" HH:mm")}</div>
                      </div>
                    ),
                  },
                ]}
                defaultPageSize={10}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Transaction;
