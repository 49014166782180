import {useSelector} from 'react-redux'
import {UncontrolledAlert} from "reactstrap";

import './Alerts.scss'

import React from 'react'

function Alerts() {
    const alerts = useSelector(state => state.alert)
    console.log(alerts)
    return (
        <div className="alertContainer" style={{width:"50%"}}>
            {alerts.map( alert => 
            <UncontrolledAlert key={alert.id} color={alert.alertType}>
                <span>
                    {alert.msg}
                </span>
            </UncontrolledAlert>)}            
        </div>
    )
}

export default Alerts











