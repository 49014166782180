
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

const Footer = (props) => {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container className="container-custom">
        <ul className="nav nav-padding">
          <li className="nav-item">
            <a className="nav-link-footer" href="https://www.deeptechnoservices.com" target="_blank">
              Deep TechnoServices LLP
            </a>
          </li>{" "}
          <li className="nav-item">
            <a
              className="nav-link-footer"
              href="https://www.deeptechnoservices.com/about-us" target="_blank"
            >
              About us
            </a>
          </li>{" "}
          {/* <li className="nav-item">
            <a className="nav-link-footer" href="https://www.astroscience.com/hindi-blog.php" target="_blank">
              Blog
            </a>
          </li> */}
        </ul>
        <div className="copyright">
          © {new Date().getFullYear()} Reserved 
          by
          <a href="https://www.deeptechnoservices.com/"  target="_blank">
            
          </a>{" "}
          Deep TechnoServices LLP
        </div>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
