import React, { useEffect, useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";
import classnames from "classnames";
import Parser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/custom.css";
import axios from "axios";
import { Audio, Rings } from "react-loader-spinner";
import { Threedotscomp } from "components/Spinner/Threedotscomp";
import { useDoubleTap } from "use-double-tap";
//import ReactHtmlParser from "react-html-parser"
//import parse from 'html-react-parser';
const VASHISHT_API_URL = process.env.REACT_APP_VASHISHT_API;
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const Mahadasha = ({ heightLeft, heightChart }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [khabarmodal, setKhabarModal] = useState(false);
  const [varshfalmodal, setVarshfalModal] = useState(false);
  const [houseModal, setHouseModal] = useState(false);
  const togglemodal = () => {
    setModal(!modal);
    handleScrollUnblock();
  };
  const togglemodal1 = () => {
    setModal1(!modal1);
    handleScrollUnblock();
  };
  const togglemodal2 = () => {
    setKhabarModal(!khabarmodal);
    handleScrollUnblock();
  };
  const [antardasha, setAntardasha] = useState(false);
  const [pratyantardasha, setPratyantardasha] = useState(false);
  const [shuksmdasha, setShukshmdasha] = useState(false);
  const [dashapred, setDashapred] = useState();
  const [housepred, setHousepred] = useState();
  const [khabardashapred, setKhabarDashapred] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [checkedchakar, setCheckedchakkar] = useState(false);
  const [chakar, setChakar] = useState();
  const [countDisabled, setCountDisabled] = useState(false);
  const [rlnlsl, setChartheader] = useState();
  const [mahadashaheader, setMahadashheader] = useState();
  const [lkachakarplanet, setLkachakarplanet] = useState();
  const [lkachakarstart, setLkachakarstart] = useState();
  const [lkachakarend, setLkachakarend] = useState();
  const [partdashaheader, setPartdashheader] = useState();
  const [selectedvalue, setSelectedValue] = useState(-1);
  const [antrselectedvalue, setAntarSelectedValue] = useState(-1);
  const [partselectedvalue, setPartSelectedValue] = useState(-1);
  const [sukshmselectedvalue, setShukshmSelectedValue] = useState(-1);
  const [timer, setTimer] = useState(null);
  const [defaultColor, setdefaultColor] = useState("#fff");
  const [selectedID, setSelectedID] = React.useState(null);
  const [selectedRow, setSelectedRow] = useState(-1);
  const allData = useSelector((state) => state.horoscope);
  const store = allData.horo.store;
  const leve1One = allData.horo.leve1One;
  const lkdasha = allData.horo.lkdasha;
  const products = useSelector((state) => state.selectedproduct);
  const language1 = useSelector((state) => state.selectedlanguage);
  const kundalidata = localStorage.getItem("kundalidata");
  let productdata = kundalidata ? JSON.parse(kundalidata) : "";
  let productid = productdata.product;
  let mahavalue1;
  let antvalue2;
  let partvalue3;
  let shukshmvalue3;
  if (allData.horo.deskvalue) {
    mahavalue1 = allData.horo.deskvalue[1].id
      ? allData.horo.deskvalue[1].id
      : "";
    antvalue2 = allData.horo.deskvalue[2].id
      ? allData.horo.deskvalue[2].id
      : "";
    partvalue3 = allData.horo.deskvalue[3].id
      ? allData.horo.deskvalue[3].id
      : "";
    shukshmvalue3 = allData.horo.deskvalue[4].id
      ? allData.horo.deskvalue[4].id
      : "";
  }
  // console.log("ABCD ++----++++", allData.horo);
  if (allData.horo.new_userDEtails) {
    // console.log("ABCD ++++++++", allData.horo);
    let dob = allData.horo.new_userDEtails[0].dob.split("/");
    let tob = allData.horo.new_userDEtails[0].tob.split(":");
    let gender = allData.horo.new_userDEtails[0].gender;
    let name = allData.horo.new_userDEtails[0].name;
    allData.horo.userdetails1.dob.day = dob[0];
    allData.horo.userdetails1.dob.month = dob[1];
    allData.horo.userdetails1.dob.year = dob[2];
    allData.horo.userdetails1.tob.hour = tob[0];
    allData.horo.userdetails1.tob.minute = tob[1];
    allData.horo.userdetails1.gender = gender;
    allData.horo.userdetails1.name = name;
  }

  // stop scrolling when model on

  const handleScrollBlock = () => {
    const stopScroll = document.getElementsByTagName("body");
    // stopScroll[0].style.height = "100vh";
    // stopScroll[0].style.overflow = "visible";
    stopScroll[0].style.position = "relative";
    stopScroll[0].style.background = "lightgrey";
  };

  const handleScrollUnblock = () => {
    const stopScroll = document.getElementsByTagName("body");
    stopScroll[0].style.height = "auto";
    stopScroll[0].style.overflow = "auto";
    stopScroll[0].style.background = "white";
  };

  const closeAntardiv = async () => {
    if (setChecked === true) {
      setAntardasha(true);
      setPratyantardasha(true);
      setShukshmdasha(true);
    }
  };

  function closeModal() {
    setKhabarModal(false);
    setHouseModal(false);
    handleScrollUnblock(false);
  }

  function closeModalVarshfal() {
    setVarshfalModal(false);
    handleScrollUnblock();
  }

  function closeModal1() {
    setModal1(false);
    handleScrollUnblock();
  }
  const LKADasha = async (level1) => {
    setModal1(true);
    setIsLoading(true);
    let nak = allData.horo.deskvalue;
    let dob =
      allData.horo.userdetails1.dob.day +
      "/" +
      allData.horo.userdetails1.dob.month +
      "/" +
      allData.horo.userdetails1.dob.year;
    //let tob = allData.horo.userdetails1.tob;
    let tob =
      allData.horo.userdetails1.tob.hour +
      "/" +
      allData.horo.userdetails1.tob.minute;
    let gender = allData.horo.userdetails1.gender;
    let cusp_chart = allData.horo.new_cusp_chart;
    let kp_chart = allData.horo.new_kp_chart;
    let dasha_end = level1.EndDate;
    let dasha_planet = level1.Planet;
    let dasha_start = level1.StartDate;
    let userdetails1 = allData.horo.userdetails1;
    const body = {
      Dob: dob,
      cusp_chart: cusp_chart,
      dasha_end: dasha_start,
      dasha_planet: dasha_planet,
      dasha_start: dasha_end,
      gender: gender,
      kp_chart: kp_chart,
      langugae: language1.language,
      nak: nak,
      // "product":products.product,
      product: productid,
      showref: true,
      showupay: false,
      tob: tob,
      type: "35years",
      userDetails: userdetails1,
    };
    let result = await axios.post(`${BAS_URL_APPAPI}acharya/lkadasha`, body);
    setChakar(result.data.data);
  };

  const LKADashaPopUp = async (level1) => {
    setModal1(true);
    setIsLoading(true);
    handleScrollBlock();
    let nak = allData.horo.deskvalue;
    let dob =
      allData.horo.userdetails1.dob.day +
      "/" +
      allData.horo.userdetails1.dob.month +
      "/" +
      allData.horo.userdetails1.dob.year;
    let tob =
      allData.horo.userdetails1.tob.hour +
      "/" +
      allData.horo.userdetails1.tob.minute;
    let gender = allData.horo.userdetails1.gender;
    let cusp_chart = allData.horo.new_cusp_chart;
    let kp_chart = allData.horo.new_kp_chart;
    let dasha_end = level1.EndDate;
    let dasha_planet = level1.Planet;
    let dasha_start = level1.StartDate;
    let userdetails1 = allData.horo.userdetails1;
    const body = {
      Dob: dob,
      cusp_chart: cusp_chart,
      dasha_end: dasha_start,
      dasha_planet: dasha_planet,
      dasha_start: dasha_end,
      gender: gender,
      kp_chart: kp_chart,
      langugae: language1.language,
      nak: nak,
      product: productid,
      showref: true,
      showupay: false,
      tob: tob,
      type: "35years",
      userDetails: userdetails1,
    };
    let result = await axios.post(`${BAS_URL_APPAPI}acharya/lkadasha`, body);
    setChakar(level1.varsh);
  };

  const openModallka = async (chakar35) => {
    setKhabarModal(true);
    setIsLoading(true);
    handleScrollBlock();
    let gender = allData.horo.userdetails1.gender;
    let cusp_chart = allData.horo.new_cusp_chart;
    let kp_chart = allData.horo.new_kp_chart;
    let dasha_end = chakar35.Start_Date;
    let dasha_planet = chakar35.Planet;
    let planetNumber = chakar35.planetNumber;
    let antar = chakar35.Antar;
    let age = chakar35.Age;
    let dasha_start = chakar35.End_Date;
    setLkachakarplanet(dasha_planet);
    setLkachakarstart(dasha_start);
    setLkachakarend(dasha_end);
    const body = {
      dasha_planet: planetNumber,
      kundali: allData.horo.laganKundali,
      kp_chart: kp_chart,
      cusp_chart: cusp_chart,
      type: "varshfal",
      //"product":products.product,
      product: productid,
      Planet: dasha_planet,
      planetNumber: planetNumber,
      Antar: antar,
      // "dasha_end" :dasha_start,
      // "dasha_start":dasha_end,
      End_Date: dasha_start,
      Start_Date: dasha_end,
      Age: age,
      month12: checkedchakar,
      showref: true,
      showupay: false,
      gender: gender,
      langugae: language1.language,
    };
    let dashapred = await axios.post(
      `${BAS_URL_APPAPI}acharya/dashapred`,
      body
    );
    setKhabarDashapred(dashapred.data.data.khabar);
    // console.log(dashapred, "dashaprediction");
    setIsLoading(false);
  };

  const monthfale = async (chakar) => {
    setKhabarModal(true);
    setIsLoading(true);
    let gender = allData.horo.userdetails1.gender;
    let cusp_chart = allData.horo.new_cusp_chart;
    let kp_chart = allData.horo.new_kp_chart;
    let userDetails = allData.horo.userdetails1;

    const body = {
      kundali: allData.horo.laganKundali,
      kp_chart: kp_chart,
      cusp_chart: cusp_chart,
      type: "monthfal",
      product: productid,
      showref: true,
      showupay: false,
      gender: gender,
      userDetails: userDetails,
      langugae: language1.langugae,
    };

    let dashapred = await axios.post(
      `${BAS_URL_APPAPI}acharya/dashapred`,
      body
    );
    setKhabarDashapred(dashapred.data.data.khabar);
    setIsLoading(false);
  };

  const weekfal = async (chakar) => {
    setKhabarModal(true);
    setIsLoading(true);
    let gender = allData.horo.userdetails1.gender;
    let cusp_chart = allData.horo.new_cusp_chart;
    let kp_chart = allData.horo.new_kp_chart;
    let userDetails = allData.horo.userdetails1;

    const body = {
      kundali: allData.horo.laganKundali,
      kp_chart: kp_chart,
      cusp_chart: cusp_chart,
      type: "weekfal",
      product: productid,
      showref: true,
      showupay: false,
      gender: gender,
      userDetails: userDetails,
      langugae: language1.langugae,
    };

    let dashapred = await axios.post(
      `${BAS_URL_APPAPI}acharya/dashapred`,
      body
    );
    setKhabarDashapred(dashapred.data.data.khabar);
    setIsLoading(false);
  };

  const dayfal = async (chakar) => {
    setKhabarModal(true);
    setIsLoading(true);
    let gender = allData.horo.userdetails1.gender;
    let cusp_chart = allData.horo.new_cusp_chart;
    let kp_chart = allData.horo.new_kp_chart;
    let userDetails = allData.horo.userdetails1;

    const body = {
      kundali: allData.horo.laganKundali,
      kp_chart: kp_chart,
      cusp_chart: cusp_chart,
      type: "dayfal",
      product: productid,
      showref: true,
      showupay: false,
      gender: gender,
      userDetails: userDetails,
      langugae: language1.langugae,
    };

    let dashapred = await axios.post(
      `${BAS_URL_APPAPI}acharya/dashapred`,
      body
    );
    setKhabarDashapred(dashapred.data.data.khabar);
    setIsLoading(false);
  };

  const antardiv = async (level1) => {
    let antarDasha = level1;
    let kp_chart = allData?.horo.new_kp_chart;
    let dashaPlanet = antarDasha?.Planet;
    let StartDate = antarDasha?.StartDate;
    let EndDate = antarDasha?.EndDate;
    const body = {
      dashaPlanet: dashaPlanet,
      StartDate: StartDate,
      EndDate: EndDate,
      kp_chart: kp_chart,
    };

    if (body.dashaPlanet && body.StartDate && body.EndDate && body.kp_chart) {
      let result = await axios.post(
        `${BAS_URL_APPAPI}acharya/antardasha`,
        body
      );
      setAntardasha(result.data.data.antardasha);
      setPratyantardasha(false);
      setPartSelectedValue(-1);
      setShukshmSelectedValue(-1);
      setShukshmdasha(false);
    }
  };

  const partyantarDiv = async (antardasha1, allData) => {
    let partyantar = antardasha1;

    let alldata = allData;

    let kp_chart = allData?.horo.new_kp_chart;
    let antarplanet = partyantar?.Planet;
    let dashaPlanet = partyantar?.mahadashaplanet;
    let StartDate = partyantar?.StartDate;
    let EndDate = partyantar?.EndDate;
    const body = {
      antar_planet: dashaPlanet,
      dasha_starts: StartDate,
      main_dasha_ends: EndDate,
      kp_chart: kp_chart,
      main_planet: antarplanet,
    };

    if (
      body.antar_planet &&
      body.dasha_starts &&
      body.kp_chart &&
      body.main_dasha_ends &&
      body.main_planet
    ) {
      let result = await axios.post(
        `${BAS_URL_APPAPI}acharya/pryanterdasha`,
        body
      );
      setPratyantardasha(result.data.data.pryanterdasha);
      setShukshmSelectedValue(-1);
      setShukshmdasha(false);
    }
  };
  const shukshmDiv = async (pratyantardasha1, allData) => {
    let shukshm = pratyantardasha1;
    let alldata = allData;
    let kp_chart = allData.horo.new_kp_chart;
    let dashaPlanet = shukshm.Planet;
    let StartDate = shukshm.StartDate;
    let EndDate = shukshm.EndDate;
    let partyanterPlanet = shukshm.Planet;
    // console.log("Partyanter Palnet===", partyanterPlanet);
    const body = {
      antar_planet: dashaPlanet,
      dasha_starts: StartDate,
      main_dasha_ends: EndDate,
      kp_chart: kp_chart,
      main_planet: dashaPlanet,
      // "pryantar_planet":shukshm.Planet
      pryantar_planet: partyanterPlanet,
    };

    // console.log("Start Date = End Date= Planet Name==, KP_Chart", body);

    let result = await axios.post(
      `${BAS_URL_APPAPI}acharya/sukhsmadasha`,
      body
    );
    // console.log("Result data -======================", result.data.data);
    // setAntardasha(current => !current);
    setShukshmdasha(result.data.data.sukhsmadasha);
  };

  const openModal = async (level1) => {
    setModal(true);
    setIsLoading(true);
    handleScrollBlock();
    // console.log("Loading==========", isLoading);
    let mahadashapred = level1.Planet;
    // console.log("Mahadasha Lavel one data==", level1);
    setMahadashheader(level1);
    let kp_chart = allData.horo.new_kp_chart;
    let cusp_chart = allData.horo.new_cusp_chart;
    let kundali = allData.horo.laganKundali;
    let mahadasha = level1;
    let nak = allData.horo.deskvalue;
    let gender = allData.horo.userdetails1.gender;
    let userDetails = allData.horo.userdetails1;
    const body = {
      dasha_planet: mahadashapred,
      cusp_chart: cusp_chart,
      gender: gender,
      langugae: language1.language,
      kp_chart: kp_chart,
      kundali: kundali,
      mahadasha: mahadasha,
      nak: nak,
      //"product": products.products,
      product: productid,
      showref: true,
      showupay: false,
      type: "mahadasha",
      userDetails: userDetails,
    };

    let dashapred = await axios.post(
      `${BAS_URL_APPAPI}acharya/dashapred`,
      body
    );
    setDashapred(dashapred.data.data.dashaprediction);
    setModal(true);
    setIsLoading(false);
  };

  const openModalVarsh = async (level1) => {
    setVarshfalModal(true);
    setIsLoading(true);
    handleScrollBlock();
    let mahadashapred = level1.planetNumber;
    setMahadashheader(level1);
    let kp_chart = allData.horo.new_kp_chart;
    let cusp_chart = allData.horo.new_cusp_chart;
    let kundali = allData.horo.laganKundali;
    let mahadasha = level1;
    let nak = allData.horo.deskvalue;
    let gender = allData.horo.userdetails1.gender;
    let userDetails = allData.horo.userdetails1;
    const body = {
      dasha_planet: mahadashapred,
      cusp_chart: cusp_chart,
      gender: gender,
      langugae: language1.language,
      kp_chart: kp_chart,
      kundali: kundali,
      mahadasha: mahadasha,
      nak: nak,
      product: productid,
      showref: true,
      showupay: false,
      type: "35years",
      userDetails: userDetails,
    };

    let dashapred = await axios.post(
      `${BAS_URL_APPAPI}acharya/dashapred`,
      body
    );
    setDashapred(dashapred.data.data.dashaprediction);
    setVarshfalModal(true);
    setIsLoading(false);
  };

  const openAntarModal = async (antardasha1) => {
    setModal(true);
    setIsLoading(true);
    setMahadashheader(antardasha1);
    handleScrollBlock();
    let mahadashapred = antardasha1.Planet;
    let kp_chart = allData.horo.new_kp_chart;
    let cusp_chart = allData.horo.new_cusp_chart;
    let kundali = allData.horo.laganKundali;
    let mahadasha = antardasha1;
    let nak = allData.horo.deskvalue;
    let gender = allData.horo.userdetails1.gender;
    let userDetails = allData.horo.userdetails1;
    const body = {
      dasha_planet: mahadashapred,
      cusp_chart: cusp_chart,
      gender: gender,
      langugae: language1.language,
      kp_chart: kp_chart,
      kundali: kundali,
      mahadasha: mahadasha,
      nak: nak,
      product: productid,
      showref: true,
      showupay: false,
      type: "antardasha",
      userDetails: userDetails,
    };

    let dashapred = await axios.post(
      `${BAS_URL_APPAPI}acharya/dashapred`,
      body
    );
    setDashapred(dashapred.data.data.dashaprediction);
    setIsLoading(false);
  };
  const openPartModal = async (pratyantardasha1) => {
    setModal(true);
    setIsLoading(true);
    handleScrollBlock();
    setMahadashheader(pratyantardasha1);
    let mahadashapred = pratyantardasha1.Planet;
    let kp_chart = allData.horo.new_kp_chart;
    let cusp_chart = allData.horo.new_cusp_chart;
    let kundali = allData.horo.laganKundali;
    let mahadasha = pratyantardasha1;
    let nak = allData.horo.deskvalue;
    let gender = allData.horo.userdetails1.gender;
    let userDetails = allData.horo.userdetails1;
    const body = {
      dasha_planet: mahadashapred,
      cusp_chart: cusp_chart,
      gender: gender,
      langugae: language1.language,
      kp_chart: kp_chart,
      kundali: kundali,
      mahadasha: mahadasha,
      nak: nak,
      product: productid,
      showref: true,
      showupay: false,
      type: "preantardasha",
      userDetails: userDetails,
    };
    let dashapred = await axios.post(
      `${BAS_URL_APPAPI}acharya/dashapred`,
      body
    );
    setDashapred(dashapred.data.data.dashaprediction);
    setIsLoading(false);
  };

  const openChartModal = async (row) => {
    setHouseModal(true);
    handleScrollBlock();
    setIsLoading(true);
    let house = row.House;
    setChartheader(row);
    let kp_chart = allData.horo.new_kp_chart;
    let cusp_chart = allData.horo.new_cusp_chart;
    const body = {
      showref: true,
      house: house,
      cusp_chart: cusp_chart,
      kp_chart: kp_chart,
      langugae: language1?.language,
    };

    let housepred1 = await axios.post(
      `${BAS_URL_APPAPI}acharya/housepreddata`,
      body
    );
    setHousepred(housepred1.data.data);
    setIsLoading(false);
  };

  const changeColor = (e, i) => {
    setSelectedValue(i);
  };
  const changeAntrColor = (e, i) => {
    setAntarSelectedValue(i);
  };
  const changePartColor = (e, i) => {
    setPartSelectedValue(i);
  };
  const changeShukshmColor = (e, i) => {
    setShukshmSelectedValue(i);
  };

  useEffect(() => {
    antardiv();
    partyantarDiv();
    closeAntardiv();
  }, []);
  useEffect(() => {
    toggle("1");
    setAntardasha(false);
    setPratyantardasha(false);
    setShukshmdasha(false);

    setAntarSelectedValue(-1);
    setPartSelectedValue(-1);
    setSelectedValue(-1);
    setShukshmSelectedValue(-1);
    setChecked(false);
  }, [leve1One]);
  const handleRowDoubleClick = (row) => {
    openChartModal(row);
  };
  const Bind1 = (level1) =>
    useDoubleTap((e) => {
      openModal(level1);
    });
  const handleTouchStart = (level1) => {
    setTimer(
      setTimeout(() => {
        openModal(level1);
      }, 1000)
    );
  };
  const handleTouchEnd = () => {
    clearTimeout(timer);
  };

  let checkLKADASHA = (check) => {
    if (check) {
      setAntardasha(false);
      setPratyantardasha(false);
      setShukshmdasha(false);

      setAntarSelectedValue(-1);
      setPartSelectedValue(-1);
      setSelectedValue(-1);
      setShukshmSelectedValue(-1);
      setChecked(false);
    }
    setChecked(check);
  };

  return (
    <div style={{ position: "inherit", width: "100%" }} className="rounded-lg">
      <Nav className="position-sticky top-0 mb-2 z-[1]">
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            gap: 10,
            paddingBottom: "3px",
          }}
        >
          <NavItem>
            <NavLink
              className={classnames({ active: currentActiveTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
              style={{
                borderRadius: "30px",
                padding: "5px 8px",
                fontSize: "12px",
              }}
            >
              Chart
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: currentActiveTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
              style={{
                borderRadius: "30px",
                padding: "5px 8px",
                fontSize: "12px",
              }}
            >
              Mahadasha
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: currentActiveTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
              style={{
                borderRadius: "30px",
                padding: "5px 8px",
                fontSize: "12px",
              }}
            >
              {" "}
              LKADasha
            </NavLink>
          </NavItem>
        </div>
      </Nav>
      <TabContent activeTab={currentActiveTab} className="pr-0">
        <TabPane
          className="overflow-y-auto horoscope-box overflow-x-auto"
          tabId="1"
          style={{
            overflow: "overlay",
            height: `calc(${heightLeft - heightChart}px - 114px)`,
          }}
        >
          <Row className="h-full mx-0 px-0">
            <Col md="12" sm="12" className="px-0 h-full">
              <Table
                striped
                bordered
                hover
                style={{
                  width: "100%",
                }}
                className=" text-gray-900 overflow-x-auto pr-0 h-full nowrap max-md:whitespace-nowrap"
              >
                <thead
                  className={`bg-gray-100 position-sticky top-0 ${
                    store && "z-10"
                  }`}
                >
                  <tr>
                    <th className="px-2 py-2 text-[12px]">House</th>
                    <th className="px-2 py-2 text-[12px]">Degree</th>
                    <th className="px-2 py-2 text-[12px]">RL-NL-SL-SSL</th>
                    <th className="px-2 py-2 text-[12px]">Significators</th>
                    <th className="px-2 py-2 text-[12px]">Nak Signi</th>
                    <th className="px-2 py-2 text-[12px]">Sub Signi</th>
                  </tr>
                </thead>

                <tbody>
                  {store &&
                    store.length > 0 &&
                    store.map((row, index) => (
                      <tr
                        className="cursor-pointer"
                        {...row}
                        key={index}
                        onDoubleClick={() => handleRowDoubleClick(row)}
                      >
                        <td className="px-2 py-2 text-[12px]">{row.House}</td>
                        <td className="px-2 py-2 text-[12px]">
                          {row.DegreeHouse}
                        </td>
                        <td className="px-2 py-2 text-[12px]">
                          {row.RL}-{row.NL}-{row.SL}-{row.SSL}
                        </td>
                        <td className="px-2 py-2 text-[12px]">
                          {row.Significaters}
                        </td>
                        <td className="px-2 py-2 text-[12px]">
                          {row.nakSigni}
                        </td>
                        <td className="px-2 py-2 text-[12px]">
                          {row.subSigni}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </TabPane>
        <TabPane
          className="overflow-y-auto horoscope-box overflow-x-auto"
          tabId="2"
          style={{
            overflow: "overlay",
            height: `calc(${heightLeft - heightChart}px - 114px)`,
          }}
        >
          <Row style={{ overFlow: "auto" }} className="h-full pb-1">
            <Col md="12" sm="12" className="mahadasha2 d-flex">
              <Table
                striped
                bordered
                hover
                className="mahadasha-div mahadasha text-gray-900 h-full"
                style={{ minWidth: "425px", maxWidth: "425px" }}
              >
                <thead className="p-0 bg-gray-100 z-20">
                  <tr>
                    <th className="px-2 py-2 text-[12px]">Planet</th>
                    <th className="px-2 py-2 text-[12px]">Period</th>
                    <th className="px-2 py-2 text-[12px]">Time</th>
                    <th className="px-2 py-2 text-[12px]">Signi</th>
                  </tr>
                </thead>
                <tbody>
                  {checked
                    ? leve1One &&
                      leve1One.map((level1, index) => (
                        <tr
                          onClick={(e) => LKADasha(level1)}
                          key={index}
                          className="selected cursor-pointer"
                          style={{
                            backgroundColor:
                              mahavalue1 === level1.Plane ? "#a6dda6" : "#fff",
                          }}
                        >
                          <td className="px-2 py-2 text-[12px]" key={index}>
                            {level1.Planetname}
                          </td>
                          <td className="px-2 py-2 text-[12px]">
                            {level1.StartDate}-{level1.EndDate}
                          </td>
                          <td className="px-2 py-2 text-[12px]">
                            {level1.Duration}
                          </td>
                          <td className="px-2 py-2 text-[12px]">
                            {level1.signiString}-{level1.nakstring}
                          </td>
                        </tr>
                      ))
                    : leve1One &&
                      leve1One.map((level1, index) =>
                        selectedvalue == index ? (
                          <tr
                            // onClick={(e) => {
                            //   antardiv(level1);
                            //   changeColor(e, index);
                            // }}
                            key={index}
                            onDoubleClick={(e) => openModal(level1)}
                            className="selected cursor-pointer"
                            style={{
                              backgroundColor:
                                selectedvalue === index
                                  ? "#a6dda6"
                                  : mahavalue1 === level1.Planet &&
                                    selectedvalue === -1
                                  ? "#a6dda6"
                                  : "#fff",
                            }}
                          >
                            <td key={index}>{level1.Planetname}</td>
                            <td>
                              {level1.StartDate}-{level1.EndDate}
                            </td>
                            <td>{level1.Duration}</td>
                            <td>
                              {level1.signiString}-{level1.nakstring}
                            </td>
                          </tr>
                        ) : (
                          <tr
                            onClick={(e) => {
                              antardiv(level1);
                              changeColor(e, index);
                            }}
                            key={index}
                            // onDoubleClick={(e) => openModal(level1)}
                            className="selected cursor-pointer"
                            style={{
                              backgroundColor:
                                selectedvalue === index
                                  ? "#a6dda6"
                                  : mahavalue1 === level1.Planet &&
                                    selectedvalue === -1
                                  ? "#a6dda6"
                                  : "#fff",
                            }}
                          >
                            <td key={index}>{level1.Planetname}</td>
                            <td>
                              {level1.StartDate}-{level1.EndDate}
                            </td>
                            <td>{level1.Duration}</td>
                            <td>
                              {level1.signiString}-{level1.nakstring}
                            </td>
                          </tr>
                        )
                      )}
                </tbody>
              </Table>

              {/*Antar dasha data Start==================================== */}
              {antardasha && (
                <Table
                  striped
                  bordered
                  hover
                  className="mahadasha-div mahadasha text-gray-900 bg-gray-100 h-full"
                  style={{ minWidth: "425px", maxWidth: "425px" }}
                >
                  <thead className="p-0  z-10">
                    <tr>
                      <th className="px-2 py-2 text-[12px]">Planet</th>
                      <th className="px-2 py-2 text-[12px]">Period</th>
                      <th className="px-2 py-2 text-[12px]">Time</th>
                      <th className="px-2 py-2 text-[12px]">Signi</th>
                    </tr>
                  </thead>
                  {console.log(selectedvalue, "selectedvalue")}
                  <tbody>
                    {antardasha &&
                      antardasha.map((antardasha1, index) =>
                        antrselectedvalue == index ? (
                          <tr
                            className="cursor-pointer"
                            // onClick={(e) => {
                            //   partyantarDiv(antardasha1, allData, index);
                            //   changeAntrColor(e, index);
                            // }}
                            key={index}
                            onDoubleClick={(e) => openAntarModal(antardasha1)}
                            style={{
                              backgroundColor:
                                antrselectedvalue === index
                                  ? "#a6dda6"
                                  : antvalue2 === antardasha1.Planet &&
                                    selectedvalue != -1 &&
                                    leve1One[selectedvalue].Planet ===
                                      mahavalue1 &&
                                    antrselectedvalue === -1
                                  ? "#a6dda6"
                                  : "#fff",
                            }}
                          >
                            <td key={index}>{antardasha1.PlanetName}</td>
                            <td>
                              {antardasha1.StartDate}-{antardasha1.EndDate}
                            </td>
                            <td>{antardasha1.Duration}</td>
                            <td>
                              {antardasha1.signiString}-{antardasha1.nakstring}
                            </td>
                          </tr>
                        ) : (
                          <tr
                            className="cursor-pointer"
                            onClick={(e) => {
                              partyantarDiv(antardasha1, allData, index);
                              changeAntrColor(e, index);
                            }}
                            key={index}
                            // onDoubleClick={(e) => openAntarModal(antardasha1)}
                            style={{
                              backgroundColor:
                                antrselectedvalue === index
                                  ? "#a6dda6"
                                  : antvalue2 === antardasha1.Planet &&
                                    selectedvalue != -1 &&
                                    leve1One[selectedvalue].Planet ===
                                      mahavalue1 &&
                                    antrselectedvalue === -1
                                  ? "#a6dda6"
                                  : "#fff",
                            }}
                          >
                            <td key={index}>{antardasha1.PlanetName}</td>
                            <td>
                              {antardasha1.StartDate}-{antardasha1.EndDate}
                            </td>
                            <td>{antardasha1.Duration}</td>
                            <td>
                              {antardasha1.signiString}-{antardasha1.nakstring}
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </Table>
              )}
              {/*Antar dasha data End==================================== */}
              {/*Partyantar dasha data =============================== */}
              {pratyantardasha && (
                <Table
                  striped
                  bordered
                  hover
                  className="mahadasha-div mahadasha text-gray-900 bg-gray-100 h-full"
                  style={{ minWidth: "425px", maxWidth: "425px" }}
                >
                  <thead className="p-0">
                    <tr>
                      <th className="px-2 py-2 text-[12px]">Planet</th>
                      <th className="px-2 py-2 text-[12px]">Period</th>
                      <th className="px-2 py-2 text-[12px]">Time</th>
                      <th className="px-2 py-2 text-[12px]">Signi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pratyantardasha &&
                      pratyantardasha.map((pratyantardasha1, index) =>
                        partselectedvalue == index ? (
                          <tr
                            className="cursor-pointer"
                            // onClick={(e) => {
                            //   shukshmDiv(pratyantardasha1, allData);
                            //   changePartColor(e, index);
                            // }}
                            key={index}
                            onDoubleClick={(e) =>
                              openPartModal(pratyantardasha1)
                            }
                            style={{
                              backgroundColor:
                                partselectedvalue === index
                                  ? "#a6dda6"
                                  : partvalue3 === pratyantardasha1.Planet &&
                                    selectedvalue != -1 &&
                                    leve1One[selectedvalue].Planet ===
                                      mahavalue1 &&
                                    partselectedvalue === -1
                                  ? "#a6dda6"
                                  : "#fff",
                            }}
                          >
                            <td>{pratyantardasha1.PlanetName}</td>
                            <td>
                              {pratyantardasha1.StartDate}-
                              {pratyantardasha1.EndDate}
                            </td>
                            <td>{pratyantardasha1.Duration}</td>
                            <td>
                              {pratyantardasha1.signiString}-
                              {pratyantardasha1.nakstring}
                            </td>
                          </tr>
                        ) : (
                          <tr
                            className="cursor-pointer"
                            onClick={(e) => {
                              shukshmDiv(pratyantardasha1, allData);
                              changePartColor(e, index);
                            }}
                            key={index}
                            // onDoubleClick={(e) => openPartModal(pratyantardasha1)}
                            style={{
                              backgroundColor:
                                partselectedvalue === index
                                  ? "#a6dda6"
                                  : partvalue3 === pratyantardasha1.Planet &&
                                    selectedvalue != -1 &&
                                    leve1One[selectedvalue].Planet ===
                                      mahavalue1 &&
                                    partselectedvalue === -1
                                  ? "#a6dda6"
                                  : "#fff",
                            }}
                          >
                            <td>{pratyantardasha1.PlanetName}</td>
                            <td>
                              {pratyantardasha1.StartDate}-
                              {pratyantardasha1.EndDate}
                            </td>
                            <td>{pratyantardasha1.Duration}</td>
                            <td>
                              {pratyantardasha1.signiString}-
                              {pratyantardasha1.nakstring}
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </Table>
              )}
              {/*Partyantar dasha data End =============================== */}
              {/*Shukshm dasha data =============================== */}
              {shuksmdasha && (
                <Table
                  striped
                  bordered
                  hover
                  className="mahadasha-div mahadasha text-gray-900 bg-gray-100 h-full"
                  style={{ minWidth: "425px", maxWidth: "425px" }}
                >
                  <thead className="p-0">
                    <tr>
                      <th className="px-2 py-2 text-[12px]">Planet</th>
                      <th className="px-2 py-2 text-[12px]">Period</th>
                      <th className="px-2 py-2 text-[12px]">Time</th>
                      <th className="px-2 py-2 text-[12px]">Signi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shuksmdasha &&
                      shuksmdasha.map((shuksmdasha1, index) => (
                        <tr
                          className="cursor-pointer"
                          onClick={(e) => changeShukshmColor(e, index)}
                          key={index}
                          style={{
                            backgroundColor:
                              sukshmselectedvalue === index
                                ? "#a6dda6"
                                : shukshmvalue3 === shuksmdasha1.Planet &&
                                  selectedvalue != -1 &&
                                  leve1One[selectedvalue].Planet ===
                                    mahavalue1 &&
                                  sukshmselectedvalue === -1
                                ? "#a6dda6"
                                : "#fff",
                          }}
                        >
                          <td>{shuksmdasha1.PlanetName}</td>
                          <td>
                            {shuksmdasha1.StartDate}-{shuksmdasha1.EndDate}
                          </td>
                          <td>{shuksmdasha1.Duration}</td>
                          <td>
                            {shuksmdasha1.signiString}-{shuksmdasha1.nakstring}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
              {/*Shukshm dasha data End =============================== */}
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tabId="3"
          style={{
            overflow: "overlay",
            height: `calc(${heightLeft - heightChart}px - 114px)`,
          }}
          className="overflow-y-auto horoscope-box overflow-x-auto"
        >
          <Row className="mx-0 px-0">
            <Col
              md="12"
              sm="12"
              className={lkdasha !== undefined ? `h-[15rem] px-0` : "px-0"}
            >
              <Table
                striped
                bordered
                hover
                className="chart-div mahadasha  text-gray-900 bg-gray-100 pr-0 cursor-pointer"
                style={{ position: "relative" }}
                width={"100%"}
              >
                <thead
                  className="p-0 position-sticky top-0 bg-gray-100 pr-0"
                  style={{ zIndex: "1" }}
                >
                  <tr>
                    <th className="px-2 py-2 text-[12px]">Planet</th>
                    <th className="px-2 py-2 text-[12px]">Start_Date</th>
                    <th className="px-2 py-2 text-[12px]">End_Date</th>
                    <th className="px-2 py-2 text-[12px]">Age</th>
                    <th className="px-2 py-2 text-[12px]"></th>
                  </tr>
                </thead>
                {lkdasha !== undefined && (
                  <tbody className="-z-[1]">
                    {lkdasha &&
                      lkdasha.map((lkdasha, index) => (
                        <tr
                          key={index}
                          className="selected"
                          style={{
                            backgroundColor:
                              mahavalue1 === lkdasha.Plane ? "#a6dda6" : "#fff",
                          }}
                        >
                          <td
                            key={index}
                            onDoubleClick={(e) => openModalVarsh(lkdasha)}
                          >
                            {lkdasha.Planet}
                          </td>
                          <td
                            key={index}
                            onDoubleClick={(e) => openModalVarsh(lkdasha)}
                          >
                            {lkdasha.Start_Date}
                          </td>
                          <td onDoubleClick={(e) => openModalVarsh(lkdasha)}>
                            {lkdasha.End_Date}
                          </td>
                          <td onDoubleClick={(e) => openModalVarsh(lkdasha)}>
                            {lkdasha.Age}
                          </td>
                          <td role="button" className="w-5">
                            <NavLink
                              className={classnames({
                                active: currentActiveTab === "1",
                              })}
                              onClick={(e) => LKADashaPopUp(lkdasha)}
                              style={{
                                borderRadius: "30px",
                                padding: "3px 8px",
                                fontSize: "12px",
                                margin: "1px 0",
                              }}
                            >
                              Varshfal
                            </NavLink>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </Table>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <Modal
        isOpen={modal}
        toggle={togglemodal}
        className="mahadasha-modal"
        size="lg"
      >
        <ModalHeader
          toggle={togglemodal}
          style={{ padding: "10px 15px", background: "#292f343d" }}
        >
          <h4 className="mb-0">
            {mahadashaheader ? (
              <>
                <strong>Planet :</strong>
                {mahadashaheader.Planetname
                  ? mahadashaheader.Planetname
                  : mahadashaheader.PlanetName}{" "}
                <strong>Start :</strong> {mahadashaheader.StartDate}{" "}
                <strong>End :</strong> {mahadashaheader.EndDate}
              </>
            ) : (
              ""
            )}
          </h4>
        </ModalHeader>

        {isLoading ? (
          <Threedotscomp />
        ) : (
          <ModalBody>
            <Row>
              <Col md="12">
                <ul>
                  {dashapred &&
                    dashapred.map((dasha) => (
                      <li>{Parser("" + dasha.prediction)}</li>
                    ))}
                </ul>
              </Col>
            </Row>
          </ModalBody>
        )}
      </Modal>

      <Modal
        isOpen={varshfalmodal}
        toggle={closeModalVarshfal}
        className="mahadasha-modal"
        size="lg"
      >
        <ModalHeader
          toggle={closeModalVarshfal}
          style={{ padding: "10px 15px", background: "#292f343d" }}
        >
          <h4 className="mb-0">
            {mahadashaheader ? (
              <>
                <strong>Planet :</strong>
                {mahadashaheader.Planet
                  ? mahadashaheader.Planet
                  : mahadashaheader.Planet}{" "}
                <strong>Start :</strong> {mahadashaheader.Start_Date}{" "}
                <strong>End :</strong> {mahadashaheader.End_Date}
              </>
            ) : (
              ""
            )}
          </h4>
        </ModalHeader>

        {isLoading ? (
          <Threedotscomp />
        ) : (
          <ModalBody>
            <Row>
              <Col md="12">
                <ul>
                  {dashapred &&
                    dashapred.map((dasha) => (
                      <li>{Parser("" + dasha.prediction)}</li>
                    ))}
                </ul>
              </Col>
            </Row>
          </ModalBody>
        )}
      </Modal>

      <Modal
        isOpen={khabarmodal}
        className="mahadasha-modal "
        // onRequestClose={closeModal}
        size="lg"
        toggle={closeModal}
      >
        <ModalHeader
          toggle={closeModal}
          style={{ padding: "10px 15px", background: "#292f343d" }}
        >
          <h4 className="mb-0">
            <strong>Planet :</strong> {lkachakarplanet} <strong>Start :</strong>{" "}
            {lkachakarend} <strong> End :</strong> {lkachakarstart}
          </h4>
        </ModalHeader>
        {isLoading ? (
          <Threedotscomp />
        ) : (
          <ModalBody>
            <Row>
              <Col md="12">
                <ul>
                  {khabardashapred &&
                    khabardashapred.map((khabar) => (
                      <div>
                        {khabar.heading ? (
                          <>
                            <h4>
                              <strong>{khabar.heading}</strong>
                            </h4>
                            <li>{Parser(khabar.remedy)}</li>
                          </>
                        ) : (
                          <li>{Parser(khabar.prediction)}</li>
                        )}
                      </div>
                    ))}
                </ul>
              </Col>
            </Row>
          </ModalBody>
        )}
      </Modal>

      <Modal
        isOpen={houseModal}
        className="mahadasha-modal"
        toggle={closeModal}
        size="lg"
      >
        <ModalHeader
          toggle={closeModal}
          style={{ padding: "10px 15px", background: "#292f343d" }}
        >
          <h4 className="mb-0">
            {rlnlsl ? (
              <>
                <strong>NL :</strong> {rlnlsl.NL} <strong>RL :</strong>{" "}
                {rlnlsl.RL} <strong>SL :</strong> {rlnlsl.SL}{" "}
                <strong>SSL :</strong>
                {rlnlsl.SSL}
              </>
            ) : (
              ""
            )}
          </h4>
        </ModalHeader>
        {isLoading ? (
          <Threedotscomp />
        ) : (
          <ModalBody>
            <Row>
              <Col md="12">
                <ul>
                  {housepred &&
                    housepred.map((housedata) => (
                      <div>
                        <li>{Parser(housedata.Pred_Hindi)}</li>
                      </div>
                    ))}
                </ul>
              </Col>
            </Row>
          </ModalBody>
        )}
      </Modal>

      <Modal
        isOpen={modal1}
        className="lkadasha-modal modal-lg"
        toggle={closeModal1}
        size="lg"
      >
        <ModalHeader toggle={closeModal1}>
          <h4>&nbsp;</h4>
        </ModalHeader>
        <ModalBody className="mt-0 pt-0">
          <div className="row">
            <div className="col-md-12">
              <table className="table chakartable">
                <thead>
                  <th>Planet</th>
                  <th>Antar</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Age</th>
                  <th></th>
                </thead>
                <tbody>
                  {/* {console.log(chakar, "chakar")} */}
                  {chakar &&
                    chakar.map((chakar35, index) => (
                      <tr>
                        <td>{chakar35.Planet}</td>
                        <td>{chakar35.Antar}</td>
                        <td>{chakar35.Start_Date}</td>
                        <td>{chakar35.End_Date}</td>
                        <td>{chakar35.Age}</td>
                        <td
                          className="flex justify-center mahadasha"
                          role="button"
                        >
                          <NavLink
                            key={index}
                            onClick={(e) => openModallka(chakar35)}
                            className={classnames({
                              active: currentActiveTab === "0",
                            })}
                            style={{
                              borderRadius: "30px",
                              padding: "3px 8px",
                              fontSize: "12px",
                              margin: "1px 0",
                              border: "1px solid #3358f4",
                              color: "#3358f4",
                            }}
                          >
                            {" "}
                            Varshfal
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Mahadasha;
