import React from 'react'
import Pagination from "react-bootstrap/Pagination";

const Paginations = ({
    pageNo,totalPages, maxPagesToShow = 10, setPageNo
}) => {
    const pages = [];
    // const maxPagesToShow = 10;
    const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    const handlePageChange = (newPage) => {
        setPageNo(newPage);
      };

    if (startPage > 1) {
      pages.push(<Pagination.Ellipsis />);
    }

    for (let i = startPage; i <= endPage; i++) {
      {
        pages.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageChange(i)}
            // disabled={i === pageNo}
            active={i === pageNo}
          >
            <span className='text-dark'>{i}</span>
          </Pagination.Item>
        );
      }
    }

    if (endPage < totalPages) {
      pages.push(<Pagination.Ellipsis />);
    }

    return (
      <Pagination>
        <Pagination.Prev
          onClick={() => handlePageChange(pageNo - 1)}
          disabled={pageNo === 1}
          className='bg-dark rounded-2xl text-white'
        />{" "}
        <span className='text-dark flex'>{pages}</span>
        <Pagination.Next
          onClick={() => handlePageChange(pageNo + 1)}
          disabled={pageNo === totalPages}
          className='bg-dark rounded-2xl text-white'
        />
      </Pagination>
    );
  };

  export default Paginations;
