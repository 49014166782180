import { SWITCH_THEME, SWITCH_COLOR } from "../actions/constants"

let initialState = {
    theme:{light: "white-content"},
    color:"blue"
}

export default function(state = initialState, action) {
    switch(action.type){
        case SWITCH_THEME:     
            state.theme = action.theme    
            return {...state}
        case SWITCH_COLOR:     
            state.color = action.color    
            return {...state}
            
        default:
            return state
    }
}




