import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Badge } from "reactstrap";

const ProfileIconMobile = ({ imageUrl, altText, notifications, logout }) => (
  <div className="">
    <div
      style={{
        position: "relative",
        display: "inline-block",
        paddingTop: "6px ",
      }}
    >
      <img
        src={imageUrl}
        alt={altText}
        style={{
          width: "70px",
          height: "70px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
        className="border-gray-950 bg-slate-600"
      />
      {/* <div
        style={{
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          position: "absolute",
          right: 0,
          bottom: 2,
          cursor: "pointer",
        }}
        className="bg-white"
      >
        <Link
          to="acharya/myprofile"
          className="leading-none p-1 m-0 flex items-center justify-center"
        >
          <i className="fas fa-pencil-alt hover:text-purple-600"></i>
        </Link>
      </div> */}

      {notifications > 0 && (
        <Badge
          color="danger"
          pill
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            transform: "translate(25%, -25%)",
          }}
        >
          {notifications}
        </Badge>
      )}
    </div>
  </div>
);

export default ProfileIconMobile;
