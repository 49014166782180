import axios from "axios";
import NotificationDisplay from "components/Dashboard/NotificationDisplay";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Container,
  FormGroup,
  Input,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { CListGroup } from "@coreui/react";
import { Threedotscomp } from "components/Spinner/Threedotscomp";
import { Link } from "react-router-dom";
import UpgradePlan from "./UpgradePlan";
import Loadingspinner from "components/Spinner/Loadingspinner";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_EXPIRED } from "redux/actions/constants";
import { updateExpire } from "redux/actions/updateExpire";
import { ThreeDots } from "react-loader-spinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logout } from "redux/actions/auth";
import { checkplan } from "redux/actions/plan";
const r_key_id = process.env.REACT_APP_CHECK_R_KEY_ID;
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
const RECHARGE_API = process.env.REACT_APP_RECHARGE_API;
const PLANAPI = process.env.REACT_APP_PLAN_API;

const Rechargecredit = () => {
  const history = useHistory();
  const [firstVal, setFirstVal] = useState(1000);
  const [secondVal, setSecondVal] = useState(0);
  const [minimumRecharge, setMinimumRecharge] = useState("");
  const [email, setEmail] = useState();
  const [allrecharge, setRechargevalu] = useState([]);
  const [selected, getSelected] = useState(1000);
  const [plans, setPlans] = useState([]);
  // const [isValidplan, setIsValidPlan] = useState(false);
  const [update, setUpdate] = useState([]);
  const notificationAlertRef = React.useRef(null);
  // const [planStatus, setPlanStatus] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setpageIndex] = useState(0);
  // const [planBtn, setplanBtn] = useState("");
  

  const dispatch = useDispatch();

  const getEmail = async () => {
    let userDetails = JSON.parse(localStorage.getItem("user"));
    //console.log("User Details====",userDetails);
    let email = userDetails.email;
    //console.log("Email=====",email);
    setEmail(email);
  };

  const initPayment = async (data, plan_id) => {
    setIsLoading(true);
    let notifyMessage = "Plan renewed successfully.";
    let body = data;
    //console.log("Body from initaiate payment",body);
    let userDetails = JSON.parse(localStorage.getItem("user"));
    //console.log("User Email======",userDetails.email)
    // const inObject = {
    //         amount : parseInt((body.amount) / 100),
    //         orderid: body.id,
    //         email:userDetails.email,
    // }
    //console.log("inObject inObject ======:",inObject);
    //let inserttopup = await axios.post(`${BAS_URL_APPAPI}payment/storedbeforeverify`, inObject);
    //console.log("inserttopupinserttopupinserttopupinserttopup==",inserttopup);
    const options = {
      key_id: r_key_id,
      credit: parseInt(firstVal),
      amount: 100 * secondVal,
      currency: data.currency,
      order_id: data.id,
      modal: {
        ondismiss: function () {
          setIsLoading(false);
        },
      },
      handler: async (response) => {
        const dataObj = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          userDetails: userDetails,
          schemecode: allrecharge.find(
            (value) => value.Selling_Price == selected
          ),
          email: email,
          price: firstVal,
          credit: firstVal,
          usertype: "acharya",
          plan_id: plan_id,
          //insertedId:inserttopup.iinsertId
        };
        //console.log("")
        console.log("dataObjdataObjdataObjdataObj === before", dataObj);
        const { data } = await axios.post(
          `${BAS_URL_APPAPI}payment/verifySubs`,
          dataObj
        );
        if (data.message === "Payment verified successfully") {
          let options = {};
          options = {
            place: "tc",
            message: notifyMessage,
            type: "primary",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
          };
          // getSubscription();
          dispatch(checkplan())
          setIsLoading(false);
          dispatch(updateExpire(true));
          notificationAlertRef.current.notificationAlert(options);
        } else {
          let options = {};
          options = {
            place: "tc",
            message: notifyMessage,
            type: "primary",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
          };
          notificationAlertRef.current.notificationAlert(options);
          alert("Something went wrong");
          setIsLoading(false);
        }
        console.log("Data =============", data);
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const rechargeSubsHandler = async (e, price, p_id) => {
    try {
      setIsLoading(true);
      console.log(e);
      const API_URL = `${BAS_URL_APPAPI}payment/rechargeSubs`;
      e.preventDefault();
      // const orderUrl = `${API_URL}rechargecredit`;
      // console.log("orderUrlorderUrlorderUrl  :==", orderUrl);
      const { data } = await axios.post(API_URL, {
        email: email,
        amount: price,
        plan_id: p_id,
      });
      console.log("data rechargeSubsHandler", data);
      initPayment(data.data, p_id);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const Recharges = async () => {
    setIsLoading(true);
    // console.log("entered");
    try {
      const res = await axios.get(`${RECHARGE_API}`);
      // console.log(res, "my plan response");
      setPlans(res.data.data);
      setIsLoading(false);
    } catch (err) {
      {
        let notifyMessage = "Unable to Fetch Data";
        let optiondata = {};
        optiondata = {
          place: "tc",
          message: notifyMessage,
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(optiondata);
      }
    }
  };

  const UpdatePlans = async () => {
    // console.log("entered");
    try {
      const res = await axios.get(`${PLANAPI}`);
      console.log(res.data.data, "my plan response");
      setUpdate(res.data.data);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const currentPlan = useSelector(e => e.plan)
  const isValidplan = currentPlan.subs_plan;
  const planStatus = currentPlan.plan_status
  const planBtn = currentPlan.reg_plan

  console.log(currentPlan,"currentPlan")

  // const getSubscription = async () => {
  //   try {
  //     let acharya = localStorage.getItem("user");
  //     const userDetail = JSON.parse(acharya);
  //     const acharyaid = userDetail.id;
  //     const body = {
  //       id: acharyaid,
  //     };
  //     // //console.log("Acharya Id==============",acharyaid) ;
  //     let result = await axios.post(
  //       `${BAS_URL_APPAPI}acharya/acharyaval`,
  //       body
  //     );
  //     setIsValidPlan(result.data.data.subs_plan);
  //     let planStatus = result.data.data.plan_status;
  //     setPlanStatus(planStatus);
  //     let regStatus = result.data.data.reg_plan;
  //     setplanBtn(regStatus);
  //     // console.log(result, "result");
  //   } catch (error) {
  //     let data = localStorage.getItem("user");
  //     let finalData = JSON.parse(data);
  //     let token = localStorage.getItem("token");
  //     if (error.response.data.messages == "NOT_A_USER") {
  //       const obj = {
  //         id: finalData.id,
  //         token: token,
  //       };
  //       // console.log(error, finalData.id, token, obj, "JJJJJJJJ")
  //       try{
  //       const result = await axios.post(`${BAS_URL_APPAPI}acharya/logout`, obj);
  //       }catch(err){
  //         if (error.response.data.messages == "NOT_A_USER") {
  //           dispatch(logout());
  //         }
  //       }
  //     }
  //   }
  // };

  // console.log(planBtn, "planBtn");

  useEffect(() => {
    getEmail();
    Recharges();
    UpdatePlans();
  }, []);

  useEffect(()=>{
   dispatch(checkplan())
  },[])

  // console.log(plans, "plans");

  const notify = () => {
    let options = {};
    options = {
      place: "tc",
      message: `Your plan ${
        planBtn == 2 ? "changed" : "upgraded"
      } successfully`,
      type: "primary",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
    // getSubscription();
    dispatch(checkplan())
    setpageIndex(0);
  };

  const myplan = plans.find(
    (e) => e?.mapped_subscription_plan_id == isValidplan
  );

  // console.log(plans, "myplan");

  return (
    <>
      <div className="content relative">
        <NotificationAlert ref={notificationAlertRef} />{" "}
        <Row>
          <Col>
            <Card className="mb-1">
              <CardBody>
                <ButtonGroup
                  className="btn-group-toggle d-flex"
                  data-toggle="buttons"
                  style={{ gap: 3 }}
                >
                  <Row>
                    <Col md="12">
                      <Button
                        className="btn"
                        style={{
                          height: "40px",
                          alignContent: "center",
                          padding: "5px 16px",
                          marginTop: "0px",
                          fontSize: "12px",
                          color: "#1d8cf8",
                          borderColor: "#1d8cf8",
                          background: pageIndex == 1 ? "transparent" : "",
                          border: "1px solid",
                          borderRadius: "4px",
                        }}
                        onClick={() => setpageIndex(0)}
                      >
                        <strong> Current Plan</strong>
                      </Button>
                      {console.log(planBtn,"planBtn")}
                      {planBtn == 1 && (
                        <Button
                          className="btn"
                          style={{
                            height: "40px",
                            alignContent: "center",
                            padding: "5px 16px",
                            marginTop: "0px",
                            fontSize: "12px",
                            color: "#1d8cf8",
                            borderColor: "#1d8cf8",
                            background: pageIndex == 0 ? "transparent" : "",
                            border: "1px solid",
                            borderRadius: "4px",
                          }}
                          onClick={() => setpageIndex(1)}
                        >
                          Upgrade Plan
                        </Button>
                      )}
                    </Col>
                  </Row>
                </ButtonGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {pageIndex === 0 ? (
          isLoading ? (
            <div className="bg-[#fff]">
              <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#1d8cf8"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
              />
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <div className="flex flex-wrap items-center justify-center">
                  <div className="flex-shrink-0 m-6 relative rounded-lg max-w-xs shadow-lg py-0">
                    {myplan && (
                      <>
                        <div className="m-0 border bg-gradient-to-r from-violet-200 to-pink-100 rounded-lg bg-pink  position-relative overflow-hidden">
                          <svg
                            className="absolute bottom-0 left-0 mb-8"
                            viewBox="0 0 375 283"
                            fill="none"
                            style={{
                              transform: "scale(1.5)",
                              opacity: "0.1",
                            }}
                          >
                            <rect
                              x="159.52"
                              y="175"
                              width="152"
                              height="152"
                              rx="8"
                              transform="rotate(-45 159.52 175)"
                              fill="white"
                            />
                            <rect
                              y="107.48"
                              width="152"
                              height="152"
                              rx="8"
                              transform="rotate(-45 0 107.48)"
                              fill="white"
                            />
                          </svg>
                          <div className="relative text-white pt-3 pb-6 mt-6 justify-between align-center">
                            <div className="flex justify-center item-center mb-0 py-3">
                              <span className="block font-semibold text-xl">
                                <h2 className="text-center text-dark">
                                  {myplan && myplan?.name}
                                </h2>
                              </span>
                            </div>
                          </div>
                          <div className="relative px-10 flex items-start justify-start flex-col h-60">
                            {myplan && myplan?.features.map((el, index) => (
                              <span
                                style={{
                                  display: "flex",
                                  gap: 3,
                                  color: "#",
                                  padding: "5px 0",
                                }}
                                className="rounded-full"
                              >
                                <i
                                  key={index}
                                  className="fa fa-check-circle success"
                                  aria-hidden="true"
                                  style={{ marginTop: "6px" }}
                                ></i>
                                &nbsp;
                                <li
                                  style={{
                                    listStyle: "",
                                    color: "#000",
                                    listStyleType: "none",
                                  }}
                                >
                                  {" "}
                                  <p
                                    style={{
                                      color: "#000",
                                      lineHeight: "25px",
                                    }}
                                  >
                                    {" "}
                                    {el.description}
                                  </p>
                                </li>
                              </span>
                            ))}
                          </div>
                          <div className="relative text-white px-6 pb-6 mt-6 justify-around align-center flex flex-col">
                            <div className="flex justify-center pb-2">
                              <span className="block bg-white rounded-full text-orange-500 text-lg font-bold px-3 py-2 leading-none flex items-center">
                                ₹{myplan?.price}/-
                              </span>
                            </div>
                            {planStatus === 0 && (
                              <div className="flex justify-center pb-2">
                                <span
                                  role="button"
                                  onClick={(a) =>
                                    rechargeSubsHandler(
                                      a,
                                      myplan?.price,
                                      myplan?.id
                                    )
                                  }
                                  className="w-3/4 block bg-[#344675] rounded-full text-white-500 text-lg font-bold px-3 py-2 leading-none flex items-center justify-around hover:text-[#344675] hover:bg-white"
                                >
                                  Renew
                                </span>
                              </div>
                            )}
                            {planStatus === 1 && (
                              <div className="flex justify-center pb-2">
                                <span className="w-3/4 block bg-teal-500 rounded-full text-white-500 text-lg font-bold px-3 py-2 leading-none flex items-center justify-around">
                                  Active
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )
        ) : pageIndex === 1 ? (
          <UpgradePlan notify={() => notify()} setpageIndex={setpageIndex} currentPlan={myplan.price} />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Rechargecredit;
