import axios from "axios";
import NotificationDisplay from "components/Dashboard/NotificationDisplay";
import React, { useEffect, useState, memo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Logo from "../../assets/img/logo.png";
import { Link, Redirect } from "react-router-dom";
import { Threedotscomp } from "components/Spinner/Threedotscomp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  FormGroup,
  Input,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Header from "components/Header";
const r_key_id = process.env.REACT_APP_CHECK_R_KEY_ID;
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
const PLANAPI = process.env.REACT_APP_PLAN_API;

const RechargeReg = ({ paymentHandler, state, uri = "/Login", uri_name = "Back to Login" }) => {
  const history = useHistory();
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const Plans = async () => {
    setIsLoading(true);
    console.log("entered");
    try {
      const res = await axios.get(`${PLANAPI}`);
      // console.log(res, "my plan response");
      setPlans(res.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err, "err");
      setIsLoading(true);
    }
  };

  useEffect(() => {
    Plans();
  }, []);

  const handlePlans = (e) => {
    paymentHandler(e);
    state(false);
  };

  // console.log(selected, "plans selected")

  return (
    <>
      {isLoading ? (
        <Threedotscomp />
      ) : (
        <>
          <div
            className="container content-registration py-0 m-0 z-2 justify-center flex w-full"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="row p-12 flex flex-wrap items-center justify-center position-relative overflow-hidden w-full py-32">
              {plans.map((e, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 m-6 relative rounded-lg max-w-xs shadow-lg py-0 hover:scale-105 cursor-pointer"
                  role="button"
                  onClick={() => handlePlans(e)}
                >
                  {e.mapped_subscription_plan_id == 4 && (
                    <div
                      style={{
                        position: "absolute",
                        background: "transparent",
                        height: "140px",
                        width: "150px",
                        left: "-10px",
                        top: "-10px",
                        overflow: "hidden",
                        zIndex: "2",
                      }}
                      className=""
                    >
                      <div
                        style={{
                          position: "absolute",
                          background: "#344675",
                          height: "30px",
                          width: "20px",
                          left: "-10px",
                          bottom: "0",
                          overflow: "hidden",
                          zIndex: "1",
                        }}
                        className="bg-gradient-to-r from-slate-700 to-slate-900"
                      ></div>
                      <div
                        style={{
                          position: "absolute",
                          background: "#344675",
                          height: "10px",
                          width: "30px",
                          right: "32px",
                          top: "0",
                          overflow: "hidden",
                          zIndex: "1",
                        }}
                        className="bg-gradient-to-r from-slate-900 to-slate-700"
                      ></div>
                      <div
                        style={{
                          position: "inherit",
                          height: "30px",
                          width: "200%",
                          left: "-100px",
                          rotate: "310Deg",
                          top: "42px",
                          zIndex: "1",
                          color: "#ffffff",
                          textAlign: "center",
                          justifyItems: "center",
                          justifyItems: "center",
                          lineHeight: "2.2",
                          boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 5px",
                        }}
                        className="bg-gradient-to-r from-red-500 to-orange-500"
                      >
                        Top Choice
                      </div>
                    </div>
                  )}
                  <svg
                    className="absolute bottom-0 left-0 mb-8"
                    viewBox="0 0 375 283"
                    fill="none"
                    style={{ transform: "scale(1.5)", opacity: "0.1" }}
                  >
                    <rect
                      x="159.52"
                      y="175"
                      width="152"
                      height="152"
                      rx="8"
                      transform="rotate(-45 159.52 175)"
                      fill="white"
                    />
                    <rect
                      y="107.48"
                      width="152"
                      height="152"
                      rx="8"
                      transform="rotate(-45 0 107.48)"
                      fill="white"
                    />
                  </svg>
                  <div className="m-0 border bg-gradient-to-r from-violet-200 to-pink-100 rounded-lg bg-pink">
                    <div className="relative text-white pt-3 pb-6 mt-6 justify-between align-center">
                      <div className="flex justify-center item-center mb-0 py-3">
                        <span className="block font-semibold text-xl">
                          <h2 className="text-center text-dark">{e.name}</h2>
                        </span>
                      </div>
                    </div>
                    <div className="relative px-10 flex items-start justify-start flex-col h-60">
                      {e.features.map((el, index) => (
                        <span
                          style={{
                            display: "flex",
                            gap: 3,
                            color: "#",
                            padding: "5px 0",
                          }}
                          className="rounded-full"
                        >
                          <i
                            key={index}
                            className="fa fa-check-circle success"
                            aria-hidden="true"
                            style={{ marginTop: "6px" }}
                          ></i>
                          &nbsp;
                          <li
                            style={{
                              listStyle: "",
                              color: "#000",
                              listStyleType: "none",
                            }}
                          >
                            {" "}
                            <p
                              style={{
                                color: "#000",
                                lineHeight: "25px",
                              }}
                            >
                              {" "}
                              {el.description}
                            </p>
                          </li>
                        </span>
                      ))}
                    </div>
                    <div className="relative text-white px-6 pb-6 mt-6 justify-between align-center">
                      <div className="flex justify-center pb-2">
                        <span className="block bg-white rounded-full text-orange-500 text-lg font-bold px-3 py-2 leading-none flex items-center">
                          ₹{e.price}/-
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="text-center w-full py-8">
                <Link
                  to={uri}
                  className="text-blue-500 hover:text-blue-700 font-semibold"
                >
                  {uri_name}
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(RechargeReg);
