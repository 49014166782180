import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
} from "reactstrap";
import Pagination from "react-bootstrap/Pagination";
import ReactTable from "components/ReactTable/ReactTable.js";
import axios from "axios";
import moment from "moment/moment";
import NotificationAlert from "react-notification-alert";
import { logout } from "redux/actions/auth";
import { useDispatch } from "react-redux";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const PaidKundali = () => {
  const [paidkundali, setPaidkundali] = useState([]);
  let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [totalpages, setTotalpage] = useState(0);
  const [name, setSearchName] = useState("");
  const notificationAlertRef = React.useRef(null);
  const token = localStorage.getItem("token");
  const acharya = localStorage.getItem("user");

  const dispatch = useDispatch();

  const paidkundaliData = async (validation) => {
    if ((!endDate || !startDate) && validation) {
      let notifyMessage = "Please select a valid date!";
      let optiondata = {};
      optiondata = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(optiondata);
      return;
    }
    try {
      const userDetail = JSON.parse(acharya);
      const id = userDetail.id;
      startDate = "";
      endDate = "";
      const paidkundalidata1 = await axios.get(
        `${BAS_URL_APPAPI}acharya/paidkundli?id=${id}&sdate=${startDate}&edate=${endDate}`,
        {
          headers: { Authorization: token },
          body: { id: id, size: pagesize, pageNo: page },
        }
      );

      setStartDate("");
      setEndDate("");
      setPage(1);
      setTotalpage(Math.round(paidkundalidata1.data.data / 10));

      let data = paidkundalidata1.data.data;
      let modedDate = data.map((val, ind) => {
        return {
          ...val,
          language:
            val.language == "1"
              ? "Hindi"
              : val.language == "15"
              ? "English"
              : val.language == "2"
              ? "Marathi"
              : val.language == "3"
              ? "Punjabi"
              : "Tamil",
        };
      });
      setPaidkundali(modedDate);
      console.log(data, "geee");
    } catch (error) {
      let notifyMessage = "Unable to Fetch Data";
      let optiondata = {};
      optiondata = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(optiondata);
      if (error.response.data.messages == "NOT_A_USER") {
        dispatch(logout());
      }
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault(); // 👈️ prevent page refresh
    if (!endDate || !startDate) {
      let notifyMessage = "Please select a valid date!";
      let optiondata = {};
      optiondata = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(optiondata);
      return;
    }
    if (new Date(endDate) < new Date(startDate)) {
      alert("End date should be greater than start date!");
      return;
    }
    try {
      const userDetail = JSON.parse(acharya);
      const id = userDetail.id;
      let body = {
        name,
        sdate: startDate,
        enddate: endDate,
        pageNo: 1,
        size: pagesize,
      };
      console.log("!@#$%^&*(123456768", id, userDetail);
      console.log("BODY BODY BODY", body);
      const paidkundalidata1 = await axios.get(
        `${BAS_URL_APPAPI}acharya/paidkundli?id=${id}&sdate=${startDate}&edate=${endDate}&page=${page}&pageSize=${pagesize}`,
        {
          headers: { Authorization: token },
          body: { id: id, size: pagesize, pageNo: page },
        }
      );

      console.log("PAID KUNDALI", paidkundalidata1);
      setPaidkundali(paidkundalidata1.data.data);
      setTotalpage(Math.round(paidkundalidata1.data.data / 10));
    } catch (error) {
      let notifyMessage = "Unable to Fetch Data";
      let optiondata = {};
      optiondata = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(optiondata);
    }
  };
  let active = page;
  let items = [];
  let ecliptsarr = [];
  for (let number = 1; number <= totalpages; number++) {
    if (number < 10) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          value={number}
          onClick={(e) => setPageNumber(number)}
        >
          {number}
        </Pagination.Item>
      );
    } else if (number === 10) {
      items.push(<Pagination.Ellipsis />);
    }
  }
  const setPageNumber = (number) => {
    console.log("dskfjfls Number", number);
    setPage(number);
    setPageSize(10);
    paidkundaliData(false, pagesize, page);
  };


  useEffect(() => {
    paidkundaliData(false);
  }, []);

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlertRef} />
        <Row className="mb-3 mt-1">
          <Col xs={12} md={12}>
            <Card className="mb-1">
              <CardBody className="mt-0">
                <Form onSubmit={(e) => onSubmit(e)}>
                  <div className="mb-0">
                    <FormGroup
                      className={window.innerWidth < 800 ? "" : "flex-search"}
                    >
                      <div className={window.innerWidth < 800 ? "" : "exist"}>
                        <div className="mr-2 mt-[7px]">
                          <strong className="text-sm">FROM</strong>{" "}
                        </div>
                        <div className="mr-1">
                          <Input
                            type="date"
                            value={startDate}
                            onChange={(e) =>
                              setStartDate(e.target.value ? e.target.value : "")
                            }
                            className="mb-0"
                          />
                        </div>
                        <div className="mr-2 mt-[7px]">
                          <strong className="text-sm">TO</strong>{" "}
                        </div>
                        <div className="mr-1">
                          <Input
                            type="date"
                            value={endDate}
                            onChange={(e) =>
                              setEndDate(e.target.value ? e.target.value : "")
                            }
                            className="mb-0 mr-2"
                          />
                        </div>
                      </div>
                      <div className="flex-search mt-1">
                        <div>
                          <Button
                            variant="primary"
                            type="submit"
                            className="mt-0 btn"
                            style={{ padding: "10px 30px" }}
                          >
                            Filter
                          </Button>
                        </div>
                        <div>
                          <Button
                            variant="primary"
                            onClick={(e) => paidkundaliData(e)}
                            className="mt-0 submitbtn"
                            style={{ padding: "10px 28px" }}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardBody>
                <ReactTable
                  data={paidkundali}
                  columns={[
                    {
                      Header: "Id",
                      accessor: "id",
                    },
                    {
                      Header: "Name",
                      accessor: "name",
                    },
                    {
                      Header: "Product Name",
                      accessor: "product_name",
                    },
                    {
                      Header: "Gender",
                      accessor: "gender",
                    },
                    {
                      Header: "D.O.B",
                      accessor: "dob",
                    },
                    {
                      Header: "T.O.B",
                      accessor: "tob",
                    },
                    {
                      Header: "P.O.B",
                      accessor: "pob",
                    },
                    {
                      Header: "Language",
                      accessor: "language",
                    },
                    {
                      Header: "VCN",
                      accessor: "vcn",
                    },
                    {
                      Header: "Date",
                      accessor: (row) => (
                        <div>
                          {moment(row.generatedate)
                            .utc()
                            .format("DD/MM/YY hh:mm a")}{" "}
                        </div>
                      ),
                    },
                    {
                      Header: "Download",
                      accessor: (row) =>
                        row.pdflink ? (
                          <a
                            href={row.pdflink}
                            pathname=""
                            className="btn btn-sm"
                            target="_blank"
                            color={"primary"}
                            style={{ padding: "0px 10px" }}
                          >
                            <i className="fa fa-download"></i>
                          </a>
                        ) : (
                          "No PDF"
                        ),
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PaidKundali;
