import * as React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

export default function Menu({ img, logout }) {
  return (
    <UncontrolledDropdown setActiveFromChild style={{ zIndex: "9999999" }}>
      <DropdownToggle caret className="nav-link" tag="a">
        {img}
      </DropdownToggle>
      <DropdownMenu className="max-lg:hidden top-0" right tag="ul">
        <Link to="acharya/myprofile" className="text-gray-950">
          <DropdownItem style={{ color: "#000" }}>My Profile</DropdownItem>
        </Link>
        <Link className="text-gray-950 " to="/login">
          <DropdownItem style={{ color: "#000" }}>Dashbord</DropdownItem>
        </Link>
        <Link className="text-gray-950" onClick={() => logout()}>
          <DropdownItem style={{ color: "#000" }}>Logout</DropdownItem>
        </Link>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
