import { useEffect, forwardRef, useState, useRef } from "react";
import logo from "../../assets/img/logo.png";
import Vector1 from "../../assets/landing/img/Vector1.svg";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Footer = () => {
  const [height, setHeight] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    if (sectionRef.current) {
      setHeight(sectionRef.current.offsetHeight);
    }
  }, []);

  // console.log(height, "height");

  return (
    <>
      <section
        style={{
          height: height,
          zIndex: "-20",
          position: "absolute",
          width: "100%",
        }}
        className="max-md:hidden"
      ></section>
      <footer
        className="cs_site_footer cs_color_1 cs_sticky_footer"
        style={{ zIndex: "-1" }}
        ref={sectionRef}
      >
        <div className="cs_footer_shape1">
          <img src={Vector1} alt="Vector-Icon" />
        </div>
        <div className="cs_height_140 cs_height_lg_70"></div>
        <div className="cs_main_footer">
          <div className="container-custom">
            <div className="row">
              <div className="col-lg-3 col-xl-3">
                <div className="cs_footer_widget">
                  <div className="cs_text_field">
                    {/* <Link to="/home">
                      <img src={logo} alt="Logo" className="cs_footer_logo" />
                    </Link> */}
                       <h2 className="cs_footer_widget_title">Company</h2>
                    <address className="cs_text_white mb-0">
                      Deep TechnoServices LLP
                      <br />2<sup>nd</sup> Floor, C-97, Mckenzie Tower,
                      <br/>Phase-2, Mayapuri, West Delhi
                      <br/>Pin: 110064<br/>Email: care@astroscience.in<br/>Contact Number:
                      +91-9821397676
                    </address>
                  </div>
                </div>
                {/* <div className="cs_footer_widget">
                  <div className="cs_social_btn cs_style_1 d-flex">
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                </div> */}
                <div className="cs_height_0 cs_height_lg_30"></div>
              </div>
              <div className="col-lg-2 col-xl-2 offset-lg-1">
                <div className="cs_footer_widget">
                  <h2 className="cs_footer_widget_title">Quick Links</h2>
                  <ul className="cs_footer_widget_nav cs_mp0">
                    <li>
                      <Link to="/terms_and_conditions">
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/product_services">Products and Services</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                <div className="cs_height_0 cs_height_lg_30"></div>
              </div>

              <div className="col-xl-2 col-lg-2">
                <div className="cs_footer_widget">
                  <h2 className="cs_footer_widget_title">Policies</h2>
                  <ul className="cs_footer_widget_nav cs_mp0">
                    <li>
                      <Link to="/return_and_refunds">Return and Refund</Link>
                    </li>
                    {/* <li>
                    <Link to="/shipping_and_delivery">Shipping policy</Link>
                    </li> */}
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
                <div className="cs_height_0 cs_height_lg_30"></div>
              </div>
              <div className="col-lg-4 col-xl-3 offset-xl-1">
                <div className="cs_footer_widget">
                  <h2 className="cs_footer_widget_title">Our Location</h2>
                  <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1751.0979257942206!2d77.11930573604047!3d28.623891697736212!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d034d95555555%3A0x3206a85813dd6260!2sDeep%20Techno%20Services%20LLP!5e0!3m2!1sen!2sin!4v1723530693252!5m2!1sen!2sin"
                    // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2743.1922157410683!2d77.1188014!3d28.6239455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1950073e89cb%3A0x8e5be97a1299b481!2sGD%20Vashist%20Jyotish%20Sansthan!5e0!3m2!1sen!2sin!4v1647756501715!5m2!1sen!2sin"
                    width="100%"
                    height="150"
                    style={{ border: 0, borderRadius: "5px" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="cs_height_110 cs_height_lg_50"></div>
          </div>
          <div className="container-custom cs_copyright_text cs_text_white text-center">
            © 2024 Reserved by
            <p className="cs_site_link inline">
              {" "}
              <a href="https://www.deeptechnoservices.com/" target="_blank"><span className="text-[#e14eca]">Deep TechnoServices LLP</span></a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
