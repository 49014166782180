import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

export const Threedotscomp = () => {
  return (
    <div className="loaderdiv" >
        <ThreeDots height="80" width="80"  radius="9" color="#1d8cf8" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
    </div>
  )
}
