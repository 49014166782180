import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  RESET_STORE,
} from "../actions/constants";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  loading: true,
  loginFailed: false,
  err: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        //user: payload
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      console.log(payload.token);
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loginFailed: false,
        loading: false,
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
      return {
        ...state,
        ...payload,
        loginFailed: true,
        loading: false,
        err:payload,
      };
    case LOGOUT:
      localStorage.clear();
      return {
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null
      };
    case RESET_STORE:
      {
        state = initialState;
      }
      break;
    default:
      return state;
  }
}
