import moment from "moment";
import axios from "axios";
import { Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import Loadingspinner from "../../components/Spinner/Loadingspinner";
// import exportFromJSON from "export-from-json";
import Paginations from "../components/Pagination";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Crm from "./Crm";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const TopUp = () => {
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [email, setEmail] = useState("");
  const [emailText, setEmailText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [source, setSource] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [data, setData] = useState([]);
  const [totalPages, setTotalpage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const history = useHistory();

  let localData = localStorage.getItem("crm-agent");
  let crm_agent = JSON.parse(localData);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    // setIsLoading(true);
    try {
      const response = await axios.post(
        `${BAS_URL_APPAPI}admin/allrechargeHistoryForAgent`,
        {
          pageNo,
          size: pageSize,
          email,
          startDate: (startDate + " " + startTime).trim(),
          endDate: (endDate + " " + endTime).trim(),
          source,
          transactionId,
          agent:crm_agent
        }
      );
      if (response.data.success === 1) {
        setData(response.data.data.record);
        const totalPages = Math.ceil(response.data.data.totalRecord / pageSize);
        setTotalpage(totalPages);
      } else if (response.data.success === 0) {
        console.log(response.data.success, "response.data.status");
        // dispatch(logout());
      }
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(true);
      console.error("Error fetching data:", error);
    }
  };

  const HandleEmail = (e) => {
    const text = e.target.value;
    // setEmailText(text);
    // if (e.target.value.includes("@")) {
    setEmail(text);
    // } else {
    //   setEmail("");
    // }
  };

  const resetPage = () => {
    setPageNo(1);
    setPageSize(10);
    setEmail("");
    setStartDate("");
    setStartTime("");
    setEndDate("");
    setEndTime("");
    setSource("");
    setTransactionId("");
  };

  const formatDateString = (dateString) => {
    let fetchDate = new Date(dateString);
    return fetchDate.toLocaleDateString("en-Us", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const exportType = "xls";

  const getExcel = async (event, p, s) => {
    if (event) {
      event.preventDefault();
    } // 👈️ prevent page refresh
    if (startDate.trim() === "" || endDate.trim() === "") {
      alert("Select date to export recharge data");
      return;
    } else if (
      new Date(endDate + " " + endTime) < new Date(startDate + " " + startTime)
    ) {
      alert("End date should be greater than start date!");
      return;
    }
    try {
      setIsLoading(true);

      let body = {
        pageNo,
        size: pageSize,
        email,
        startDate: (startDate + " " + startTime).trim(),
        endDate: (endDate + " " + endTime).trim(),
        source,
        transactionId,
        excel: true,
      };
      const response = await axios.post(
        `${BAS_URL_APPAPI}admin/allrechargeHistoryForAgent`,
        body
      );
      setIsLoading(false);
      console.log(
        "topupdatatopupdatatopupdata",
        Array.isArray(response.data.data.record)
      );
      let data = response.data.data.record.map((item) => ({
        ...item,
        date: formatDateString(item.date),
      }));

      let fileName = "topup";
      // exportFromJSON({ data, fileName, exportType, formatDateString });
      // exportTOExcel(response.data.data.record,"topup")
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const timer = setTimeout(() => {
      fetchData();
    }, 500); // Adjust the delay as needed (500ms in this case)

    setDebounceTimer(timer);

    return () => {
      clearTimeout(timer);
    };
  }, [
    pageNo,
    pageSize,
    email,
    startDate,
    startTime,
    endDate,
    endTime,
    source,
    transactionId,
  ]);

  return (
    <>
      <Crm uri_name={"CRM LOG OUT"} uri="/crm-agent" username={crm_agent} />
      <div className="content px-4 py-4">
        <Card className="bg-white">
          <Form
            // onSubmit={(e) => getTopupdata(e)}
            onSubmit={handleSubmit}
            style={{
              width: "100%",
            }}
            className="row px-2"
          >
            <div className="col">
              <FormGroup
                className="d-flex"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: 6,
                  width: "100%",
                }}
              >
                <div className="col px-0">
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => HandleEmail(e)}
                    className="mb-0"
                  />
                </div>
                <div className="col px-0">
                  <Input
                    type="text"
                    placeholder="Transaction ID"
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    className="mb-0"
                  />
                </div>
                <div className="co px-0">
                  <Input
                    type="select"
                    value={source}
                    onChange={(e) => {
                      setSource(e.target.value);
                    }}
                    className="mb-0 mr-2"
                  >
                    {[
                      { value: "", label: "Select" },
                      { value: "Online", label: "Online" },
                      { value: "Offline", label: "Offline" },
                    ].map((option) => (
                      <option value={option.value} className="mb-0 mr-2">
                        {option.label}
                      </option>
                    ))}
                  </Input>
                </div>
                <div className="col px-0">
                  <Input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="mb-0 my-2"
                  />
                  <Input
                    type="time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    className="mb-0 my-2"
                  />
                </div>

                <div className="">
                  <strong>TO</strong>{" "}
                </div>
                <div className="col px-0">
                  <Input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    // onChange={(e) => setEndDate(e.target.value)}
                    className="mb-0 mr-2 my-2"
                  />
                  <Input
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    // onChange={(e) => setEndTime(e.target.value)}
                    className="mb-0 my-2"
                  />
                </div>
                <div>
                  {/* <Button
                    variant="primary"
                    onClick={(e) => getExcel()}
                    className="submitbtn"
                  >
                    Export Excel
                  </Button> */}
                  <Button
                    variant="primary"
                    onClick={(e) => resetPage()}
                    className="submitbtn"
                  >
                    Reset
                  </Button>
                </div>
                {/* <div>
                          
                      </div>
                      <div>
                            
                        </div>  */}
              </FormGroup>
            </div>
          </Form>
          {/* <div>                
              <Link className="btn btn-outline-light submitbtn" to="/admin/acharyacredit">Top Up</Link>

          </div> */}
        </Card>
        {isLoading ? (
          <Loadingspinner />
        ) : (
          <Card className="bg-white">
            <CardBody className="relative overflow-x-auto">
              <>
                {" "}
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  size="sm"
                  width={"100%"}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <thead>
                    <tr>
                      <th className="text-dark">S.No</th>
                      <th className="text-dark">Name</th>
                      <th className="text-dark">Email</th>
                      <th className="text-dark">Action</th>
                      <th className="text-dark">Phone</th>
                      <th className="text-dark">Amount</th>
                      <th className="text-dark">CRM Order No</th>
                      <th className="text-dark">Mode</th>
                      <th className="text-dark">Order Id</th>
                      <th className="text-dark">Transaction Id</th>
                      <th className="text-dark">Date</th>
                      <th className="text-dark">Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {data ? (
                        data.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span className="text-dark">{item.serial}</span>
                            </td>
                            <td>
                              <span className="text-dark">{item.name}</span>
                            </td>
                            <td>
                              <span className="text-dark">{item.email}</span>
                            </td>
                            <td>
                              <span className="text-dark">{item.action}</span>
                            </td>
                            <td>
                              <span className="text-dark">{item.contact}</span>
                            </td>
                            <td>
                              <span className="text-dark">{item.amount}</span>
                            </td>
                            <td>
                              <span className="text-dark">
                                {item.crm_order}
                              </span>
                            </td>
                            <td>
                              <span className="text-dark">{item.mode}</span>
                            </td>
                            <td>
                              <span className="text-dark">{item.order_id}</span>
                            </td>
                            <td>
                              <span className="text-dark">
                                {item.transaction_id}
                              </span>
                            </td>
                            <td>
                              <span className="text-dark">
                                {moment(item.date)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm A")}
                              </span>
                            </td>

                            <td>
                              <span className="text-dark">
                                {moment(item.expiry_date)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm")}
                              </span>
                            </td>

                            <td className="d-flex">
                              {/* <button
                                class="btn btn-success btn-sm rounded-0"
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add"
                                onClick={() =>
                                  history.push(
                                    "/admin/acharya-details/" + item.id,
                                    item
                                  )
                                }
                              >
                                <i class="fa fa-edit" key={index}></i>
                              </button> */}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <h2 className="text-center">No Record Found</h2>
                      )}
                    </>
                  </tbody>
                </Table>{" "}
              </>
              <Paginations
                pageNo={pageNo}
                totalPages={totalPages}
                maxPagesToShow={10}
                setPageNo={setPageNo}
              />
            </CardBody>
          </Card>
        )}
      </div>
    </>
  );
};

export default TopUp;
