import React, { useCallback, useEffect, useRef, useState } from "react";
import FooterNew from "components/Footer/FooterNew";
import Header from "components/Header";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AOS from "aos";
import Footer from "components/Landing/Footer";
// import Header from "components/Landing/Header";

const PrivacyPolicy = () => {
  const [loader, SetLoader] = useState(true);
  const [lang, setlang] = useState("eng");
  const [isOpen, setOpen] = useState(false);
  // const [langDefault, setlangDefault] = useState(lang);
  const mylink = useLocation();
  const link = mylink.state;
  // scroll by link
  const scrollToSection = (id) => {
    if (id) {
      const section = document.getElementById(id);
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 1,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      SetLoader(false);
    }, 300);

    // animation
    AOS.init({
      useClassNames: true,
      initClassName: false,
      animatedClassName: "animated",
    });

    scrollToSection(link);

    localStorage.setItem("language", lang);
    const language = localStorage.getItem("language");

    if (language) {
      setlang(language);
    } else {
      setlang(lang);
    }
  }, [link]);

  useEffect(() => {
    let langs = localStorage.getItem("langDefault");
    if (langs) {
      setlang(langs);
    } else {
      setlang(lang);
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          background: "#fff",
        }}
        className="bg-white "
      >
        <Header lang={setlang} text={"Privacy & Policy"} langDefault={lang} />
        <section className="dark:bg-gray-100 dark:text-gray-800 bg-white flex justify-center align-center lg:py-32">
          <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
            <h2 className="mb-5 text-4xl font-bold leading-none text-center sm:text-5xl text-gray-600 max-lg:pt-28">
              Privacy & Policy
            </h2>
            <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 dark:divide-gray-300">
              {/* <details> */}

              <div className="grid gap-10 md:gap-8 sm:p-3 md:grid-cols-2  lg:grid-cols-1 lg:px-0 xl:px-0">
                <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    PRIVACY POLICY
                  </h5>

                  <p className="mt-1 text-gray-800 text-justify lg:text-base py-2">
                    At gdvpanel.in, The Privacy and Security of our customer’s
                    personal information is of utmost importance to us. This
                    privacy policy outlines how we collect, use, and protect the
                    information that is provided to us through our GDV
                    Acharyapanel.{" "}
                  </p>
                  <p className="mt-1 text-gray-800 text-justify lg:text-base">
                    This Privacy Policy is published in accordance with Rule
                    3(1) of the Information Technology (Intermediaries
                    Guidelines) Rules, 2011 and Information Technology
                    (Reasonable Security Practices and Procedures and Sensitive
                    Personal Data or Information) Rules, 2011 which requires
                    publishing of the Privacy policy for collection, use,
                    storage and transfer of sensitive personal data or
                    information.{" "}
                  </p>
                </div>
                <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    USER’S CONSENT{" "}
                  </h5>
                  <p className="mt-1 text-gray-800 text-justify lg:text-base py-2">
                    This Privacy Policy, which may be updated/amended from time
                    to time, deals with the information collected from its users
                    in the form of personal identification, contact details,
                    birth details and any forecast made using the supplied
                    information and how such information is further used for the
                    purposes of the Website. By accessing the website and using
                    it, you indicate that you understand the terms and expressly
                    consent to the privacy policy of this website. If you do not
                    agree with the terms of this privacy policy, please do not
                    use this GDV Panel.{" "}
                  </p>
                </div>
                <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    SECURITY MEASURES
                  </h5>

                  <p className="mt-1 text-gray-800 text-justify lg:text-base py-2">
                    The security of the personal information supplied by the
                    User is very important to the Website and the website for
                    the purpose of securing the information takes various
                    measures inclusive of taking reasonable steps such as
                    physical and electronic security measures to guard against
                    the unauthorized access to the information. The personal
                    information of a user is collected on a secured server. The
                    payment details are entered on the Payment Gateway’s or
                    Bank’s page on a secured SSL. The data is transferred
                    between Bank’s page and payment’s gateways in an encrypted
                    manner. However please note that no data transmission can be
                    guaranteed to be completely secure. Hence the user is
                    advised to take precaution and care against any sharing of
                    the details submitted on the website included the log-in
                    details as generated after registration. The website is not
                    responsible for the security or confidentiality of
                    communications the user may send through the internet using
                    email messages, etc.{" "}
                  </p>
                </div>
                <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    CONFIDENTIAL{" "}
                  </h5>
                  <p className="mt-1 text-gray-800 text-justify lg:text-base py-2">
                    The GDV Panel aspires to take care of all the information
                    provided by its Users which may be termed as confidential.
                    Such confidential information which is not required to be
                    disclosed to the website is specifically excluded from the
                    definition of Personal Information and shall not be
                    collect/used. The confidential information of the User shall
                    not be disclosed or shared by the Websites, its employees,
                    its agents or any third-party contractors including the
                    experts either orally or in writing except for the following
                    circumstances:{" "}
                  </p>

                  <p className="mt-1 text-gray-800 text-justify lg:text-base py-2">
                    1. If Website believes that there is a
                    significant/real/imminent threat or risk to User’s health,
                    safety or life or to the health, safety or life of any other
                    person or the public.{" "}
                  </p>

                  <p className="mt-1 text-gray-800 text-justify lg:text-base">
                    2. If such confidential information must be shared in
                    accordance with the law inclusive of any investigation,
                    Court summons, judicial proceedings etc. By using our
                    website and our services, you agree to the terms outlined in
                    this privacy policy.{" "}
                  </p>
                </div>
                <div>
                  <h5 className="text-2xl bg-gradient-to-r from-violet-400 to-pink-400 bg-opacity-0 text-white w-full px-1">
                    DISCLAIMER
                  </h5>
                  <p className="mt-1 text-gray-800 text-justify lg:text-base py-2">
                    THE WEBSITE IS NOT RESPONSIBLE FOR ANY COMMUNICATION BETWEEN
                    THE USER AND THE THIRD-PARTY WEBSITE. THE USER IS ADVISED TO
                    READ THE PRIVACY POLICY AND OTHER POLICIES OF THE THIRD
                    PARTY ON THEIR WEBSITES AND THIS WEBSITE SHALL NOT BE HELD
                    LIABLE FOR SUCH USAGE MADE ONLY BECAUSE A LINK TO THE
                    THIRD-PARTY WEBSITE WAS PROVIDED ON THE PAGE OF THIS
                    WEBSITE.
                  </p>
                  <p className="mt-1 text-gray-800 lg:text-base ">
                    <span className="text-underline">GRIEVANCE OFFICER</span>
                    {/* <br /> */}
                    <br />
                    <strong>Officer Name:</strong> M. Sharma{" "}
                  </p>
                  <p className="mt-1 text-gray-800 lg:text-base">
                    <strong>Email:</strong> care@astroscience.in{" "}
                  </p>

                  <p className="mt-1 text-gray-800 text-justify lg:text-base ">
                    The above Officer is appointed in accordance with the
                    Information Technology Act 2000 and rules made there under.
                    The Officer can be contacted if there are any discrepancies
                    found on the website or there is any breach of Terms of Use,
                    Privacy Policy or other policies or any other complaints or
                    concerns.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
