import axios from "axios";
import classNames from "classnames";
import ReactTable from "components/ReactTable/ReactTable.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useDispatch, useSelector} from "react-redux";
import { logout } from "redux/actions/auth";

const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
const VASHISHT_API_URL = process.env.REACT_APP_VASHISHT_API;
const REACT_APP_TEST_URL_APPAPI = process.env.REACT_APP_TEST_URL_APPAPI;

const Storedkundalitable = (props) => {
  const dispatch = useDispatch();
  const [savedkundali, setsSavedkundali] = useState([]);
  // const [isValidPlan, setIsValidPlan] = useState([]);
  const notificationAlertRef = React.useRef(null);
  const token = localStorage.getItem("token");
  const acharya = localStorage.getItem("user");
  const history = useHistory();
  const currentPlan = useSelector(e  => e.plan)
  // console.log(currentPlan,"currentPlan")
  const isValidPlan = currentPlan.plan_status;


  const data = JSON.parse(acharya);

  console.log(JSON.parse(acharya));

  const rotatekundaliList = new Map(
    [
      { id: 1, name: "Your Self" },
      { id: 2, name: "Father in law" },
      { id: 3, name: "younger Brother/Sister" },
      { id: 4, name: "Mother" },
      { id: 5, name: "Father/Son" },
      { id: 6, name: "Mama/Bhanja/Bua/Brother in Law(Saala)" },
      { id: 7, name: "Spouse" },
      { id: 9, name: "Daada Ji" },
      { id: 10, name: "Chacha" },
      { id: 11, name: "Elder brother/Sister/Taau" },
    ].map((entry) => [entry.id, entry.name])
  );

  // const checkSubsHandler = async (e) => {
  //   console.log("entered");
  //   try {
  //     let response = await axios.post(
  //       `${BAS_URL_APPAPI}acharya/checkSubscription`,
  //       {
  //         acharyaid: data.id,
  //       }
  //     );
  //     let registration = response.data;
  //     console.log(registration, "hhhhhhh");
  //     setIsValidPlan(registration.success);
  //   } catch (error) {
  //     let data = localStorage.getItem("user");
  //     let finalData = JSON.parse(data);
  //     let token = localStorage.getItem("token");
  //     if (error.response.data.messages == "NOT_A_USER") {
  //       const obj = {
  //         id: finalData.id,
  //         token: token,
  //       };
  //       // console.log(error, finalData.id, token, obj, "JJJJJJJJ")
  //       try{
  //       const result = await axios.post(`${BAS_URL_APPAPI}acharya/logout`, obj);
  //       }catch(err){
  //         if (error.response.data.messages == "NOT_A_USER") {
  //           dispatch(logout());
  //         }
  //       }
  //     }
  //   }
  // };

  const getsavedkundaliData = async () => {
    try {
      const token = localStorage.getItem("token");
      let acharya = localStorage.getItem("user");
      const userDetail = JSON.parse(acharya);
      const id1 = userDetail.id;
      const savedData = await axios.get(
        `${BAS_URL_APPAPI}acharya/kundalilist/${id1}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      let data = savedData.data.data.map((val, inex) => {
        return {
          ...val,
          kundaliFor: rotatekundaliList.get(parseInt(val.kundaliFor)),
        };
      });
      setsSavedkundali(data);
    } catch (error) {
      if(error.response.data.messages == 'NOT_A_USER'){
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    // checkSubsHandler();
    getsavedkundaliData();
  }, []);

  console.log(savedkundali,"savedkundali--")

  return (
    <div className="content">
      <NotificationAlert ref={notificationAlertRef} />
      <Row>
        <Col>
          <Card className="mb-1">
            <CardBody>
              <h4 className="mb-0">All Saved Kundali</h4>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Card>
            <CardBody>
              <ReactTable
                data={savedkundali}
                filterable
                resizable={true}
                columns={[
                  {
                    Header: "Id",
                    accessor: (row, index) => index + 1,
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                  },
                  {
                    Header: "D.O.B",
                    accessor: "dob",
                  },
                  {
                    Header: "T.O.B",
                    accessor: "tob",
                  },
                  {
                    Header: "Kundali For",
                    accessor: "kundaliFor",
                  },
                  {
                    Header: "P.O.B",
                    accessor: "pob",
                  },

                  {
                    Header: "Remarks",
                    accessor: "remark",
                  },
                  {
                    Header: "Date",
                    accessor: (row) => (
                      <div>
                        {moment(row.generateDate).format("DD/MM/YY")}
                        <div>{row.generateTime}</div>
                      </div>
                    ),
                    //accessor: row => <div>{(new Date(Date.parse(row.generateDate))).toLocaleDateString("en-US", options)}</div>,
                  },
                  {
                    Header: "Actions",
                    // accessor:(d) => <div><Button   color={"primary"} style={{padding:"5px 10px"}}><i className='fa fa-eye'></i></Button>{" "}</div>,
                    accessor: (d) =>
                      isValidPlan === 1 ? (
                        <div>
                          <Button
                            key={d.id}
                            onClick={() => {
                              let dataObj = {
                                langitutde: "",
                                gender: "",
                                kundalitype: "",
                                birthDate: {
                                  day: "",
                                  month: "",
                                  year: "",
                                },
                                timezone: "",
                                language: "1",
                                // "product": product,
                                product: "150",
                                latitude: "",
                                name: "",
                                dst: "",
                                generate: "",
                                // "pob": "",
                                pob: {
                                  placeName: "",
                                  StateName: "",
                                  countryName: "",
                                  latitude: "",
                                  longitude: "",
                                  gmtoffset: "",
                                  dstoffset: "",
                                  timezone: "",
                                },
                                // "birthTime": birthTime,
                                birthTime: {
                                  hour: "",
                                  minute: "",
                                },
                                rotatekundali: "1",
                                currentDate: "",
                                currentTime: "",
                                showpdf: true,
                                showgochar: true,
                                ageRange: "",
                                branding: "",
                                acharyaid: "",
                                btntype: "prediction",
                                message: "",
                                generateKundaliProduct: "",
                              };
                              //cleaning data for new kundali generation
                              localStorage.setItem(
                                "kundalidata",
                                JSON.stringify(dataObj)
                              );
                              history.push({
                                pathname: "/acharya/dashboard/",
                                state: { view_id: d.id },
                              });
                            }}
                            data={savedkundali}
                            color={"primary"}
                            style={{ padding: "5px 10px" }}
                          >
                            <i className="fa fa-eye"></i>
                          </Button>{" "}
                        </div>
                      ) : (
                        <div>
                          <Button
                            disabled
                            key={d.id}
                            color={"primary"}
                            style={{ padding: "5px 10px" }}
                          >
                            <i className="fa fa-eye-slash"></i>
                          </Button>{" "}
                        </div>
                      ),
                  },
                ]}
                defaultPageSize={10}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Storedkundalitable;
