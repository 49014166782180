import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Modal, ModalBody } from "reactstrap";
import { checkplan } from "redux/actions/plan";
import { useDispatch, useSelector } from "react-redux";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const Modals = () => {
  const planStatus = useSelector((e) => e.plan.plan_status);
  // console.log(planStatus,"planStatusjjj")
  const [modalIsOpen, setIsOpen] = useState(false);
  const history = useHistory();
  function closeModal() {
    setIsOpen(false);
  }

  const Redirect = () => {
    history.push("/acharya/registration-management");
    closeModal();
  };


  useEffect(()=>{
    setIsOpen(planStatus == 0)
  },[planStatus])


  return (
    <div className="formmodal-card">
      <Modal
        className="modal-data"
        size="md"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        fullscreen
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "45%",
          left: "50%",
          right: "0",
          marginLeft: "0",
          marginRight: "0",
          width: "95%",
        }}
      >
        <i
          className="fa fa-close lkaclose"
          onClick={closeModal}
          style={{ fontSize: "20px", cursor: "pointer", zIndex: "1" }}
        ></i>
        <ModalBody
          className="p-5 text-center mt-2 expired-pop"
          style={{ overflowY: "hidden" }}
        >
          Your current Plan has been expired
          <br />
          Please renew your plan!!
          <br />
          <Button
            onClick={(e) => {
              Redirect();
            }}
            className="mt-4"
          >
            {" "}
            Renew
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Modals;
