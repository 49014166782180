import { HOROSCOPE_POST,PREDICTION_POST, GET_MAHADASHA } from '../actions/constants'

const initialState = {horo:{}, maha:{},pred:{}}

export default function(state=initialState, action){
    const {type, payload} = action
    switch(type){
        case HOROSCOPE_POST:
            state.horo = payload
            return {...state}       
        case PREDICTION_POST:
            state.pred = payload
            return {...state}
        case GET_MAHADASHA:
            state.maha = payload
            return {...state}
        default:
            return state
    }
}