import axios from "axios";
import { CHECK_PLAN } from "./constants";
import { logout } from "./auth";

const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

// Keep user loaded persistently
export const checkplan = () => async (dispatch) => {
  let acharya = localStorage.getItem("user");
  const userDetail = JSON.parse(acharya);
  const acharyaid = userDetail.id;

  const body = {
    id: acharyaid,
  };

  try {
    const res = await axios.post(`${BAS_URL_APPAPI}acharya/acharyaval`, body);
    // console.log("Resssssssssssssssss=============", res.data);
    dispatch({
      type: CHECK_PLAN,
      payload: res.data.data,
    });
    // console.log(res,"hagemaru")
  } catch (err) {
    dispatch({
      type: CHECK_PLAN,
      payload: err.response.data.messages,
    });
    if(err.response.data.messages == 'NOT_A_USER'){
      dispatch(logout());
    }
  }
};

