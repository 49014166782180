import { CloudHSM } from "aws-sdk";
import { Threedotscomquestion } from "components/Dashboard/Threedotscomquestion";
import Parser from "html-react-parser";
import { memo } from "react";

function Accordions({ data, isLoading, tabnames, handleQuestionAns }) {
  // console.log(tabnames, "ajsndjj");

  const Skeleton = () => {
    return (
      <div role="status">
        <div class="flex items-center w-full px-3 py-1">
          <span class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mt-0 pulse-line"></span>
          <span class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24 pulse-line"></span>
          <span class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full pulse-line"></span>
        </div>
        <div class="flex items-center w-full max-w-[480px] px-3 py-1">
          <span class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full pulse-line"></span>
          <span class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full pulse-line"></span>
          <span class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24 pulse-line"></span>
        </div>
        <div class="flex items-center w-full max-w-[400px] px-3 py-1">
          <span class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full pulse-line"></span>
          <span class="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80 pulse-line"></span>
          <span class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full pulse-line"></span>
        </div>
      </div>
    );
  };

  const SkeletonSquare = () => {
    return (
      <div role="status p-4">
        <div class="flex flex-col items-center w-full px-[40px] pt-4">
          <span class="h-2.5 bg-gray-100 rounded-0 dark:bg-gray-700 w-32 mt-0 pulse-line py-4 mb-3 w-full"></span>
          <span class="h-2.5 bg-gray-100 rounded-0 dark:bg-gray-700 w-32 mt-0 pulse-line py-4 mb-3 w-full"></span>
          <span class="h-2.5 bg-gray-100 rounded-0 dark:bg-gray-700 w-32 mt-0 pulse-line py-4 mb-3 w-full"></span>
        </div>
      </div>
    );
  };

  const HandleAnswersLocal = (e) => {
    const item = JSON.parse(localStorage.getItem(e.category));
    item.map((obj) => {
      if (obj.qid === e.qid) {
        if (Array.isArray(obj.ans)) {
          console.log(obj.ans, "fcfcf");
          // Store the object in localStorage
          const existingData =
            JSON.parse(localStorage.getItem(e.category)) || [];
          localStorage.setItem(e.category, JSON.stringify(existingData));
        } else {
          handleQuestionAns(e);
        }
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <SkeletonSquare />
      ) : data?.length ? (
        <section class="accordion border-none py-4">
          {data &&
            data?.map((e, index) => (
              <div class="tabs" key={index}>
                <input type="checkbox" name="accordion-1" id={`cb${index}`} />
                <label
                  role="button"
                  for={`cb${index}`}
                  class="tab__label bg-gray-100 text-dark"
                  style={{ fontWeight: "bolder" }}
                  onClick={() => HandleAnswersLocal(e)}
                >
                  {Parser(e.que)}
                </label>
                {Array.isArray(e.ans) ? (
                  e.ans.map((ans, indexs) => (
                    <div key={indexs} class="tab__content">
                      <p>{Parser(ans.ans)}</p>
                      {ans.upay && Parser(ans.upay).length > 0 && (
                        <p>
                          <strong>Remedies :</strong> {Parser(ans.upay)}
                        </p>
                      )}
                    </div>
                  ))
                ) : (
                  <div class="tab__content">
                    <Skeleton />
                  </div>
                )}
              </div>
            ))}
        </section>
      ) : (
        <strong className="text-center flex justify-center py-5 text-danger">
          {data.note && data.note.replace("--catename--", tabnames)}
        </strong>
      )}
    </>
  );
}

export default memo(Accordions);
