import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import tanslate from "../views/translate/languages";
import ProfileIcon from "views/components/ProfileIcon";
import { logout } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import ProfileIconMobile from "../views/components/ProfileIconMobile";
import Menu from "../views/components/Menu";
import axios from "axios";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const Header = ({ lang, langDefault = "eng" }) => {
  const history = useHistory();
  // Redirect to Diff Page
  const [activeNav, setActiveNav] = useState(false);
  const [activeAnch, setActiveAnch] = useState("");
  // Reload after logout
  const [reload, setReload] = useState(false);
  // Track the previous scroll position
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const user = localStorage.getItem("user");
  const userDetail = JSON.parse(user);
  // use Redux
  const dispatch = useDispatch();

  // Redirect Logic for push achor text to another page
  const redirectLink = (mylink) => {
    history.push({
      pathname: "/",
      state: mylink,
    });
  };

  console.log(userDetail,"userDetailhhhd")

  // Get acharaya image
  let acharyaimg =
    userDetail && userDetail["image"]
      ? userDetail["image"]
      : require("assets/img/default-avatar.png");

  // get user Details from localsrorage
  const aid = userDetail ? userDetail["id"] : "";

  // close drawer in mobile after select navigation
  const closeDrawer = (event, mylink) => {
    setActiveAnch(event);
    setActiveNav(false);
    redirectLink(mylink);
  };

  // close drawer in mobile after select lang Hin
  const closeDrawerLangHindi = () => {
    lang("hin");
    setActiveNav(false);
    localStorage.setItem("langDefault", "hin");
  };

  // close drawer in mobile after select lang Eng
  const closeDrawerLangEng = () => {
    lang("eng");
    setActiveNav(false);
    localStorage.setItem("langDefault", "eng");
  };

  // logout functionality
  const logout1 = async () => {
    const obj = {
      id: aid,
    };

    try {
      const result = await axios.post(`${BAS_URL_APPAPI}acharya/logout`, obj);
      console.log(result, "result");
      if (result) {
        dispatch(logout());
        setReload(true);
        history.push("/login");
      }
    } catch (err) {
      if (err.response.data.messages == "NOT_A_USER") {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    let langs = localStorage.getItem("langDefault");
    if (langs) {
      // setlangDefault(langs);
    } else {
      // setlangDefault(lang);
    }
  }, []);

  useEffect(() => {
    const fadeOutOnScroll = () => {
      const element = document.getElementById("header"); // Replace 'header' with the ID of your element
      if (!element) return;

      // Check if the current screen size is larger than the lg breakpoint (1024px)
      if (window.innerWidth <= 1024) {
        // Stop the functionality for max-lg (1024px and below)
        element.style.opacity = 1; // Reset to fully opaque if under max-lg
        return;
      }

      const scrollTop = window.scrollY;
      let opacity = parseFloat(element.style.opacity) || 1;

      if (scrollTop > lastScrollTop) {
        // Scrolling down
        opacity = 0; // Decrease opacity, but don’t go below 0
      } else {
        // Scrolling up
        opacity = 1; // Increase opacity, but don’t go above 1
      }

      element.style.opacity = opacity;
      setLastScrollTop(scrollTop);
    };

    const handleScroll = () => fadeOutOnScroll();
    window.addEventListener("scroll", handleScroll);

    // Cleanup scroll event on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop, reload]);

  return (
    <>
      <header
        id="header"
        className="cs_site_header cs_style_1 cs_text_dark cs_sticky_header cs_medium cs_sticky_active bg-gradient-to-r from-violet-400 to-pink-400"
        style={{
          backgroundColor: "transparent",
          transition: "opacity 0.3s ease",
        }}
      >
        <div className="cs_main_header">
          <div className="container">
            <div className="cs_main_header_in">
              <div className="shrink-0 mr-4">
                <a
                  href={"/"}
                  className="block text-[#000] text-2xl font-bold"
                  aria-label="Cruip"
                >
                  <img src={logo} alt={"GDV PANEL"} width={"70%"} />
                </a>
              </div>
              <div className="cs_main_header_left w-fit">
                <nav className="cs_nav cs_medium cs_primary_font">
                  <ul
                    className={`cs_nav_list cs_onepage_nav relative ${
                      activeNav ? "cs_active" : ""
                    }`}
                  >
                    <div className="flex justify-center m-auto rounded-md  absolute top-6 left-0 right-0 lg:hidden">
                      <button
                        onClick={() => closeDrawerLangHindi()}
                        title="Hindi"
                        className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10"
                      >
                        हिं
                      </button>
                      <button
                        onClick={() => closeDrawerLangEng()}
                        title="English"
                        className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10"
                      >
                        En
                      </button>
                    </div>

                    {userDetail ? (
                      <div className="max-lg:block lg:hidden pb-4">
                        <div className="text-center text-gray-950">
                          <ProfileIconMobile imageUrl={acharyaimg} />
                        </div>

                        {/* {console.log(userDetail,"userDetail")} */}
                        <div className="text-center text-gray-950 font-light">
                          {userDetail?.name}
                        </div>
                        <div className="text-center text-gray-950 font-light">
                          {userDetail?.email}
                        </div>
                        <div className="mt-3 flex justify-center">
                          <Link
                            to="/login"
                            className="btn-primary text-gray-900 bg-white focus:outline-none hover:bg-gray-700 focus:ring-4 font-medium rounded-full text-sm px-4 py-2.5 me-2 mb-2"
                          >
                            Dashboard
                          </Link>
                          <Link
                            onClick={logout1}
                            className="btn-primary text-gray-900 bg-white focus:outline-none hover:bg-gray-700 focus:ring-4  font-medium rounded-full text-sm px-4 py-2.5 me-2 mb-2"
                          >
                            Log Out
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <>
                        <li className="max-lg:block lg:hidden">
                          <Link
                            to="/registration"
                            className="max-lg:block px-3 text-dark"
                            data-modal="register"
                          >
                            {tanslate.register[langDefault]}
                          </Link>
                        </li>
                        <li className="max-lg:block lg:hidden">
                          <Link
                            className="cs_header_text_btn cs_modal_btn"
                            to="/login"
                          >
                            {tanslate.login[langDefault]}
                          </Link>
                        </li>
                      </>
                    )}

                    <li>
                      <Link
                        // href="#home"
                        className={
                          activeAnch === 0
                            ? "active-link"
                            : "max-lg:hover:bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] max-lg:hover:bg-[#a78bfa] xl:hover:bg-transparent"
                        }
                        onClick={() => closeDrawer(0, "home")}
                      >
                        {tanslate.home[langDefault]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        // href="#features"
                        className={
                          activeAnch === 1
                            ? "active-link"
                            : "max-lg:hover:bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] max-lg:hover:bg-[#a78bfa] xl:hover:bg-transparent"
                        }
                        onClick={() => closeDrawer(1, "features")}
                      >
                        {tanslate.features[langDefault]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="#pricing"
                        className={
                          activeAnch === 2
                            ? "active-link"
                            : "max-lg:hover:bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] max-lg:hover:bg-[#a78bfa] xl:hover:bg-transparent"
                        }
                        onClick={() => closeDrawer(2, "pricing")}
                      >
                        {tanslate.pricing[langDefault]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="#testimonial"
                        className={
                          activeAnch === 3
                            ? "active-link"
                            : "max-lg:hover:bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] max-lg:hover:bg-[#a78bfa] xl:hover:bg-transparent"
                        }
                        onClick={() => closeDrawer(3)}
                      >
                        {tanslate.testimonial[langDefault]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="#faq"
                        className={
                          activeAnch === 4
                            ? "active-link"
                            : "max-lg:hover:bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] max-lg:hover:bg-[#a78bfa] xl:hover:bg-transparent"
                        }
                        onClick={() => closeDrawer(4)}
                      >
                        {tanslate.whyUs[langDefault]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact-us"
                        className={
                          activeAnch === 5
                            ? "active-link"
                            : "max-lg:hover:bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] max-lg:hover:bg-[#a78bfa] xl:hover:bg-transparent"
                        }
                        onClick={() => closeDrawer(5)}
                      >
                        {tanslate.contactUs[langDefault]}
                      </Link>
                    </li>
                  </ul>
                  {activeNav && (
                    <span
                      className="cs_menu_toggle cs_toggle_active z-[20]"
                      onClick={() => setActiveNav(false)}
                    >
                      <span></span>
                    </span>
                  )}
                </nav>
              </div>
              <div className="cs_main_header_right">
                <div className="cs_header_btns">
                  <div className="inline-flex rounded-md shadow-sm max-lg:hidden">
                    <button
                      onClick={() => closeDrawerLangHindi()}
                      title="Hindi"
                      className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:text-white"
                    >
                      हिं
                    </button>
                    <button
                      onClick={() => closeDrawerLangEng()}
                      title="English"
                      className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:text-white"
                    >
                      En
                    </button>
                  </div>
                  {/* {console.log(userDetail,"")} */}
                  {userDetail ? (
                    <div className="max-lg:hidden">
                      <Menu
                        logout={logout1}
                        img={
                          <ProfileIcon
                            imageUrl={acharyaimg}
                            userDetail={userDetail}
                          />
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <Link
                        className="cs_header_text_btn text-dark cs_modal_btn max-lg:hidden"
                        to="/login"
                      >
                        {tanslate.login[langDefault]}
                      </Link>
                      <div className="max-lg:hidden">
                        <Link
                          to="/registration"
                          className="cs_btn cs_bg_accent cs_modal_btn max-lg:block"
                          data-modal="register"
                        >
                          {tanslate.register[langDefault]}
                        </Link>
                      </div>
                    </>
                  )}
                </div>

                {!activeNav && (
                  <span
                    className="cs_menu_toggle"
                    onClick={() => setActiveNav(true)}
                  >
                    <span></span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
