import React from "react";
import HeroBg2 from "../../assets/landing/img/hero_bg2.png";
import HeroBg3 from "../../assets/landing/img/hero_bg3.png";
import iconkundali from "../../assets/landing/img/icon-kundali.svg";
import iconPred from "../../assets/landing/img/iconpred.svg";
import Questions from "../../assets/landing/img/Questions.svg";
import Crown from "../../assets/landing/img/Crown.svg";
import Form from "./Form";

const New = ({ lang }) => {
  //data
  const bullets = {
    eng: [
      {
        heading: "Powerful Features with Incredible",
        sub_heading: "",
        points: [
          {
            num: "1",
            img: iconkundali,
            title: "Comprehensive Kundali Analysis System",
            sub_title:
              "This feature offers a user-friendly platform to create and analyze Kundalis (birth charts) with precision, making astrology accessible and easy to understand.",
          },
          {
            num: "2",
            img: iconPred,
            title: "Dasha Planetary Prediction Engine",
            sub_title:
              "Leverage the power of Vedic astrology’s Dasha system to receive detailed forecasts based on the unique planetary influences during specific periods of your life.",
          },
          {
            num: "3",
            img: Questions,
            title: "Personalized Question & Answer Analysis",
            sub_title:
              "Ask questions and receive customized responses grounded in the analysis of your individual birth chart, providing highly relevant insights for every query.",
          },
          {
            num: "4",
            img: Crown,
            title: "Precise Prediction Remedies",
            sub_title:
              "Our software helps you select and implement the most effective remedies to counteract negative planetary influences, ensuring your predictions and solutions are on point.",
          },
        ],
      },
    ],

    hin: [
      {
        heading: "शक्तिशाली फीचर्स के साथ अद्भुत",
        sub_heading: "",
        points: [
          {
            num: "1",
            img: iconkundali,
            title: "व्यापक कुंडली विश्लेषण प्रणाली",
            sub_title: "यह फीचर उपयोगकर्ता के अनुकूल प्लेटफ़ॉर्म प्रदान करता है, जिससे आप कुंडलियों (जन्म पत्रिकाओं) को सटीकता से बना सकते हैं और उनका विश्लेषण कर सकते हैं, जिससे ज्योतिष को समझना और उपयोग करना आसान हो जाता है।",
          },
          {
            num: "2",
            img: iconPred,
            title: "दशा ग्रह भविष्यवाणी इंजन",
            sub_title: "वैदिक ज्योतिष की दशा प्रणाली की शक्ति का उपयोग करें और जीवन के विशिष्ट समयावधियों के दौरान ग्रहों के प्रभाव के आधार पर विस्तृत भविष्यवाणियाँ प्राप्त करें।",
          },
          {
            num: "3",
            img: Questions,
            title: "व्यक्तिगत प्रश्न और उत्तर विश्लेषण",
            sub_title: "प्रश्न पूछें और अपनी व्यक्तिगत जन्म कुंडली के विश्लेषण पर आधारित कस्टमाइज़्ड उत्तर प्राप्त करें, जो प्रत्येक सवाल के लिए अत्यधिक प्रासंगिक जानकारी प्रदान करते हैं।",
          },
          {
            num: "4",
            img: Crown,
            title: "सटीक भविष्यवाणी उपचार",
            sub_title: "सॉफ़्टवेयर आपको नकारात्मक ग्रहों के प्रभाव को ठीक करने के लिए सबसे प्रभावी उपचार चुनने और लागू करने में मदद करता है, ताकि आपकी भविष्यवाणियाँ और समाधान सटीक हों।",
          },
        ],
      },
    ],
  };

  return (
    <section
      className="cs_hero cs_style_2 position-relative py-5"
      id="home container-custom"
    >
      <div className="container-custom px-0">
        <div className="row align-items-center cs_gap_y_10 px-3">
          {bullets[lang].map((item_main, index) => (
            <>
              <div
                className="cs_section_heading cs_style_1 text-[40px] mb-5 flex justify-center col-xl-12"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <h2 className="cs_section_title mb-0">{item_main.heading}</h2>
              </div>
              <div
                className="col-xl-8 col-lg-7 wow fadeIn pt-0"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
              >
                <div className="cs_height_60 cs_height_lg_40"></div>
                <div className="row cs_gap_y_40">
                  {item_main.points.map((items, index) => (
                    <div className="col-lg-12">
                      <div className="cs_iconbox cs_style_2">
                        <div className="items-start flex my-auto">
                          <div
                            key={index}
                            className="cs_number_box cs_bg_accent cs_text_white my-auto"
                            style={{
                              width: "60px",
                              flex: "0 0 auto",
                              height: "60px",
                              flexWrap: "1",
                            }}
                          >
                            <img
                              src={items.img}
                              width="auto"
                              alt="Polygon Icon"
                              className="p-2"
                            />
                          </div>
                          <div>
                            <h3
                              className="cs_iconbox_title flex my-auto pl-3 cs_text_accent"
                              data-aos="fade-left"
                              data-aos-duration="800"
                              data-aos-delay="200"
                            >
                              {items.title}
                            </h3>
                            <p className="cs_iconbox_subtitle text-gray-600 ms-5 text-dark">
                              {items.sub_title}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 mt-16">
                <div className="cs_hero_thumb">
                  <Form lang={lang} />
                </div>
              </div>{" "}
            </>
          ))}
        </div>
      </div>
      <div className="cs_hero_shape1">
        {/* <img src={HeroBg3} alt="Polygon Icon" /> */}
      </div>
      <div className="cs_hero_shape2">
        <img src={HeroBg2} alt="Polygon Icon" />
      </div>
    </section>
  );
};

export default New;
