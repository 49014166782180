import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { login } from "../../redux/actions/auth";
import Alerts from "components/Alert/Alerts";
import "./Login.css";
import Logo from "../../assets/img/logo.png";
// import discount from "../../assets/img/horooffer.png";
import discount from "../../assets/img/AI-background.webp";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import FooterNew from "components/Footer/FooterNew";
import NotificationAlert from "react-notification-alert";
import { CListGroup } from "@coreui/react";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Crm from "views/pages/Crm";
import Footer from "components/Landing/Footer";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
// import Alerts from '../components/Alert'

function Login() {
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const notificationAlertRef = React.useRef(null);
  let [submitClicked, setSubmitClicked] = useState(false);
  const history = useHistory();
  const [showMobileWarning, setShowMobileWarning] = useState(false);
  const loginFailed = useSelector((state) => state.auth.loginFailed);
  const users = useSelector((state) => state.auth);
  const loginExp = users.err;
  // console.log(users, "user");

  useEffect(() => {
    if (window.innerWidth <= 800) setShowMobileWarning(true);
  }, []);

  // console.log('authenticated', authenticated)
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [eye, setEye] = useState(true);
  const { email, password } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  // const [planStatus, setPlanStatus] = useState(3);
  // const [modalIsOpen, setIsOpen] = useState(false);

  // const closeModal = () => {
  //   console.log("click");
  //   setIsOpen(false);
  // };

  // const getSubscription = async () => {
  //   try {
  //     let acharya = localStorage.getItem("user");
  //     const userDetail = JSON.parse(acharya);
  //     const acharyaid = userDetail.id;
  //     const body = {
  //       id: acharyaid,
  //     };
  //     // //console.log("Acharya Id==============",acharyaid) ;
  //     let result = await axios.post(
  //       `${BAS_URL_APPAPI}acharya/acharyaval`,
  //       body
  //     );
  //     setPlanStatus(result.data.data.plan_status);
  //     if (result.data.data.plan_status === 0) {
  //       setIsOpen(true);
  //     }
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
    let dataObj = {
      langitutde: "",
      gender: "",
      kundalitype: "",
      birthDate: {
        day: "",
        month: "",
        year: "",
      },
      timezone: "",
      language: "1",
      // "product": product,
      product: "150",
      latitude: "",
      name: "",
      dst: "",
      generate: "",
      // "pob": "",
      pob: {
        placeName: "",
        StateName: "",
        countryName: "",
        latitude: "",
        longitude: "",
        gmtoffset: "",
        dstoffset: "",
        timezone: "",
      },
      // "birthTime": birthTime,
      birthTime: {
        hour: "",
        minute: "",
      },
      rotatekundali: "1",
      currentDate: "",
      currentTime: "",
      //"showref": user.user.data.role.includes('Admin') || user.user.data.role.includes('Horoscope With Reference') ? showref : false,
      //"showupay": user.user.data.role.includes('Admin') || user.user.data.role.includes('Horoscope With Remedy') ? showupay : false,
      showpdf: true,
      showgochar: true,
      ageRange: "",
      branding: "",
      acharyaid: "",
      btntype: "prediction",
      message: "",
      generateKundaliProduct: "",
    };
    setSubmitClicked(true);
    localStorage.setItem("kundalidata", JSON.stringify(dataObj));
    // console.log("Data object in franchise credit kundali=====", dataObj);
  };
  //Redirect if logged in
  if (authenticated) {
    console.log("^^^^^^^^^^", authenticated);
    console.log(authenticated, "authenticated");
    return <Redirect to="/acharya/dashboard" />;
  }

  const HandleReregNav = (event, userdata) => {
    // history.push("registration")
    history.push({
      pathname: "/registration",
      state: userdata,
    });
  };

  return (
    <>
      <div
        className="home-background bg-white"
        style={{
          "align-items": "center",
          display: "flex",
        }}
      >
        <NotificationAlert ref={notificationAlertRef}></NotificationAlert>
        {/* <Crm /> */}
        <div className="bg-white z-2 justify-center flex w-full lg:h-screen">
          <div className="row w-full">
            <div
              className="col-lg-7 col-md-12 bg-fuchsia-800"
              style={{
                backgroundImage: `Url(${discount})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: 'relative',
              }}
            >
              {/* <img src={discount} alt="Discount" /> */}
              <div
                style={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.0)", // Adjust opacity as needed
                  zIndex: 1,
                }}
              />
            </div>
            <div className="col-lg-5 col-md-12 justify-center flex flex-col self-center max-md:px-0">
              <div className="xl:w-[70%] 2xl:w-1/2  h-auto pt-4 pb-5 shadow-lg shadow-indigo-500/40 rounded-lg mx-auto px-10 lg:px-16 xl:px-12">
                <div className="d-flex w-100 justify-center align-center flex-column pb-4">
                  <div className="position-center flex justify-center items-center">
                    <Link to="/">
                      <img
                        src={Logo}
                        alt=""
                        className="object-contain xl:w-25"
                      />
                    </Link>
                  </div>
                  <h1 className="text-xl md:text-2xl font-semibold leading-tight text-gray-600 text-center mt-2">
                    <div className="my-0">GDV PANEL LOGIN</div>
                  </h1>
                </div>

                <form
                  class="mt-0"
                  action="#"
                  method="POST"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <div>
                    <label class="block text-gray-700">Email Address</label>
                    <input
                      type="email"
                      name="email"
                      // id=""
                      placeholder="Enter Email Address"
                      class="w-full px-4 py-2 rounded-lg bg-gray-200 mt-1 border focus:border-blue-500 focus:bg-white focus:outline-none"
                      autofocus
                      autocomplete
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div class="mt-4 relative">
                    <label class="block text-gray-700">Password</label>
                    <input
                      type={eye == true ? "password" : "text"}
                      name="password"
                      // id=""
                      placeholder="Enter Password"
                      minlength="6"
                      class="w-full px-4 py-2 rounded-lg bg-gray-200 mt-1 border focus:border-blue-500
                    focus:bg-white focus:outline-none"
                      onChange={(e) => onChange(e)}
                    />
                    <span
                      role="button"
                      style={{
                        position: "absolute",
                        right: "20px",
                        bottom: "8px",
                      }}
                      onClick={() => setEye((e) => !e)}
                    >
                      {eye == true ? (
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>

                  <div class="text-center mt-3">
                    <small class="text-[10px] text-dark-300">
                      *By using this website, you agree with the Terms and
                      Conditions.
                    </small>
                  </div>

                  <button
                    type="submit"
                    class="w-full block bg-gradient-to-bl from-[#e14eca] via-[#ba54f5] to-[#e14eca] hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
                   px-4 py-2 mt-6"
                  >
                    Log In
                  </button>
                </form>
                {/* {console.log(loginExp.data,"loginExp.data")} */}

                {loginFailed && submitClicked ? (
                  loginExp.data && loginExp.data == "REG_EXPIRIED" ? (
                    <Row className="justify-content-center">
                      <Form.Label
                        style={{ color: "red" }}
                        className="text-center"
                      >
                        <small> Your Account Has Expired!</small>
                        <small>
                          {" "}
                          Please Reregisteration again.{" "}
                          <Link
                            type="button"
                            class="text-blue-500 hover:text-blue-700 font-semibold"
                            onClick={(e) => HandleReregNav(e, users.user)}
                          >
                            Click Here!
                          </Link>
                        </small>
                      </Form.Label>
                    </Row>
                  ) : (
                    <Row className="justify-content-center">
                      <Form.Label
                        style={{ color: "red" }}
                        className="text-center"
                      >
                        <small>
                          Invalid Email or Password. Please try again.
                        </small>
                      </Form.Label>
                    </Row>
                  )
                ) : (
                  ""
                )}

                {/* {loginFailed && submitClicked ? (
                    loginExp.data && loginExp.data == "REG_EXPIRIED" ? (
                      <Row className="justify-content-center">
                        <p class="mt-2 text-center">
                          <Link
                            type="button"
                            class="text-blue-500 hover:text-blue-700 font-semibold"
                            onClick={(e) =>
                              HandleReregNav(e, users.user)
                            }
                          >
                            Re-register
                          </Link>
                        </p>
                      </Row>
                    ) : (
                      <Row className="justify-content-center">
                        <Link
                          to="/registration"
                          class="text-blue-500 hover:text-blue-700 font-semibold"
                        >
                          Register
                        </Link>
                      </Row>
                    )
                  ) : (
                    <Row className="justify-content-center">
                      <p class="mt-2 text-center">
                        <Link
                          to="/registration"
                          class="text-blue-500 hover:text-blue-700 font-semibold"
                        >
                          Register
                        </Link>
                      </p>
                    </Row>
                  )} */}

                <Row className="justify-content-center mt-2">
                  <Link
                    to="/registration"
                    class="text-blue-500 hover:text-blue-700 font-semibold"
                  >
                    Register
                  </Link>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
