import {SELECTED_PRODUCT } from '../actions/constants'

const initialState = {}

export default function(state=initialState, action){
    const {type, payload} = action
    switch(type){
        case SELECTED_PRODUCT:
            //state.horo = payload
            return {...state,...payload}
        default:
            return state
    }
}