import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  // Table,
  // ButtonGroup,
  // Button,
  // Nav,
  // NavItem,
  // TabPane,
  // TabContent,
} from "reactstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
// import { Audio, Rings, ThreeCircles } from "react-loader-spinner";
// import Loadingspinner from './Loadingspinner';
// import { Threedotscomp } from "components/Spinner/Threedotscomp";
// import moment from "moment";
// import InputNumber from "rc-input-number";
import NotificationAlert from "react-notification-alert";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
// const VASHISHT_API_URL = process.env.REACT_APP_VASHISHT_API;

const Horoinnerthree = (props) => {
  //console.log("Props=====",props);
  let vY = "" + props.finalyear;
  let gocharkundali = props.gocharShodhit;
  const [key, setKey] = useState("profile");
  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [gocharShodhit, setGocharShodhit] = useState([]);
  const [todaysGocharKundali, setTodaysGocharKundali] = useState([]);
  const [isGocharSelected, setIsGocharSelected] = useState(false);
  const allData = useSelector((state) => state.horoscope);
  const [laganchart, setLaganchart] = useState("data1");

  const [vyear, setVyear] = useState(0);
  const [showDiv, setShowDiv] = useState("false");
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const notificationAlertRef = React.useRef(null);
  const setBgChartData = (name) => {
    setLaganchart(name);
  };

  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  const shodhitkundli = allData.horo.new_kmv_lagan;
  const lagankundli = allData.horo.new_shodhitkundali;
  const newVfaldatanew = allData.horo.vfaldata;
  // const gocharkundali = allData.horo.gochar_shodhitkundali.shodhitkundali ? allData.horo.gochar_shodhitkundali.shodhitkundali: ""
  const [newVfaldata, setNewvFalData] = useState();
  const [error, setError] = useState();
  const keyChange = async (k) => {
    if (k == "contact") {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let dateObj = new Date();
      let output = "";
      let month = dateObj.getMonth() + 1;
      let day = String(dateObj.getDate()).padStart(2, "0");
      let year = dateObj.getFullYear();
      let currentdate;
      let currentime =
        (dateObj.getHours() < 10 ? "0" : "") +
        dateObj.getHours() +
        ":" +
        (dateObj.getMinutes() < 10 ? "0" : "") +
        dateObj.getMinutes();
      setCurrentTime(currentime);
      if (month < 10) {
        output = day + "/" + "0" + month + "/" + year;
        currentdate = day + "/" + "0" + month + "/" + year;
        setCurrentDate(currentdate);
      } else {
        output = day + "/" + month + "/" + year;
        currentdate = day + "/" + month + "/" + year;
        setCurrentDate(currentdate);
      }
      let time = dateObj.getHours() + ":" + dateObj.getMinutes();
      let postData = {
        lin: output + "," + time + ",077:07E,28:23N,+05:30,K",
      };
      try {
        const res = await axios.post(
          `${BAS_URL_APPAPI}acharya/gocharkundali`,
          postData
        );
        //console.log("Gochar Data in Chart====",res);
        if (res.data.data.cusRashiList) {
          setGocharShodhit(res.data.data.cusRashiList);
          setTodaysGocharKundali(res.data.data.cusRashiList);
        }
        setIsGocharSelected(true);
      } catch (error) {
        // console.log(error);
      }
    }
    setKey(k);
  };
  
  const onYearChange = (e) => {
    setVyear(e.target ? e.target.value + "" : e + "");
    setIsLoading(true);
    onSubmit(e.target ? e.target.value : e);
  };

  const handleYear = (e) => {
    const limit = 2;
    // only take first N characters
    let year = Number(e.target.value.slice(0, limit));
    if (year <= 99) e.target.value = year + "";
    else year = e.target.value = e.target.value.slice(0, limit);
    // handleChange(e);
    setVyear(e.target ? e.target.value : e + "");
    setIsLoading(true);
    onSubmit(e.target ? e.target.value : e);
  };







  const onSubmit = async (e) => {
    setIsLoading(true);
    let lagankundli = allData.horo.new_kmv_lagan;
    let new_kp_chart = allData.horo.new_kp_chart;
    let onemore = 1;
    const body = {
      //"age":props.finalyear,
      // "age":parseInt(vyear) + 1,
      age: parseInt(e),
      new_kp_chart: new_kp_chart,
      new_kmv_lagan: lagankundli,
    };
    let token = localStorage.getItem("token");
    let result = await axios.post(
      `${BAS_URL_APPAPI}acharya/varshfalkundali`,
      body
    );
    if (result) {
      setNewvFalData(result.data.data);
      setIsLoading(false);
    } else {
      let notifyMessage = "Something went wrong";
      let optiondata = {};
      optiondata = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(optiondata);
    }
  };
  const showHide = () => {
    showDiv(true);
  };

  useEffect(() => {
    setNewvFalData(newVfaldatanew);
    setVyear(props.finalyear + "");
  }, [props.finalyear, newVfaldatanew]);
  useEffect(() => {
    setKey("profile");
  }, [allData]);


  console.log(vyear,"vyear")
  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <div className="border-none p-2 varshfal">
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            setKey(k);
            keyChange(k);
          }}
          style={{
            width: "100%",
            height: "20%",
            display: "flex",
            justifyContent: "flex-start",
            fontSize: "12px",
            padding: "5px 8px",
          }}
          className=""
        >
          <Tab eventKey="profile" title="Vashist" className="p-0 m-0">
            <Card className="p-0 mb-0">
              <CardBody className="p-0">
                <Row>
                  <Col md="12" className="d-flex justify-content-sm-start px-4">
                    {!shodhitkundli && (
                      <div className="box">
                        <span style={{ order: "4" }} className="ten"></span>
                        <span style={{ order: "1" }} className="ten"></span>
                        <span style={{ order: "3" }} className="nine"></span>
                        <span style={{ order: "6" }} className="seven"></span>
                        <span style={{ order: "8" }} className="nine"></span>
                        <span style={{ order: "11" }} className="seven"></span>
                        <span style={{ order: "9" }} className="seven"></span>
                        <span style={{ order: "12" }} className="seven"></span>
                        <span style={{ order: "10" }} className="ten"></span>
                        <span style={{ order: "7" }} className="seven"></span>
                        <span style={{ order: "5" }} className="ten"></span>
                        <span style={{ order: "2" }} className="seven"></span>
                      </div>
                    )}
                    {shodhitkundli && (
                      <div className="box">
                        <span style={{ order: "4" }} className="ten">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 1 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "1" }} className="ten">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 2 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "3" }} className="nine">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 3 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "6" }} className="seven">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 4 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "8" }} className="nine">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 5 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "11" }} className="seven">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 6 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "9" }} className="seven">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 7 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "12" }} className="seven">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 8 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "10" }} className="ten">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 9 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "7" }} className="seven">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 10 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "5" }} className="ten">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 11 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "2" }} className="seven">
                          {shodhitkundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 12 && val.Rashi + planet;
                          })}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Tab>
          <Tab eventKey="" title="Lagan" className="p-0 m-0">
            <Card className="p-0 mb-0">
              <CardBody className="p-0">
                <Row>
                  <Col md="12" className="d-flex justify-content-sm-start px-4">
                    {!lagankundli && (
                      <div className="box">
                        <span style={{ order: "4" }} className="ten"></span>
                        <span style={{ order: "1" }} className="ten"></span>
                        <span style={{ order: "3" }} className="nine"></span>
                        <span style={{ order: "6" }} className="seven"></span>
                        <span style={{ order: "8" }} className="nine"></span>
                        <span style={{ order: "11" }} className="seven"></span>
                        <span style={{ order: "9" }} className="seven"></span>
                        <span style={{ order: "12" }} className="seven"></span>
                        <span style={{ order: "10" }} className="ten"></span>
                        <span style={{ order: "7" }} className="seven"></span>
                        <span style={{ order: "5" }} className="ten"></span>
                        <span style={{ order: "2" }} className="seven"></span>
                      </div>
                    )}
                    {lagankundli && (
                      <div className="box">
                        <span style={{ order: "4" }} className="ten">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 1 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "1" }} className="ten">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 2 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "3" }} className="nine">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 3 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "6" }} className="seven">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 4 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "8" }} className="nine">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 5 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "11" }} className="seven">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 6 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "9" }} className="seven">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 7 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "12" }} className="seven">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 8 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "10" }} className="ten">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 9 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "7" }} className="seven">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 10 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "5" }} className="ten">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 11 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "2" }} className="seven">
                          {lagankundli.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 12 && val.Rashi + planet;
                          })}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Tab>
          <Tab eventKey="contact" title="Gochar">
            <Card className="p-0 mb-0">
              <CardBody className="p-0">
                <Row>
                  <Col md="12" className="d-flex justify-content-sm-start px-4">
                    {!isGocharSelected && (
                      <div className="box">
                        <span style={{ order: "4" }} className="ten"></span>
                        <span style={{ order: "1" }} className="ten"></span>
                        <span style={{ order: "3" }} className="nine"></span>
                        <span style={{ order: "6" }} className="seven"></span>
                        <span style={{ order: "8" }} className="nine"></span>
                        <span style={{ order: "11" }} className="seven"></span>
                        <span style={{ order: "9" }} className="seven"></span>
                        <span style={{ order: "12" }} className="seven"></span>
                        <span style={{ order: "10" }} className="ten"></span>
                        <span style={{ order: "7" }} className="seven"></span>
                        <span style={{ order: "5" }} className="ten"></span>
                        <span style={{ order: "2" }} className="seven"></span>
                      </div>
                    )}
                    {isGocharSelected && (
                      <div className="box">
                        <span style={{ order: "4" }} className="ten">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 1 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "1" }} className="ten">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 2 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "3" }} className="nine">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 3 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "6" }} className="seven">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 4 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "8" }} className="nine">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 5 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "11" }} className="seven">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 6 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "9" }} className="seven">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 7 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "12" }} className="seven">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 8 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "10" }} className="ten">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 9 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "7" }} className="seven">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 10 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "5" }} className="ten">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 11 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "2" }} className="seven">
                          {gocharShodhit.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 12 && val.Rashi + planet;
                          })}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Tab>

          <Tab
            eventKey="varshfal"
            title="Varshfal"
            style={{ fontSize: "10px" }}
            onClick={showHide}
          >
            <Card className="p-0 mb-0">
              <CardBody className="p-0">
                <Row>
                  <Col md="12" className="px-4">
                    {!newVfaldata && (
                      <div
                        className="box w-full"
                        style={{
                          height: key == "varshfal" ? "14.50rem" : "16.6rem",
                        }}
                      >
                        <span style={{ order: "4" }} className="ten"></span>
                        <span style={{ order: "1" }} className="ten"></span>
                        <span style={{ order: "3" }} className="nine"></span>
                        <span style={{ order: "6" }} className="seven"></span>
                        <span style={{ order: "8" }} className="nine"></span>
                        <span style={{ order: "11" }} className="seven"></span>
                        <span style={{ order: "9" }} className="seven"></span>
                        <span style={{ order: "12" }} className="seven"></span>
                        <span style={{ order: "10" }} className="ten"></span>
                        <span style={{ order: "7" }} className="seven"></span>
                        <span style={{ order: "5" }} className="ten"></span>
                        <span style={{ order: "2" }} className="seven"></span>
                      </div>
                    )}
                    {newVfaldata && (
                      <div
                        className="box w-full"
                        style={{
                          height: key == "varshfal" ? "14.50rem" : "16.6rem",
                        }}
                      >
                        <span style={{ order: "4" }} className="ten">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 1 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "1" }} className="ten">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 2 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "3" }} className="nine">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 3 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "6" }} className="seven">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 4 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "8" }} className="nine">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 5 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "11" }} className="seven">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 6 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "9" }} className="seven">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 7 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "12" }} className="seven">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 8 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "10" }} className="ten">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 9 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "7" }} className="seven">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 10 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "5" }} className="ten">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 11 && val.Rashi + planet;
                          })}
                        </span>
                        <span style={{ order: "2" }} className="seven">
                          {newVfaldata.map((val) => {
                            let planet = val.content.replaceAll(",", ", ");
                            return val.House === 12 && val.Rashi + planet;
                          })}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Tab>
        </Tabs>
        {key == "varshfal" && (
          <div
            style={{
              display: "flex",
              height: "30px",
              marginLeft: "10px",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
            className="px-3"
          >
            <p
              style={{ height: "30px", marginTop: "4px", flex: 1 }}
              className="text-base"
            >
              Varshfal Age:
            </p>
            {/* <div> */}
            <button
              className="age-changer"
              onClick={() => onYearChange(parseInt(vyear) + 1)}
            >
              +
            </button>
            <Input
              bsSize="sm"
              type="number"
              min={1}
              max={100}
              className="form-control px-1 rounded-[2px] pl-2 pr-2"
              name="years"
              maxLength={2}
              placeholder="Age"
              value={vyear}
              style={{
                height: "30px",
                width: "70px",
                color: "#fff !important",
                textAlign: "center",
              }}
              autoComplete="off"
              onChange={(e) => {
                handleYear(e);
              }}
            />
            <button
              className="age-changer"
              onClick={() => onYearChange(parseInt(vyear) - 1)}
              style={{ marginLeft: "5px" }}
            >
              -
            </button>
            {/* </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default Horoinnerthree;
