// import "./App.css";
import { v4 as uuidv4 } from "uuid";
// import Card from "./Card";
import Carousel from "./Carousel";
import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { useEffect } from "react";
import { config } from "react-spring";
import Styless from "./Button.module.css";
import ModalVideo from "react-modal-video";

function Card({ imagen }) {
  const [show, setShown] = useState(false);
  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });
  return (
    <animated.div
      className={Styles.card}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <div className={Styles.bg} alt="" >
      <ModalVideo
        className="model-video"
        chanel="youtube"
        autoplay={1}
        loop="1"
        isOpen={true}
        videoId="YrjbJajCDoI"
      />
      </div>
    </animated.div>
  );
}


// function Button({ text }) {
//   return <button className={Styless.btn}>{text}</button>;
// }

function App() {
  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg" />
     
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/acf_pro.png" />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/layer_slider_plugin_thumb.png" />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2016/08/slider_revolution-1.png" />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2019/01/pwa_880_660.jpg" />
      ),
    },
  ];

  return (
    <div className="mt-5">
      <Carousel
        cards={cards}
        height="500px"
        width="30%"
        margin="0 auto"
        offset={3}
        showArrows={false}
      />
    </div>
  );
}

export default App;
