import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Pagination,
  Input,
} from "reactstrap";

import ReactTable from "components/ReactTable/ReactTable.js";
import axios from "axios";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { Form, FormGroup } from "react-bootstrap";
import { useDispatch, useSelector} from "react-redux";
import { logout } from "redux/actions/auth";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const Viewedkundali = () => {
  const dispatch = useDispatch();
  const [viewkundali, setViewkundali] = useState([]);
  let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [totalpages, setTotalpage] = useState(0);
  const notificationAlertRef = React.useRef(null);
  const token = localStorage.getItem("token");
  const acharya = localStorage.getItem("user");

  const viewKundaliData = async (validation) => {
    if ((!endDate || !startDate) && validation) {
      let notifyMessage = "Please select a valid date!";
      let optiondata = {};
      optiondata = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(optiondata);
      return;
    }
    try {
      const userDetail = JSON.parse(acharya);
      const id = userDetail.id;
      startDate = "";
      endDate = "";
      const viewkundaliresult = await axios.get(
        `${BAS_URL_APPAPI}acharya/viewkundali?id=${id}&sdate=${startDate}&edate=${endDate}`,
        {
          headers: { Authorization: token },
          body: { id: id },
        }
      );
      //console.log("Paid Kundali==============",paidkundali)

      setStartDate("");
      setEndDate("");
      setPage(1);
      setViewkundali(viewkundaliresult.data.data);
      // window.location.reload(false);
    } catch (error) {
      let notifyMessage = "Unable to Fetch Data";
      let optiondata = {};
      optiondata = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(optiondata);
      if(error.response.data.messages == 'NOT_A_USER'){
        dispatch(logout());
      }
    }
  };
  const onSubmit = async (event) => {
    event.preventDefault(); // 👈️ prevent page refresh
    if (!endDate || !startDate) {
      let notifyMessage = "Please select a valid date!";
      let optiondata = {};
      optiondata = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(optiondata);
      return;
    }

    if (new Date(endDate) < new Date(startDate)) {
      alert("End date should be greater than start date!");
      return;
    }
    try {
      const userDetail = JSON.parse(acharya);
      const id = userDetail.id;
      const viewkundaliresult = await axios.get(
        `${BAS_URL_APPAPI}acharya/viewkundali?id=${id}&sdate=${startDate}&edate=${endDate}&page=${page}&pageSize=${pagesize}`,
        {
          headers: { Authorization: token },
          body: { id: id },
        }
      );

      console.log("VIEW KUNDALI", viewkundaliresult);
      setViewkundali(viewkundaliresult.data.data);
      setTotalpage(Math.round(viewkundaliresult.data.data.list / 10));
    } catch (error) {
      let notifyMessage = "Unable to Fetch Data";
      let optiondata = {};
      optiondata = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(optiondata);
    }
  };
  let active = page;
  let items = [];
  let ecliptsarr = [];
  for (let number = 1; number <= totalpages; number++) {
    if (number < 10) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          value={number}
          onClick={(e) => setPageNumber(number)}
        >
          {number}
        </Pagination.Item>
      );
    } else if (number === 10) {
      items.push(<Pagination.Ellipsis />);
    }
  }
  const setPageNumber = (number) => {
    console.log("dskfjfls Number", number);
    setPage(number);
    onSubmit();
  };


  useEffect(() => {
    // getSubscription();
    viewKundaliData(false);
  }, []);

  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const [data, setData] = React.useState(
    viewkundali?.map((prop, key) => {
      return {
        id: key,
        name: prop[0],
        product_name: prop[1],
        gender: prop[2],
        dob: prop[3],
        tob: prop[4],
        pob: prop[5],
        language: prop[6],
        generatedate: prop[9],
      };
    })
  );


  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlertRef} />
        <Row className="mb-0">
          <Col xs={12} md={12}>
            <Card className="mb-1">
              <CardBody className="mt-0">
                <Form onSubmit={(e) => onSubmit(e)}>
                  <div className="mb-0">
                    <FormGroup
                      className={window.innerWidth < 800 ? "" : "flex-search"}
                    >
                      {/* <div className='mr-1'>
                            <Input type='text' placeholder="Search Name" value={name} onChange={e =>setSearchName(e.target.value ? e.target.value : '' )} className="mb-0"/>
                        </div> */}
                      
                      <div className={window.innerWidth < 800 ? "" : "exist"}>
                      <div className="mr-2 mt-[10px] text-sm">
                        <strong>FROM</strong>{" "}
                      </div>
                        <div className="mr-1">
                          <Input
                            type="date"
                            value={startDate}
                            onChange={(e) =>
                              setStartDate(e.target.value ? e.target.value : "")
                            }
                            className="mb-0"
                          />
                        </div>
                        <div className="mr-2 mt-[10px] text-sm">
                          <strong>TO</strong>{" "}
                        </div>
                        <div className="mr-1">
                          <Input
                            type="date"
                            value={endDate}
                            onChange={(e) =>
                              setEndDate(e.target.value ? e.target.value : "")
                            }
                            className="mb-0 mr-2"
                          />
                        </div>
                      </div>{" "}
                      <br></br>
                      <div className="flex-search">
                        <div>
                          <Button
                            variant="primary"
                            type="submit"
                            className="mt-0 submitbtn"
                            style={{ padding: "10px 30px", margin: "1px" }}
                          >
                            Filter
                          </Button>
                        </div>
                        <div>
                          <Button
                            variant="primary"
                            onClick={(e) => viewKundaliData(e)}
                            className="mt-0 submitbtn"
                            style={{ padding: "10px 28px", margin: "1px" }}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardBody>
                <ReactTable
                  data={viewkundali ? viewkundali : []}
                  filterable
                  resizable={true}
                  columns={[
                    {
                      Header: "Id",
                      accessor: "id",
                    },
                    {
                      Header: "Name",
                      accessor: "name",
                    },
                    {
                      Header: "Gender",
                      accessor: "gender",
                    },
                    {
                      Header: "D.O.B",
                      accessor: "dob",
                    },
                    {
                      Header: "T.O.B",
                      accessor: "tob",
                    },
                    {
                      Header: "P.O.B",
                      accessor: "pob",
                    },

                    {
                      Header: "Date",
                      accessor: (row) => (
                        <div>
                          {moment(row.generatedate)
                            .utc()
                            .format("DD/MM/YY hh:mm a")}{" "}
                        </div>
                      ),
                      // accessor: row => <div>{(new Date(Date.parse(row.generatedate))).toLocaleDateString("IN", options)}</div>,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom
                  className="-striped -highlight "
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Viewedkundali;
