import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import ModalVideo from "react-modal-video";
import thumbnail from "../../assets/landing/img/thumbnail.webp";

function Slideshow({ lang }) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [videoId, setVideoId] = useState("");
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const slides = [
    {
      src: "YrjbJajCDoI",
      alt: "Gdv panel tour",
      thumbnail: thumbnail,
    },
    {
      src: "YrjbJajCDoI",
      alt: "Gdv panel tour",
      thumbnail: thumbnail,
    },
    ,
    {
      src: "YrjbJajCDoI",
      alt: "Gdv panel tour",
      thumbnail: thumbnail,
    },
    // Add more slides as needed
  ];

  function SampleNextArrow(props) {
    return (
      <div
        onClick={props.onClick}
        className="absolute right-[20px] top-1/2 z-50 cursor-pointer"
      >
        <i
          className="fa fa-angle-right text-[40px] cursor-pointer"
          aria-hidden="true"
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="800"
        ></i>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={props.onClick}
        className="absolute left-[20px] top-1/2 z-50 cursor-pointer"
      >
        <i
          className="fa fa-angle-left text-[40px] cursor-pointer"
          aria-hidden="true"
        ></i>
      </div>
    );
  }

  const openModal = (id) => {
    setVideoId(id);
    setOpen(true);
  };

  const bullets = {
    eng: [
      {
        heading:
          "The Future at Your Fingertips: Explore Our Astrology Software",
        sub_heading: "",
        points: [
          {
            num: "1",
            // img: iconkundali,
            title: "Comprehensive Kundali Analysis System",
            sub_title:
              "This feature offers a user-friendly platform to create and analyze Kundalis (birth charts) with precision, making astrology accessible and easy to understand.",
          },
          {
            num: "2",
            // img: iconPred,
            title: "Dasha Planetary Prediction Engine",
            sub_title:
              "Leverage the power of Vedic astrology’s Dasha system to receive detailed forecasts based on the unique planetary influences during specific periods of your life.",
          },
          {
            num: "3",
            // img: Questions,
            title: "Personalized Question & Answer Analysis",
            sub_title:
              "Ask questions and receive customized responses grounded in the analysis of your individual birth chart, providing highly relevant insights for every query.",
          },
          {
            num: "4",
            // img: Crown,
            title: "Precise Prediction Remedies",
            sub_title:
              "Our software helps you select and implement the most effective remedies to counteract negative planetary influences, ensuring your predictions and solutions are on point.",
          },
        ],
      },
    ],

    hin: [
      {
        heading: "आपके हाथों में भविष्य: हमारे ज्योतिष सॉफ़्टवेयर का अन्वेषण करें",
        sub_heading: "",
        points: [
          {
            num: "1",
            // img: iconkundali,
            title: "व्यापक कुंडली विश्लेषण प्रणाली",
            sub_title: "यह फीचर उपयोगकर्ता के अनुकूल प्लेटफ़ॉर्म प्रदान करता है, जिससे आप कुंडलियों (जन्म पत्रिकाओं) को सटीकता से बना सकते हैं और उनका विश्लेषण कर सकते हैं, जिससे ज्योतिष को समझना और उपयोग करना आसान हो जाता है।",
          },
          {
            num: "2",
            // img: iconPred,
            title: "दशा ग्रहों के अनुसार भविष्यवाणी।",
            sub_title: "",
          },
          {
            num: "3",
            // img: Questions,
            title: "विभिन्न या व्यक्तिगत कुंडली पर आधारित प्रश्न/उत्तर।",
            sub_title: "",
          },
          {
            num: "4",
            // img: Crown,
            title: "यह आपकी भविष्यवाणी उपचार को सही बनाने में मदद करता है।",
            sub_title: "",
          },
        ],
      },
    ],
  };

  return (
    <div className="slider-container container-custom py-3">
      {bullets[lang].map((item_main, index) => (
        <div
          className="cs_section_heading cs_style_1 text-[40px] mb-5 flex justify-center col-xl-12"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          {/* <h1 className="cs_section_title mb-0">{item_main.heading}</h1> */}
          <p className="cs_iconbox_subtitle text-gray-950">
          {item_main.heading}
                        </p>
        </div>
      ))}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpen(false)}
        // theme="dark"
      />
      {/* <h2>Slider Syncing (AsNavFor)</h2>
      <h4>First Slider</h4> */}
      <Slider
        asNavFor={nav2}
        ref={(slider) => (sliderRef1 = slider)}
        className="flex  mx-auto rounded-2xl p-2"
        // nextArrow={
        //   <SampleNextArrow
        //     style={{
        //       color: "black",
        //       background: "pink",
        //     }}
        //   />
        // }
        // prevArrow={<SamplePrevArrow />}
      >
        {slides?.map((item, index) => (
          <div
            key={index}
            className="relative border-red-700"
            data-aos="fade-right"
            data-aos-duration="600"
            data-aos-delay="800"
          >
            {/* <div className="relative rounded-xl" style={{background:"#00000010"}}> */}
            {/* <img
               src={item.thumbnail}
               alt={item.alt}
               className="rounded-2xl w-full lg:h-[600px] max-lg:h-[300px] md:h-[400px] object-cover cursor-pointer"
               onClick={() => openModal(item.src)}
             /> */}
            <video
              width="320"
              height="240"
              poster={item.thumbnail}
              controls
              className="rounded-2xl w-full lg:h-[600px] max-lg:h-[300px] md:h-[400px] object-cover cursor-pointer"
            >
              <source src="movie.mp4" type={item.src} />
              <source src="movie.ogg" type={item.src} />
            </video>
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 rounded-2xl">
              <i
                className="fa fa-play-circle text-white text-6xl cursor-pointer"
                onClick={() => openModal(item.src)}
              ></i>
            </div>
          </div>
        ))}
      </Slider>
      {/* <Slider
        asNavFor={nav1}
        ref={(slider) => (sliderRef2 = slider)}
        slidesToShow={4}
        swipeToSlide={true}
        focusOnSelect={true}
        className="flex mx-auto p-2 bg-transparent"
        infinite={true}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {slides.map((item, index) => (
          <div
            key={index}
            className="border border-transparent bottom-2 z-1 card rounded-2xl mt-2 cursor-pointer lg:h-[200px] max-lg:h-[100px] md:h-[200px] w-full"
            data-aos="fade-up"
            data-aos-duration="200"
            data-aos-delay="800"
          >
          </div>
        ))}
        <div className="h-48 border bottom-2">
          <ModalVideo
            className="model-video"
            chanel="youtube"
            autoplay={1}
            loop="1"
            isOpen={true}
            videoId="YrjbJajCDoI"
          />
        </div>

        <div className="h-48 border bottom-2">
          <ModalVideo
            className="model-video"
            chanel="youtube"
            autoplay={1}
            loop="1"
            isOpen={true}
            videoId="YrjbJajCDoI"
          />
        </div>
      </Slider> */}
    </div>
  );
}

export default Slideshow;
